import { 
    enviosDocServico
} from '../../services'

export const enviosDoc = {

    actions: {
        EnviarNota({ commit, dispatch }, payload) {
            enviosDocServico.EnviarNota(commit, dispatch, payload)
        },
        EnviarVariasNotas({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                enviosDocServico.EnviarVariasNotas(commit, dispatch, payload).then(response => {
                    resolve(response)
                }, (error) => {
                    reject(error)
                })
            })
        },
        EnviarNfse({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                enviosDocServico.EnviarNfse(commit, dispatch, payload).then(response => {
                    resolve(response)
                }, (error) => {
                    reject(error)
                })
            })
        },
        CancelarNfse({ commit, dispatch },payload) {
            enviosDocServico.CancelarNfse(commit, dispatch, payload)
        },
        cancelarNota({ commit, dispatch },payload) {
            return new Promise((resolve, reject) => {
                enviosDocServico.cancelarNota(commit, dispatch, payload).then(response => {
                    resolve(response)
                }, (error) => {
                    reject(error)
                })
            })
        },
        cartaCorrecao({ commit, dispatch },payload) {
            enviosDocServico.cartaCorrecao(commit, dispatch, payload)
        },
        consultaEvento({commit, dispatch},payload){
            enviosDocServico.consultaEvento(commit, dispatch, payload)
        },
        consultaXml({ commit, dispatch }, payload) {
            enviosDocServico.consultaXml(commit, dispatch, payload)
        },
        consultaChaveAcesso({ commit, dispatch }, payload) {
            enviosDocServico.consultaChaveAcesso(commit, dispatch, payload)
        },
        consultarNfse({ commit, dispatch }, payload) {
            enviosDocServico.ConsultarNfse(commit, dispatch, payload)
        }
    }
}