import axios from 'axios'
import eventBus from '@/eventBus'

export const configuracoesServico = {
    configuracoes,
    removerConfiguracoes,
    ConfiguracoesEmail,
    danfeNfce,
    danfeNfe,
    ObterProvedores,
    salvarProvedor,
    gravarMunicipioProvedor,
    retornarTiposEnvio
}

//#region Envio de Inutilização
function ObterProvedores(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    return axios(`${payload}/rest/documentos/nfse/provedor`)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("salvarProvedoresObtidos", response.data)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region salvar Provedor NFSe
function salvarProvedor( commit, dispatch, payload) {
    
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: "post",
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Salvar provedor de NFS-e.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/nfse/provedor`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Gravar município do Provedor NFSe
function gravarMunicipioProvedor( commit, dispatch, payload) {
    
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: "post",
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Gravar município do provedor de NFS-e.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/nfse/municipio/provedor`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region retornar Tipos Envio
function retornarTiposEnvio( commit, dispatch, payload) {
    
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Retornar tipos de envio da NFS-e.`)
            return data;
        }]
    }
    
    return axios(`${payload}/rest//documentos/nfse/tipo_envio`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter e salvar as configurações NFe e NFSe
function configuracoes( commit, dispatch, payload) {
    
    commit("exibirOcultarModalLoading", true)

    let tipoEnvio, mensagem
    payload.configuracoes === undefined ? (tipoEnvio= 'get', mensagem= 'Obtendo' ) : (tipoEnvio = 'post', mensagem = 'Salvando')

    const requestOptions = {
        method: tipoEnvio,
        data: payload.configuracoes === undefined ? '': JSON.stringify(payload.configuracoes),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `${mensagem} configurações da NF-e.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/configuracoes/${payload.modelo}/${payload.cpfCnpj}/emissao`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", payload.cpfCnpj)
        _commitConfiguracoes(commit, payload.modelo, response.data)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region remover configurações NFe, NFS-e e NFC-e
function removerConfiguracoes( commit, dispatch, payload) {
    
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Remover configurações de ${payload.TipoRequisao} da ${payload.modelo}`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/configuracoes/${payload.modelo}/${payload.cpfCnpj}/${payload.TipoRequisao}`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", payload.cpfCnpj)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter e salvar as configuração de e-mail da empresa

function ConfiguracoesEmail( commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    const requestOptions = {
        method: payload.dadosEmail === undefined ? 'get' : 'post',
        data: JSON.stringify(payload.dadosEmail),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Obtendo dados de e-mail.")
            return data;
        }]
    }

    return axios(`${payload.server}/rest/configuracoes/${payload.cpfCnpj}/email`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", payload.cpfCnpj)
        commit("dadosEmail", response.data)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Configuração DANFE da NFC-e
function danfeNfce(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: payload.configuracoes === undefined ? 'get' : 'post',
        data: JSON.stringify(payload.configuracoes),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Configuração DANFE da NFC-e.")
            return data;
        }]
    }

    return axios(`${payload.server}/rest/configuracoes/nfce/${payload.cpfCnpj}/danfe`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", payload.cpfCnpj)
        commit("configuracoesDanfeNfce", response.data)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Configuração DANFE da NFe
function danfeNfe(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: payload.configuracoes === undefined ? 'get' : 'post',
        data: JSON.stringify(payload.configuracoes),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Configuração do DANFE da NF-e.")
            return data;
        }]
    }

    return axios(`${payload.server}/rest/configuracoes/nfe/${payload.cpfCnpj}/danfe`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", payload.cpfCnpj)
        commit("configuracoesDanfeNfe", response.data)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

function _commitConfiguracoes(commit, modelo, response) {
    if(modelo === 'nfe') {
        commit("salvarConfiguracoesNfe", response)
    }
    if(modelo === 'nfse') {
        commit("salvarConfiguracoesNfse", response)
    }

}