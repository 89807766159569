import { pdf } from '@/helpers/gerarPDF'

export const msdfe = {
    state: {
        ultimoCpfCnpj: "",
        ultimoSerial: ""
    },
    getters: {
        dadosEmpresa(state) {
            return {
                "cpfCnpj": state.ultimoCpfCnpj,
                "serial": state.ultimoSerial
            }
        }
    },
    mutations: {
        cpfCnpj(state,payload) {
            state.ultimoCpfCnpj = payload            
        },
        serialCertificado(state,payload) {
            state.ultimoSerial = payload
        },
        gerarPdfNfe(state,payload){
            pdf.gerarPDF(payload.pdf.danfe, payload.chaveAcesso)
        },
        gerarPdfNfse(state,payload){
            pdf.gerarPDF(payload.pdf.danfse, payload.chaveAcesso)
        },
        gerarCCe(state,payload){
            pdf.gerarPDF(payload.pdf.documentoAuxiliar, payload.chaveAcesso)
        },
        gerarPdfNfce(state,payload){
            pdf.gerarPDF(payload.pdf.viaConsumidor, payload.chaveAcesso)
            pdf.gerarPDF(payload.pdf.viaEmitente, payload.chaveAcesso)
        }
    },
    actions: {
        atualizarCpfCnpj({commit},payload){
            commit('cpfCnpj', payload)
        }
    }
}