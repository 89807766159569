export const xmlNfe = {
    xml: `
    <NFe xmlns="http://www.portalfiscal.inf.br/nfe">
        <infNFe versao="4.00" Id="NFe33200936462778000160550010000011341160140610">
            <ide>
                <cUF>33</cUF>
                <cNF>16014061</cNF>
                <natOp>Venda</natOp>
                <mod>55</mod>
                <serie>1</serie>
                <nNF>1134</nNF>
                <dhEmi>2020-09-25T17:17:00-03:00</dhEmi>
                <dhSaiEnt>2020-09-25T17:17:00-03:00</dhSaiEnt>
                <tpNF>0</tpNF>
                <idDest>1</idDest>
                <cMunFG>3305802</cMunFG>
                <tpImp>1</tpImp>
                <tpEmis>1</tpEmis>
                <cDV>0</cDV>
                <tpAmb>2</tpAmb>
                <finNFe>1</finNFe>
                <indFinal>0</indFinal>
                <indPres>0</indPres>
                <procEmi>0</procEmi>
                <verProc>4.14.5.1</verProc>
            </ide>
            <emit>
                <CNPJ>36462778000160</CNPJ>
                <xNome>ALTERDATA TECNOLOGIA EM INFORMATICA LTDA</xNome>
                <xFant>IE SP - 956224310481 / IE RJ - 99107014 / IE RS 0018001360</xFant>
                <enderEmit>
                    <xLgr>Rua Prefeito Sebastião Teixeira</xLgr>
                    <nro>227</nro>
                    <xBairro>CENTRO</xBairro>
                    <cMun>3305802</cMun>
                    <xMun>TERESOPOLIS</xMun>
                    <UF>RJ</UF>
                    <CEP>25953200</CEP>
                </enderEmit>
                <IE>99107014</IE>
                <CRT>1</CRT>
            </emit>
            <dest>
                <CNPJ>99999999000191</CNPJ>
                <xNome>NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL</xNome>
                <enderDest>
                    <xLgr>3333333333333333333</xLgr>
                    <nro>333333333</nro>
                    <xCpl>33333333333333</xCpl>
                    <xBairro>333333333</xBairro>
                    <cMun>3305802</cMun>
                    <xMun>TERESOPOLIS</xMun>
                    <UF>RJ</UF>
                    <CEP>33333333</CEP>
                    <cPais>1058</cPais>
                    <xPais>BRASIL</xPais>
                </enderDest>
                <indIEDest>2</indIEDest>
                <email>alterdatamsdfe@gmail.com</email>
            </dest>
            <det nItem="1">
                <prod>
                    <cProd>000011</cProd>
                    <cEAN>7893500019244</cEAN>
                    <xProd>TIO JOÃO COZINHA TAILANDESA VARIEDADES MUNDIAIS PACOTE</xProd>
                    <NCM>10063021</NCM>
                    <CFOP>1909</CFOP>
                    <uCom>PCT</uCom>
                    <qCom>1.0000</qCom>
                    <vUnCom>15.0000000000</vUnCom>
                    <vProd>15.00</vProd>
                    <cEANTrib>7893500019244</cEANTrib>
                    <uTrib>PCT</uTrib>
                    <qTrib>1.0000</qTrib>
                    <vUnTrib>15.0000000000</vUnTrib>
                    <indTot>1</indTot>
                </prod>
                <imposto>
                    <vTotTrib>1.68</vTotTrib>
                    <ICMS>
                        <ICMSSN102>
                            <orig>0</orig>
                            <CSOSN>102</CSOSN>
                        </ICMSSN102>
                    </ICMS>
                    <PIS>
                        <PISNT>
                            <CST>07</CST>
                        </PISNT>
                    </PIS>
                    <COFINS>
                        <COFINSNT>
                            <CST>07</CST>
                        </COFINSNT>
                    </COFINS>
                </imposto>
            </det>
            <total>
                <ICMSTot>
                    <vBC>0.00</vBC>
                    <vICMS>0.00</vICMS>
                    <vICMSDeson>0.00</vICMSDeson>
                    <vFCPUFDest>0.00</vFCPUFDest>
                    <vICMSUFDest>0.00</vICMSUFDest>
                    <vICMSUFRemet>0.00</vICMSUFRemet>
                    <vFCP>0.00</vFCP>
                    <vBCST>0.00</vBCST>
                    <vST>0.00</vST>
                    <vFCPST>0.00</vFCPST>
                    <vFCPSTRet>0.00</vFCPSTRet>
                    <vProd>15.00</vProd>
                    <vFrete>0.00</vFrete>
                    <vSeg>0.00</vSeg>
                    <vDesc>0.00</vDesc>
                    <vII>0.00</vII>
                    <vIPI>0.00</vIPI>
                    <vIPIDevol>0.00</vIPIDevol>
                    <vPIS>0.00</vPIS>
                    <vCOFINS>0.00</vCOFINS>
                    <vOutro>0.00</vOutro>
                    <vNF>15.00</vNF>
                    <vTotTrib>1.68</vTotTrib>
                </ICMSTot>
                <ISSQNtot>
                    <dCompet>2020-04-26</dCompet>
                </ISSQNtot>
            </total>
            <transp>
                <modFrete>9</modFrete>
            </transp>
            <pag>
                <detPag>
                    <tPag>01</tPag>
                    <vPag>15.00</vPag>
                </detPag>
            </pag>
            <infAdic>
                <infCpl>DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL.NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI.</infCpl>
            </infAdic>
        </infNFe>
    </NFe>`
}