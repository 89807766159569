<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a v-if="tipoDoc === '55'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nf-e-cancelamento-do-documento-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
                <a v-if="tipoDoc === '65'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfc-e-cancelamento-do-documento-de-nfc-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-cancel-nota-txt-cpfCnpj" 
                    class="input" 
                    v-model="params.dados.cpfCnpj"
                    ref="cpfCnpj"
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)"  
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-cancel-nota-txt-uf" 
                    class="input" 
                    v-model="params.dados.uf">   
                    <option v-for="(Ufs,i) in params.listaUf"
                        :key="i" 
                        :value="Ufs.cUF"
                        :selected="params.dados.uf">
                        {{Ufs.xUF}}</option>
                </select>
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-cancel-nota-txt-versao" 
                    class="input"
                    v-model="params.dados.versao" >   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input 
                    id="frm-cancel-nota-txt-origem" 
                    v-model.number="params.dados.origem"
                    class="input" 
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-6 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-cancel-nota-txt-serial" 
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-6 is-pulled-left is-paddingless">
                <span>Url de Notificação(webhook):</span>
                <input 
                    id="frm-cancel-nota-txt-urlNotificacao" 
                    v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-marginless is-full-width is-pulled-left has-margin-top-5">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select 
                    id="frm-cancel-nota-txt-importacaoXML" 
                    class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <FileReader class="button button-secundario is-full-width" 
                        @load="carregarXml($event)"/>
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                     <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-6 is-pulled-left has-padding-zero">
                    <span>Chave de acesso:</span>
                    <input 
                        id="frm-cancel-nota-txt-chNFe" 
                        class="input" 
                        type="text"
                        v-model="params.chNFe">
                </div>
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>Protocolo:</span>
                    <input 
                        id="frm-cancel-nota-txt-protocolo" 
                        class="input" 
                        type="text"
                        v-model="params.protocolo">
                </div>
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <button class="bntGerarDoc button button-secundario is-full-width"
                        @click="gerarDoc">Gerar XML</button>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'informe-xml-txt-area' :'']">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea  
                    id="frm-cancel-nota-txt-xml" 
                    class="textarea"
                    rows="6"
                    v-model="params.dados.xml"
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="cancelarNota($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import FileReader from "@/components/importarArquivoNovo"
import cancelarNota from '@/model/compartilhado/cancelarNota'
import { geraXML } from '@/helpers/gerarXML'
import SelecaoMenu from '@/components/SelecionarServidor'

export default {
    mixins:[mixin],
    components: {
        FileReader,
        SelecaoMenu
    },
    props: {
        tipoDoc: String
    },
    data(){
        return {
            params: new cancelarNota(),
            ImportacaoXML: 1,
            descricaoXml: ''
        }
    },
    methods: {
        importacaoXMLAlterado() {
            this.descricaoXml = ''
            this.params.dados.xml = ''
        },

        gerarDoc() {
            this.innerHtmlUrl('frm-cancel-nota-txt-xml').innerHTML  = ""
            this.params.dados.xml = geraXML.gerarCancelamento(this.params.dados, this.params.chNFe, this.params.protocolo)
        },

        carregarXml(param) {
            this.descricaoXml = param.nome
            this.params.dados.xml = geraXML.carregarXmlEvento(param.xml)
        },

        cancelarNota(server) {
            this.$root.$emit('closeModal')
            let paramsRequisicao = {
                dados: this.params.dados,
                modelo: this.tipoDoc,
                server: server
            }
            this.$store.dispatch('cancelarNota' , paramsRequisicao)
            this.params.dados.xml = ''
            let paramsStore = {
                componente: 'cancelarNota' + this.tipoDoc,
                dados: this.params.dados,
                chNFe: this.params.chNFe,
                protocolo: this.params.protocolo
            }
            this.$store.dispatch("ArmazenarDadosStore", paramsStore)
        },

        lerDadosLocalStorage() {
            const localStorageDados = JSON.parse(localStorage.getItem(`cancelarNota${this.tipoDoc}`))
            if (localStorageDados) {
                this.params = new cancelarNota(localStorageDados)
                this.$store.commit('cpfCnpj', this.params.dados.cpfCnpj)
                this.$store.commit('serialCertificado', this.params.dados.serial)
            }
        }
    },
    created() {
        this.lerDadosLocalStorage()
    }
}
</script>

<style scoped>

    .informe-xml-txt-area {
        margin-top: 22px;
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>