import axios from 'axios'
import eventBus from '@/eventBus'
import store from '@/store'

export const emailServico = {
    testarEmail,
    enviarEmailAnexo,
}

//#region  Realizar teste de e-mail sem envio de Anexo
function testarEmail( commit ,payload){
    store.commit("exibirOcultarModalLoading", true)

    const formData = new FormData();
    formData.append('destinatario', payload.dados.destinatario)
    formData.append('email',payload.dados.email)
    formData.append('senha',payload.dados.senha)
    formData.append('smtp',payload.dados.smtp)
    formData.append('porta',payload.dados.porta)
    formData.append('ssl',payload.dados.ssl)
    formData.append('tls',payload.dados.tls)
    formData.append('autenticacao',payload.dados.autenticacao)
    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Realizando teste de e-mail.")
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/email/envio_teste`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("retornoTesteEmail", {
            tipo: "Sucesso",
            mensagem: response ? response.data.mensagem : response
        })
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        commit("retornoTesteEmail", {
            tipo: "Erro",
            mensagem: error.response ? error.response.data.erros[0].mensagem : error
        })
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Realizar teste de e-mail com envio de anexo
function enviarEmailAnexo(commit ,payload){
    store.commit("exibirOcultarModalLoading", true)

    const formData = new FormData();
    for(var i=0;i < payload.dados.xml.length; i++){
        formData.append('xml', payload.dados.xml[i])
    }
    for(var i=0;i < payload.dados.documentoAuxiliar.length; i++){
        formData.append('documentoAuxiliar', payload.dados.documentoAuxiliar[i])
    }
    formData.append('destinatarios', payload.dados.destinatarios)
    formData.append('tipoDocumentoAnexo', payload.dados.tipoDocumentoAnexo)
    formData.append('cpfCnpj', payload.dados.cpfCnpj)

    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando envio de e-mail com anexo.")
            return data;
        }]
    }

    return axios(`${payload.server}/rest/email/envio_email`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion