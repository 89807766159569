<template>
    <div>
        <modal 
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" 
                :is="componente" 
                tipoDoc="65"
                :moduloSelecionado="componente"/>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import EnviarNFCe from '@/views/compartilhado/FormEnviarNota.vue'
import cancelarNota from '@/views/compartilhado/FormcancelarNota.vue'
import Inutilizacao from '@/views/compartilhado/FormInutilizacao.vue'
import ConsultarNota from '@/views/compartilhado/FormConsultarNota.vue'
import ConsultarEvento from '@/views/documentos/FormConsultarEvento.vue'
import GerarDanfceXml from '@/views/compartilhado/FormGerarDanfeXml.vue'
import GerarDanfceChave from '@/views/compartilhado/FormGerarDanfeChave.vue'
import ConfiguracoesDanfce from '@/views/configuracoes/FormConfiguracoesDanfce.vue'
import StatusServico from '@/views/compartilhado/FormStatusServico.vue'
import ObterUrlStatus from '@/views/compartilhado/FormObterUrlStatus.vue'
import RemoverConfiguracoesNfceDanfe from '@/views/configuracoes/FormRemoverConfiguracoes.vue'
import ConsultarVersao from '@/views/utilidades/FormConsultarVersao.vue'

export default {
     props: {
        modulo: String
    },
    components: {
        Modal,
        EnviarNFCe,
        cancelarNota,
        Inutilizacao,
        ConsultarNota,
        ConsultarEvento,
        GerarDanfceXml,
        GerarDanfceChave,
        ConfiguracoesDanfce,
        RemoverConfiguracoesNfceDanfe,
        StatusServico,
        ObterUrlStatus,
        ConsultarVersao
    },
    data() {
        return {
            altura: '',
            largura: '',
            titulo:'',
            showModal: '',
            componente: ''
        }
    },
    methods: {
        abrirModal(opt) {
             switch (opt) {
                case 'EnviarNFCe':
                    this.componente = 'EnviarNFCe',
                    this.altura = '559px',
                    this.largura = '920px',
                    this.titulo = 'Enviar NFC-e'
                    break
                case 'ConsultarVersao':
                    this.componente = 'ConsultarVersao'
                    this.altura = '200px'
                    this.largura = '260px'
                    this.titulo = 'Consultar Versão'
                    break
                case 'cancelarNota':
                    this.componente = 'cancelarNota',
                    this.altura = '515px',
                    this.largura = '850px',
                    this.titulo = 'Cancelar NFC-e'
                    break
                case 'Inutilizacao':
                    this.componente = 'Inutilizacao',
                    this.altura = '542px',
                    this.largura = '800px',
                    this.titulo = 'Inutilizar NFC-e'
                    break
                case 'ConsultarNota':
                    this.componente = 'ConsultarNota',
                    this.altura = '500px',
                    this.largura = '680px',
                    this.titulo = 'Consultar Nota'
                    break
                case 'ConsultarEvento':
                    this.componente = 'ConsultarEvento',
                    this.altura = '350px',
                    this.largura = '600px',
                    this.titulo = 'Consultar Evento NFC-e'
                    break
                case 'GerarDanfceXml':
                    this.componente = 'GerarDanfceXml',
                    this.altura = '412px',
                    this.largura = '600px',
                    this.titulo = 'Gerar DANFCE por XML'
                    break
                case 'GerarDanfceChave':
                    this.componente = 'GerarDanfceChave',
                    this.altura = '312px',
                    this.largura = '600px',
                    this.titulo = 'Gerar DANFCE por Chave de Acesso'
                    break
                case 'ConfiguracoesDanfce':
                    this.componente = 'ConfiguracoesDanfce',
                    this.altura = '290px',
                    this.largura = '620px',
                    this.titulo = 'Configurar DANFCE'
                    break
                case 'StatusServico':
                    this.componente = 'StatusServico'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Status servico'
                    break
                case 'ObterUrlStatus':
                    this.componente = 'ObterUrlStatus'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Obter URL de Status'
                    break
                case 'RemoverConfiguracoesNfceDanfe':
                    this.componente = 'RemoverConfiguracoesNfceDanfe'
                    this.altura = '196px'
                    this.largura = '380px'
                    this.titulo = 'Remover Configurações Danfe'
                    break
            }
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
    

}
</script>