<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a v-if="tipoDoc === '55'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nf-e-inutilização-do-documento-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
                <a v-if="tipoDoc === '65'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfc-e-inutilização-do-documento-de-nfc-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-inut-txt-cpfCnpj"
                    class="input" 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-inut-txt-uf"
                    class="input" 
                    v-model="params.dados.uf">   
                    <option v-for="(Ufs,i) in params.listaUf"
                        :key="i" 
                        :value="Ufs.cUF"
                        :selected="params.dados.uf">
                        {{Ufs.xUF}}</option>
                </select>
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-inut-txt-versao"
                    class="input"
                    v-model="params.dados.versao" >   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input
                    id="frm-inut-txt-origem" 
                    v-model.number="params.dados.origem"
                    class="input" 
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-inut-txt-serial" 
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
             <div class="column is-6 is-pulled-left has-padding-zero">
                <span>Url de Notificação(webhook):</span>
                <input 
                    id="frm-inut-txt-urlNotificacao" 
                    v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-pulled-left is-full-width is-marginless has-margin-top-10">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select 
                    id="frm-inut-txt-importacaoXML" 
                    class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-9 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader pb-0">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"' 
                        @load="carregarXml($event)"/>
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                    <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
             <div class="column is-9 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-2 is-pulled-left has-padding-zero">
                    <span>Série:</span>
                    <input 
                        id="frm-inut-txt-serie" 
                        class="input" 
                        type="text" 
                        v-model="params.serie">
                </div>
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>Inicial:</span>
                    <input 
                        id="frm-inut-txt-inicial" 
                        class="input" 
                        type="text" 
                        v-model="params.inicial">
                </div>
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>Final:</span>
                    <input 
                        id="frm-inut-txt-final"
                        class="input" 
                        type="text"
                        v-model="params.final">
                </div>
                <div class="column is-3 is-pulled-right file-reader">
                    <button class="btnGerarDoc button button-secundario is-full-width"
                        @click="gerarDoc">Gerar XML</button>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'informe-xml-txt-area' :'']">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea 
                    id="frm-inut-txt-xml" 
                    class="textarea"
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"
                    rows= 8
                    v-model="params.dados.xml"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="Inutilizacao($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import SelecaoMenu from '@/components/SelecionarServidor'
import inutilizarNota from '@/model/compartilhado/inutilizarNota.js'
import FileReader from "@/components/importarArquivoNovo"
import { geraXML } from '@/helpers/gerarXML'

export default {
    mixins:[mixin],

    components: {
        FileReader,
        SelecaoMenu
    },

    props: {
        tipoDoc: String
    },

    data(){
        return {
            params: new inutilizarNota(),
            ImportacaoXML: 1,
            descricaoXml: ''
        }
    },

    methods: {
        importacaoXMLAlterado() {
            this.descricaoXml = ''
            this.params.dados.xml = ''
        },

        gerarDoc() {
            this.innerHtmlUrl( 'frm-inut-txt-xml' ).innerHTML  = ""
            this.params.dados.xml = geraXML.gerarInutilizacao( this.tipoDoc, this.params.dados, this.params.serie, this.params.inicial,this.params.final )
        },

        carregarXml(param) {
            this.descricaoXml = param.nome
            this.params.dados.xml = geraXML.carregarXmlInut( param.xml )
        },

        Inutilizacao(server) {
            this.$root.$emit('closeModal')
            let payload = {
                dados: this.params.dados,
                modelo: this.tipoDoc,
                server: server
            }
            this.$store.dispatch( "Inutilizacao" , payload )

            let paramsStore = {
                componente: 'inutilizarNota' + this.tipoDoc,
                ...payload,
                serie: this.params.serie,
                inicial: this.params.inicial,
                final: this.params.final
            }

            paramsStore.dados.xml = ''
            this.$store.dispatch( "ArmazenarDadosStore", paramsStore )
        },

        lerDadosLocalStorage(){
            const localStorageDados = JSON.parse( localStorage.getItem( `inutilizarNota${this.tipoDoc}`) )
            if ( localStorageDados ) {
                this.params = new inutilizarNota( localStorageDados )
            }
        }
    },

    created() {
        this.lerDadosLocalStorage()
    }
}
</script>

<style scoped>

    .informe-xml-txt-area {
        margin-top: 23px;
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px;
    }

</style>