import axios from 'axios'

class ServiceGenerica {

    get(url, parametros) {
        return axios(url, parametros)
    }
}

const serviceGenerica = Object.freeze( new ServiceGenerica() )

export default serviceGenerica