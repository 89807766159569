<template>
  <div class="is-pulled-left">
        <div class="column is-12 is-paddingless has-margin-top-15 is-pulled-left">
            <div class="column is-7 is-pulled-left has-padding-zero" >
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-con-danfe-txt-cpfCnpj" 
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-5 is-pulled-right has-margin-top-12">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Obter configurações"
                        :secundario="true"
                        @serverSelecionado="obterConfiguracoesDanfeNfe($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                     <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-retornar-configurações-de-danfe-de-nf-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class=" column is-4 is-pulled-left">
                <div>
                    <input 
                        id="frm-con-danfe-txt-itemPorLinha" 
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.limitarItemPorLinha">
                    <span class="column is-11 is-pulled-left checkboxText">Item em uma linha.</span>
                </div>
                <div>
                    <input 
                        id="frm-con-danfe-txt-imprimirPagamento" 
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.imprimirPagamento">
                    <span class="column is-11 is-pulled-left checkboxText">Imprimir pagamento.</span>
                </div>
                <div>
                    <input 
                        id="frm-con-danfe-txt-obsContribuinte" 
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.exibirObservacaoContribuinte">
                    <span class="column is-11 is-pulled-left checkboxText">Exibir observação contribuinte.</span>
                </div>
            </div>
            <div class=" column is-5 is-pulled-left">
                <div>
                    <input 
                        id="frm-con-danfe-txt-ocultarLogoAlterdata"
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.ocultarLogomarcaAlterdata">
                    <span class="column is-11 is-pulled-left checkboxText">Ocultar logomarca da Alterdata.</span>
                </div>
                <div>
                    <input 
                        id="frm-con-danfe-txt-imprimirValorStItem"
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.imprimirValorStItem">
                    <span class="column is-11 is-pulled-left checkboxText">Imprimir valor St. por item.</span>
                </div>
                <div>
                    <input 
                        id="frm-con-danfe-txt-ocultarCamposTributaveisProduto"
                        class="column is-1 is-pulled-left checkbox" 
                        type="checkbox" v-model="configuracoesDanfeNfe.ocultarCamposTributaveisProduto">
                    <span class="column is-11 is-pulled-left checkboxText">Ocultar campos tributáveis produto</span>
                </div>
            </div>
            <div class=" column is-3 is-pulled-left">
                <div>
                    <span class="numeroCasasDecimaisValorUnitarioProduto">Casas decimais para o valor unitário do produto</span>
                    <input 
                        id="frm-con-danfe-txt-casasDecimaisValorUnitarioProduto"
                        class="column is-12 input is-pulled-left" 
                        type="text" v-model="configuracoesDanfeNfe.numeroCasasDecimaisValorUnitarioProduto">
                </div>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>Logo:</span>
                <select 
                    id="frm-con-danfe-txt-logo"
                    v-model="configuracoesDanfeNfe.posicaoLogotipoEmpresa"
                    class="input">
                    <option v-for="posicaoLogo in posicoesLogo"
                        :key="posicaoLogo.codigo"
                        :value="posicaoLogo.codigo">
                        {{posicaoLogo.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>Impressão Desconto item:</span>
                <select 
                    id="frm-con-danfe-txt-tipoImpressaoDescontoItem"
                    v-model="configuracoesDanfeNfe.tipoImpressaoDescontoItem"
                    class="input">
                    <option v-for="tipoImpressao in tiposImpressao"
                        :key="tipoImpressao.codigo"
                        :value="tipoImpressao.codigo">
                        {{tipoImpressao.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>Desconto item:</span>
                <select 
                    id="frm-con-danfe-txt-tipoDescontoItem"
                    v-model="configuracoesDanfeNfe.tipoDescontoItem"
                    class="input">
                    <option v-for="tipoDesconto in tiposDesconto"
                        :key="tipoDesconto.codigo"
                        :value="tipoDesconto.codigo">
                        {{tipoDesconto.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>Tipo nome:</span>
                <select 
                    id="frm-con-danfe-txt-tipoNomeEmitente"
                    v-model="configuracoesDanfeNfe.tipoNomeEmitente"
                    class="input">
                    <option v-for="nome in tiposNome"
                        :key="nome.codigo"
                        :value="nome.codigo">
                        {{nome.descricao}}
                    </option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <hr class="is-marginless has-margin-top-35">
            <div class="column is-5 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Salvar configurações"
                        @serverSelecionado="salvarConfiguracoesDanfeNfe($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-gravar-configurações-de-danfe-de-nf-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre salvar as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import mixin from '@/mixins'
export default {
    mixins:[mixin],
    data() {
        return {
            formatos: [
                {descricao: 'Retrato', codigo: 'RETRATO'},
                {descricao: 'Paisagem', codigo: 'PAISAGEM'}

            ],
            posicoesLogo: [
                {descricao: 'Esquerda', codigo: 'ESQUERDA'},
                {descricao: 'Topo', codigo: 'TOPO'}
            ],
            tiposImpressao: [
                {descricao: 'Não Imprimir', codigo: 'NAO_IMPRIMIR'},
                {descricao: 'Imprimir abaixo', codigo: 'IMPRIMIR_ABAIXO'},
                {descricao: 'Imprimir ao lado', codigo: 'IMPRIMIR_AO_LADO'}
            ],
            tiposDesconto: [
                {descricao: 'Valor', codigo: 'VALOR'},
                {descricao: 'Percentual', codigo: 'PERCENTUAL'}
            ]
        }
    },
    computed: {
        configuracoesDanfeNfe() {
            return this.$store.state.configuracoes.configuracoesDanfeNfe
        }
    },
    methods: {
        obterConfiguracoesDanfeNfe(server) {
            this.$store.dispatch("danfeNfe", {
                cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                server: server
            })
        },
        salvarConfiguracoesDanfeNfe(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("danfeNfe", {
                cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                configuracoes: this.configuracoesDanfeNfe,
                server: server
            })
        }
    }
}
</script>

<style scoped>
    .numeroCasasDecimaisValorUnitarioProduto {
        line-height: 1.3 !important;
        display: inline-block
    }

    .checkbox {
        margin-top: 5px;
    }

    .checkboxText{
        padding: 3px;
        font-size: 12px;
    }
</style>