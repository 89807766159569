export const pdf = {
    gerarPDF
}

function gerarPDF(b64, titulo){
    let obj = document.createElement('object');
    obj.style.width = '100%';
    obj.style.height = '842pt';
    obj.type = 'application/pdf';
    obj.data = 'data:application/pdf;base64,' + b64;
    let myWindow = window.open("", "window " + new Date, "width=800,height=600");
    myWindow.document.title = titulo
    myWindow.document.body.appendChild(obj);
}