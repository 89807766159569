const nfseEnvio = {
    xml: `<EnviarLoteRpsEnvio>
        <LoteRps Id="Lote364627780001601124205000000111">
            <NumeroLote>000000111</NumeroLote>
            <Cnpj>36462778000160</Cnpj>
            <InscricaoMunicipal>1124205</InscricaoMunicipal>
            <QuantidadeRps>1</QuantidadeRps>
            <ListaRps>
            </ListaRps>
        </LoteRps>
    </EnviarLoteRpsEnvio>`,
    xmlConsulta: `<ConsultarNfseEnvio xmlns='http://www.abrasf.org.br/nfse.xsd'>
        <Prestador>
            <Cnpj>36462778000160</Cnpj>
            <InscricaoMunicipal>1124205</InscricaoMunicipal>
        </Prestador>
        <IdentificacaoRps>
            <Numero>0000000457</Numero>
            <Serie>900</Serie>
            <Tipo>1</Tipo>
        </IdentificacaoRps>
    </ConsultarNfseEnvio>`
}

export default nfseEnvio