export default class gerarDanfeXmlModel {
    constructor(objeto) {

        this.dados = {
            situacao : '',
            cpfCnpj:"",
            xml : '',
            tributosNota: ''
        }
        this.tributosNota = {
            totalMunicipal: '',
            totalEstadual: '',
            totalFederal: '',
            fonte: '',
        }

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}