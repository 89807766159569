import {
    emailServico
} from '@/services'

export const email = {
    state: {
        conteudoRetornoTesteEmail: {
            mensagem: '',
            tipo: ''
        },
        dadosEmpresa: {
            cpfCnpj:"",
            dadosEmail: {
                email: "",
                smtp: "",
                senha: "",
                porta: "",
                ssl: false,
                tls: false,
                autenticacao: false,
                copiaRemetente: false,
                enviaArquivoDistribuicao: false,
                enviaDanfe: false,
                enviaRetornoSefaz: false,
                informacoesAdicionaisNFeAutorizada: null
            }
        }
    },
    mutations: {
        dadosEmail(state,payload) {
            state.dadosEmpresa.dadosEmail = payload
        },
        retornoTesteEmail(state,payload){
            state.conteudoRetornoTesteEmail = payload
        }

    },
    actions: {
        testarEmail({ commit },payload){
            emailServico.testarEmail(commit, payload)
        },
        enviarEmailAnexo({ commit },payload){
            emailServico.enviarEmailAnexo(commit, payload)
        }
    }
}