<template>
  <div>
    <label id="certificado">
      <input
        id="cpt-input-import-arquivo-novo"
        type="file"
        style="display: none"
        ref="fileInput"
        accept=".xml"
        @change="loadTextFromFile"/><strong>{{ novoTituloBotao }}</strong></label>
  </div>
</template>

<script>
export default {
  props: {
    tituloBotao: String
  },
  computed: {
    novoTituloBotao(){
      return !!this.tituloBotao ? this.tituloBotao : 'Carregar XML'
    }
  },
  methods: {
    loadTextFromFile(ev) {
      if(ev) {
        const file = ev.target.files[0];
        const reader = new FileReader();
  
        reader.onload = e => this.$emit("load", {
          xml: e.target.result,
          nome: file.name
        });
        
        reader.readAsText(file);
        this.$refs.fileInput.value = null;
      }
    }
  }
};
</script>

<style scoped>
  #certificado {
    cursor: pointer;
  }

  input {
    font-size: 0.8rem !important;
    text-align: center !important;
  }

</style>