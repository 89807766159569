<template>
    <div id="frmProvedoresNfse" class="column is-12 is-paddingless is-pulled-left has-padding-top-15">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left is-size-7">
                Função criada para teste do recurso mas de utilidade somente a programação.
            </div>
            <div class="column is-6 is-pulled-right has-padding-top-15">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Obter provedores"
                        :secundario="true"
                        @serverSelecionado="obterProvedores($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-retornar-provedores-de-nfs-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero has-margin-top-5">
            <div class="tblClasse-lista">
                <table class="table is-striped is-narrow is-hoverable">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>ID</th>
                            <th>Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(provedor, i) in configuracoes.listaProvedoresObtidos" :key="i" 
                            class="is-clickable" @click="provedorSelecionado = provedor.codigo"
                            title="Clique para selecionar o provedor a ser salvo.">
                            <td>{{ provedor.codigo }}</td>
                            <td>{{ provedor.id }}</td>
                            <td>{{ provedor.descricao }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <hr class="is-marginless has-margin-top-10">
            <div class="column is-4 is-pulled-left has-padding-zero has-padding-top-5"> 
                <span>* Código provedor</span>
                <input class="input is-small input-cursor"
                    type="number"
                    v-model="provedorSelecionado"
                    title="Digite o código ou clique na tabela a cima para selecionar provedor.">
            </div>
            <div class="column is-6 is-pulled-right has-padding-zero has-padding-top-24">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Salvar provedor"
                        @serverSelecionado="salvarProvedor($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-gravação-do-provedor-de-nfs-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como salvar as configurações."></i>
                    </a>
                </div>
            </div>
        </div>
        <span class="pulled-left is-size-8 has-margin-left-15 is-paddingless">* Campos obrigatórios.</span>
    </div>
</template>

<script>
import mixin from '@/mixins'
export default {
    mixins:[mixin],
    computed: {
        configuracoes() {
            return this.$store.state.configuracoes.configuracoesNfse
        }
    },
    data() {
        return {
            provedorSelecionado: ''
        }
    },
    methods: {
        obterProvedores(server) {
            this.$store.dispatch("ObterProvedores", server )
        },
        salvarProvedor(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("salvarProvedor", {
                dados: {
                    codigo: this.provedorSelecionado
                },
                server: server
            })
        }
    }

}
</script>

<style scoped>
    .checkbox {
        margin-top: 5px;
    }

    .checkboxText{
        padding: 3px;
        font-size: 12px;
    }

</style>