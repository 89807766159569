<template>
  <div id="formBuscarDocumentoId">
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-documento-por-id"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
      </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless"
      :class="{'has-padding-bottom-15':!idDocumentoNaoInformado}">
      <div class="column is-12 is-pulled-left has-padding-zero">
        <span>* Id Documento:</span>
        <input 
          id="frm-busc-doc-id-txt-idDocumento"
          :class="{'id-documento-nao-informado':idDocumentoNaoInformado}"
          v-model="idDocumento"
          @keyup="limparValidacao"
          class="input" 
          type="numer">
          <small class="textoValidacao" v-if="idDocumentoNaoInformado">Id Documento deve ser informado.</small>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="has-margin-top-5 has-margin-bottom-10">
      <span class="pulled-left is-size-8">* Campos obrigatórios.</span>
      <div class="column is-6 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="buscarDocumentoId($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'

  export default {
    mixins:[mixin],
    components: {
      SelecaoMenu
    },
    data(){
      return {
        idDocumento: "",
        idDocumentoNaoInformado: false
      }
    },
    methods: {
      buscarDocumentoId(server) {
        if(this.idDocumento) {
          this.$root.$emit('closeModal')
          this.$store.dispatch("buscarDocumentoId",{
            dados: this.idDocumento,
            server: server
          })
        }else{
          this.idDocumentoNaoInformado = true
        }
      },
      limparValidacao(){
        this.idDocumentoNaoInformado = false
      }
    }
  }
</script>

<style lang='scss' scoped>
  .input:active {
    border-color: #dbdbdb;
    box-shadow: none;
  }
  .id-documento-nao-informado{
    border: 1px solid red;
    border-color: red !important;
  }
  
</style>