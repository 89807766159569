export default class consultarNotaModel {
    constructor(objeto) {

        this.dados =  {
            versao:'',
            chaveAcesso: '',
            serial: '',
            cpfCnpj: '',
            ambiente: '',
            uf:'',
            urlNotificacao: '',
            xml: ''
        },
        this.tipoConsulta = 'XML',
        this.tipoEnvio = 'SINCRONO',
        this.dadosUf =  {
            xUF: '',
            cUF: ''
        }

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}