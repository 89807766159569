<template>
    <div id="selecionarServidor">
       
        <button 
            id="selecionar-servidor-btn-envio"
            class="button btnEnvio"
            :class="[secundario? 'button-secundario': '']"
            @click="enderecoWebService('Dfe')"
            >{{btnTexto}}</button>
        <div
            class="dropdown Select-serve-dropdown"
            :class="[secundario? 'is-right': 'is-up', {'is-active': exibirConteudo}]"
            ref="dropdownMenu">
            <span id="span123">
            </span>
            <div class="dropdown-trigger">
                <button class="button"
                    id="selecionar-servidor-dropdown-envio"
                    :class="[secundario? 'dropdown-secundario': '']"
                    aria-haspopup="true" 
                    aria-controls="dropdown-menu"
                    @click="visible()"
                    title="Selecione um dos servidores para obter as configurações.">
                    <span class="icon is-small">
                        <i class="fas fa-angle-up" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a @click="enderecoWebService('Dfe')" class="dropdown-item">
                        Servidor Desenvolvimento
                    </a>
                    <a @click="enderecoWebService('Debug1')" class="dropdown-item">
                        Servidor Debug 1
                    </a>
                    <a @click="enderecoWebService('Debug2')" class="dropdown-item">
                        Servidor Debug 2
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from 'config'
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    props:{
        textoBotao: String,
        secundario: Boolean
    },
    computed:{
        btnTexto() {
            return this.textoBotao ? this.textoBotao : 'Enviar'
        }
    },
    data() {
        return {
            exibirConteudo: false
        }
    },
    methods:{
        visible(){
            this.exibirConteudo = !this.exibirConteudo
        },
        enderecoWebService(endereco) {
            let URi = ''
            switch (endereco) {
                case 'Dfe':
                    URi = config.Dfe
                break;
                case 'Debug1':
                    URi = config.Debug1
                    break;
                case 'Debug2':
                    URi = config.Debug2
                    break;
            }

            this.$emit("serverSelecionado", URi)
        },
        getElemento() {
            return this.$el;
        },
        close (e) {
            if (!this.getElemento().contains(e.target)) {
                this.exibirConteudo = false
                return
            }
        },
        adicionarEventListener() {
            this.innerHtmlUrl('msdfe-app').addEventListener('click', this.close)
        },
        removerEventListener() {
            this.innerHtmlUrl('msdfe-app').removeEventListener('click',this.close)
        }
    },
    mounted () {
        this.adicionarEventListener()
    },
    beforeDestroy () {
        this.removerEventListener()
    }
}
</script>

<style lang="scss" scoped>
    #selecionarServidor{
        width: 100%;
    }
    .dropdown-secundario{
        background-color: gray !important;
        color: white !important;
        border-radius: 5px;
    }
    .dropdown {
        width: 15%;
    }
    .btnEnvio {
        width: 85%;
    }
</style>