export const xmlNfce = {
    xml: `<NFe xmlns="http://www.portalfiscal.inf.br/nfe">
                <infNFe versao="4.00" Id="NFe33200403282458000179650010001234571278386842">
                    <ide>
                        <cUF>33</cUF>
                        <cNF>27838684</cNF>
                        <natOp>Venda de mercadoria a consumidor final</natOp>
                        <mod>65</mod>
                        <serie>1</serie>
                        <nNF>123457</nNF>
                        <dhEmi>2020-04-16T09:59:00-03:00</dhEmi>
                        <tpNF>1</tpNF>
                        <idDest>1</idDest>
                        <cMunFG>3305802</cMunFG>
                        <tpImp>4</tpImp>
                        <tpEmis>1</tpEmis>
                        <cDV>2</cDV>
                        <tpAmb>2</tpAmb>
                        <finNFe>1</finNFe>
                        <indFinal>1</indFinal>
                        <indPres>1</indPres>
                        <procEmi>0</procEmi>
                        <verProc>4.13.1.1</verProc>
                    </ide>
                    <emit>
                        <CNPJ>36462778000160</CNPJ>
                        <xNome>ALTERDATA TECNOLOGIA LTDA</xNome>
                        <enderEmit>
                            <xLgr>LOGRADOURO TESTE</xLgr>
                            <nro>333</nro>
                            <xBairro>BAIRRO TESTE</xBairro>
                            <cMun>3305802</cMun>
                            <xMun>TERESOPOLIS</xMun>
                            <UF>RJ</UF>
                            <CEP>25955560</CEP>
                            <fone>21971071033</fone>
                        </enderEmit>
                        <IE>99107014</IE>
                        <IM>ISENTO</IM>
                        <CNAE>0751201</CNAE>
                        <CRT>1</CRT>
                    </emit>
                    <det nItem="1">
                        <prod>
                            <cProd>000011</cProd>
                            <cEAN>7893500019244</cEAN>
                            <xProd>NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL</xProd>
                            <NCM>10063021</NCM>
                            <CFOP>5102</CFOP>
                            <uCom>PCT</uCom>
                            <qCom>1.0000</qCom>
                            <vUnCom>15.0000000000</vUnCom>
                            <vProd>15.00</vProd>
                            <cEANTrib>7893500019244</cEANTrib>
                            <uTrib>PCT</uTrib>
                            <qTrib>1.0000</qTrib>
                            <vUnTrib>15.0000000000</vUnTrib>
                            <indTot>1</indTot>
                        </prod>
                        <imposto>
                            <vTotTrib>1.68</vTotTrib>
                            <ICMS>
                                <ICMSSN102>
                                    <orig>0</orig>
                                    <CSOSN>102</CSOSN>
                                </ICMSSN102>
                            </ICMS>
                            <PIS>
                                <PISNT>
                                    <CST>07</CST>
                                </PISNT>
                            </PIS>
                            <COFINS>
                                <COFINSNT>
                                    <CST>07</CST>
                                </COFINSNT>
                            </COFINS>
                        </imposto>
                    </det>
                    <total>
                        <ICMSTot>
                            <vBC>0.00</vBC>
                            <vICMS>0.00</vICMS>
                            <vICMSDeson>0.00</vICMSDeson>
                            <vFCPUFDest>0.00</vFCPUFDest>
                            <vICMSUFDest>0.00</vICMSUFDest>
                            <vICMSUFRemet>0.00</vICMSUFRemet>
                            <vFCP>0.00</vFCP>
                            <vBCST>0.00</vBCST>
                            <vST>0.00</vST>
                            <vFCPST>0.00</vFCPST>
                            <vFCPSTRet>0.00</vFCPSTRet>
                            <vProd>15.00</vProd>
                            <vFrete>0.00</vFrete>
                            <vSeg>0.00</vSeg>
                            <vDesc>0.00</vDesc>
                            <vII>0.00</vII>
                            <vIPI>0.00</vIPI>
                            <vIPIDevol>0.00</vIPIDevol>
                            <vPIS>0.00</vPIS>
                            <vCOFINS>0.00</vCOFINS>
                            <vOutro>0.00</vOutro>
                            <vNF>15.00</vNF>
                            <vTotTrib>1.68</vTotTrib>
                        </ICMSTot>
                    </total>
                    <transp>
                        <modFrete>9</modFrete>
                    </transp>
                    <pag>
                        <detPag>
                            <tPag>01</tPag>
                            <vPag>15.00</vPag>
                        </detPag>
                    </pag>
                </infNFe>
            </NFe>`
    
}
