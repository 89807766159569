import { dadosUfMunicipio } from '@/helpers/listaDadosUf'

export default class inutilizarNota {
    constructor(objeto) {

        this.dados = {
            cpfCnpj: '',
            serial: '',
            xml: '',
            uf:'',
            versao:'',
            urlNotificacao: '',
            origem:''
        },
        this.serie = '',
        this.inicial = '',
        this.final = '',
        this.listaUf = dadosUfMunicipio

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}