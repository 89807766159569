<template>
  <div id="formCadastroEmpresa">
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#empresas-gravar-empresa"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
      </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-6 is-pulled-left has-padding-zero">
        <span>* CPF / CNPJ:</span>
        <input id="frm-cad-empresa-txt-cpfcnpj"
          ref="cpfCnpj"
          v-model="ultimoCpfCnpj"
          class="input " 
          @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
          @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
          maxlength="14"
          type="text">
      </div>
      <div class="column is-2 select is-pulled-left has-padding-zero">
        <span>* UF:</span>
        <select id="frm-cad-empresa-txt-uf"
          v-model="parametros.uf"
          class="input">   
          <option v-for="uf in ufs"
          :key="uf.nome">
          {{uf.nome}}</option>
        </select>
      </div>
      <div class="column is-12 is-pulled-left is-paddingless">
        <div class="is-pulled-left column is-6 has-padding-zero" >
          <span>E-mail:</span>
          <input id="frm-cad-empresa-txt-email"
            v-model="parametros.email"
            class="input" 
            type="text">
        </div>
        <div class="is-pulled-left column is-6 has-padding-zero" >
          <span>Site:</span>
          <input id="frm-cad-empresa-txt-site"
            v-model="parametros.site"
            class="input" 
            type="text">
        </div>
      </div>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <hr class="is-marginless has-margin-top-5">
      <div class="column is-3 select is-pulled-left has-padding-zero">
        <span>Ambiente:</span>
        <select id="frm-cad-empresa-txt-ambiente"
          v-model="ambiente"
          class="input">   
          <option v-for="ambiente in ambientes" 
                  :key="ambiente.descricao"
                  :value="ambiente.ambiente"
                  :selected="ambiente" >
                  {{ambiente.descricao}}
          </option>
        </select>
      </div>
      <div class="column is-2 is-pulled-left has-padding-zero">
        <span>Token:</span>
        <input id="frm-cad-empresa-txt-token"
          v-model="token"
          class="input" 
          type="number">
      </div>
      <div class="column is-5 is-pulled-left has-padding-zero">
        <span>CSC:</span>
        <input  id="frm-cad-empresa-txt-csc"
          v-model="csc"
          class="input" 
          type="numer">
      </div>
      <div class="column is-2 is-pulled-left has-padding-zero">
        <button id="frm-cad-empresa-btn-adicionar-itens"
          @click="adicionarItens"
          class="btnAdicionarItens button button-secundario has-margin-top-24 is-full-width">
          Adicionar
        </button>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero has-margin-top-5">
      <div class="tblClasse-lista">
        <table class="table is-striped is-narrow is-hoverable">
          <thead>
            <tr>
              <th>Ambiente</th>
              <th>Token</th>
              <th>CSC</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(csc, i) in parametros.listaCsc" :key="i" >
              <td  v-if="csc.ambiente === '1'">Produção</td>
              <td v-else-if="csc.ambiente === '2'">Homologação</td>
              <td v-else></td>
              <td>{{ csc.idToken }}</td>
              <td>{{ csc.csc }}</td>
              <td>
                <button :id="'frm-cad-empresa-txt-cpfcnpj-' + i"
                  class="btnRemoverItem" 
                  @click="removerItens(i)"><i class="fas fa-trash-alt fa-lg"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="is-marginless has-margin-top-10 has-margin-bottom-10">
      <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
      <div class="column is-4 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="cadastroEmpresa($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'

  export default {
    mixins:[mixin],
    components: {
      SelecaoMenu
    },
    data(){
      return {
        parametros: {
          uf: '',
          cpfCnpj: '',
          email: '',
          site:'',
          listaCsc: []
        },
        csc: '',
        token: '',
        ambiente: ''
      }
    },
    methods: {

      adicionarItens(){
        let item = {
          ambiente: this.ambiente,
          idToken : this.token,
          csc: this.csc
        }
        if(!this.verificarItenVazioListaCsc(item) === true){
          this.parametros.listaCsc.push(item)
          this.csc = ''
          this.token = ''
          this.ambiente = ''
        }
      },
      verificarItenVazioListaCsc(novoItem){
        let itemLista = {
          ambiente: '',
          idToken : '',
          csc: ''
        }
        if (JSON.stringify(novoItem) === JSON.stringify(itemLista)) {
          return this.parametros.listaCsc.find(item => JSON.stringify(item) === JSON.stringify(itemLista));
        }
      },
      removerItens(index){
         this.parametros.listaCsc.splice(index,1)
      },
      cadastroEmpresa(server) {
        this.$root.$emit('closeModal')
        this.parametros.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
        this.$store.dispatch("cadastroEmpresa",{
          parametros:{
            ...this.parametros
          },
          server: server
        })
      }
    }
  }
</script>