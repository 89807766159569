<template>
    <div>
        <div class="column is-12 is-paddingless is-pulled-left has-padding-top-20">
            <div class="column is-7 is-pulled-left has-padding-zero">
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-env-email-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-5 select is-pulled-left has-padding-zero">
                <span>* Modelo:</span>
                <select 
                    id="frm-env-email-txt-docAnexo"
                    v-model="tipoDocumentoAnexo" 
                    class="input">   
                    <option v-for="(tipo, i) in tiposXMl"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>E-mail:</span>
                <input 
                    id="frm-env-email-txt-email"
                    class="input" 
                    type="url" 
                    v-model="destinatarios">
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-12 is-pulled-left has-padding-top-5">
                 <span class="is-pulled-left">Seleção de XML para Anexo:</span>
                <label class="input has-tooltip-multiline"
                :data-tooltip="mensagemNaoSelecionado(this.xmlTooltip)"
                >
                <input
                    id="frm-env-email-txt-carregarXML"
                    class="button"
                    style="display: none"
                    multiple
                    type="file"
                    ref="fileInput"
                    accept=".xml"
                    @change="carregarXML"/>{{ mensagemNaoSelecionado(msgQtXmlSelecionado) }}</label>
            </div>
            <div class="column is-12 is-pulled-left has-padding-top-5">
                 <span class="is-pulled-left">Seleção de PDF para Anexo:</span>
                <label class="input has-tooltip-multiline"
                :data-tooltip="mensagemNaoSelecionado(this.pdfTooltip)">
                <input
                    id="frm-env-email-txt-carregarPDF"
                    class="button"
                    style="display: none"
                    multiple
                    type="file"
                    ref="fileInput"
                    accept=".pdf"
                    @change="carregarPDF"/>{{ mensagemNaoSelecionado(msgQtPdfSelecionado) }}</label>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-5 is-pulled-right is-paddingless">
                <div class="column is-12 is-pulled-right">
                    <div class="column is-10 is-pulled-left is-paddingless">
                        <SelecaoMenu 
                            textoBotao="Enviar"
                            @serverSelecionado="envioEmailAnexo($event)" 
                            class="is-pulled-right"/>
                    </div>
                    <div class="column is-2 is-pulled-left is-paddingless has-text-centered has-padding-top-5 has-padding-left-10">
                        <a href="https://dfe-dev.alterdata.com.br/docs/#envio-de-e-mail-envio-de-e-mail-com-anexos-do-xml-e-do-danfe"
                            target="_blank">
                            <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    data(){
        return{
            msgQtXmlSelecionado: '',
            msgQtPdfSelecionado: '',
            xmlTooltip: '',
            xmlSelecionados:'',
            pdfTooltip:'',
            pdfSelecionados:'',
            anexos: false,
            files:[],
            destinatarios: '',
            tipoDocumentoAnexo: '',
            tiposXMl: [
                {codigo: 1, descricao: 'NF-e'},
                {codigo: 2, descricao: 'NFC-e'},
                {codigo: 3, descricao: 'Cancelamento'},
                {codigo: 4, descricao: 'Carta de Correção'},
                {codigo: 5, descricao: 'NFS-e'}
            ]
        }
    },
    methods: {
        mensagemNaoSelecionado(arquivo) {
            return arquivo === '' ? 'Nenhum arquivo selecionado.' : arquivo
        },

        carregarXML(e){
            this.xmlSelecionados = e.target.files
            let dadosArquivos = this.setFile(e.target.files)
            this.xmlTooltip = dadosArquivos.ArquivosSelecionados.replace(/,/g, '\n')
            this.msgQtXmlSelecionado = dadosArquivos.mensagem
        },

        carregarPDF(e){
            this.pdfSelecionados = e.target.files
            let dadosArquivos = this.setFile(e.target.files)
            this.pdfTooltip = dadosArquivos.ArquivosSelecionados.replace(/,/g, '\n')
            this.msgQtPdfSelecionado = dadosArquivos.mensagem
        },

        setFile(e) {
            if(e.length > 0) {
                let mensagem = "Foram selecionados "+ e.length + " arquivos."
                let ArquivosSelecionados = []
                for(var i=0;i < e.length; i++){
                    ArquivosSelecionados = ArquivosSelecionados + e[i].name + ','
                }
                return {
                    mensagem,
                    ArquivosSelecionados
                }
            }
        },

        envioEmailAnexo(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("enviarEmailAnexo", {
                server: server,
                dados: {
                    xml: this.xmlSelecionados,
                    documentoAuxiliar: this.pdfSelecionados,
                    destinatarios: this.destinatarios,
                    tipoDocumentoAnexo: this.tipoDocumentoAnexo,
                    cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj)
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>

[data-tooltip]:not(.is-disabled).has-tooltip-multiline::before, [data-tooltip]:not(.is-loading).has-tooltip-multiline::before, [data-tooltip]:not([disabled]).has-tooltip-multiline::before {
    height: auto;
    width: 25rem;
    max-width: 25rem;
    text-overflow: clip;
    white-space: pre-line;
    word-break: keep-all;
    background-color: #2e4283b7;
}

</style>