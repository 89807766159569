<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-20">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-cons-evento-txt-cpfCnpj"
                    class="input" 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-8 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-cons-evento-txt-serial"
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-cons-evento-txt-uf"
                    class="input" 
                    v-model="params.dados.uf">   
                    <option v-for="(Ufs,i) in params.listaUf"
                        :key="i" 
                        :value="Ufs.xUF"
                        :selected="params.dados.uf">
                        {{Ufs.xUF}}</option>
                </select>
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-cons-evento-txt-versao"
                    class="input"
                    v-model="params.dados.versao" >   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="column is-4 select is-pulled-left has-padding-zero">
                <span>Ambiente:</span>
                <select 
                    id="frm-cons-evento-txt-ambiente"
                    v-model="params.dados.ambiente"
                    class="input">   
                    <option v-for="ambiente in ambientes" 
                            :key="ambiente.descricao"
                            :value="ambiente.ambiente"
                            :selected="params.ambiente" >
                            {{ambiente.descricao}}
                    </option>
                </select>
            </div>
             <div class="column is-4 is-pulled-left is-paddingless">
                <div class="column is-10 select is-pulled-left has-padding-zero">
                    <span>* Tipo Envio:</span>
                    <select 
                        id="frm-cons-evento-txt-tipoEnvio"
                        class="input"
                        v-model="params.tipoEnvio">
                        <option v-for="tipo in tipos"
                            :key="tipo">
                            {{tipo}}</option>
                    </select>
                </div>
                <div class="btnAbrirAjuda column is-2 is-pulled-left btn-ajuda">
                    <i class="fas fa-question-circle fa-lg" 
                        aria-hidden="true" 
                        title="Ajuda para o envio com base nos tipos de consulta e envio."
                        @click="abrirAjuda(params.tipoEnvio)"></i>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>* Chave de acesso:</span>
                <input 
                    id="frm-cons-evento-txt-chaveAcesso"
                    class="input" 
                    type="text"
                    v-model="params.dados.chaveAcesso">
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left has-margin-bottom-10 has-height-55">
            <div class="column is-12 is-pulled-left has-padding-zero"
                :class="{ 'is-hidden' : exibirWebHook()}">
                <span>* Url de Notificação(webhook):</span>
                <input 
                    id="frm-cons-evento-txt-urlNotificacao" 
                    v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="consultaEvento($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import consultarEventoModel from '../../model/compartilhado/consultarEvento'
import SelecaoMenu from '@/components/SelecionarServidor'

export default {
    mixins:[mixin],
        props: {
        tipoDoc: String
    },
    components: {
        SelecaoMenu
    },
    data(){
        return {
            params: new consultarEventoModel(),
            tipos: [
                'ASSINCRONA',
                'SINCRONA'
            ]
        }
    },
    methods: {
        abrirAjuda(parametro) {
            switch (parametro) {
                case 'ASSINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-evento-assíncrona', '_blank')
                    break;
                case 'SINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-evento-síncrona-por-chave-de-acesso', '_blank')
                    break;
                default :
                    alert('Para acessar ajuda, selecione o tipo de envio')
                    break;
            }

        },

        consultaEvento(server) {
            this.$root.$emit('closeModal')
            this.params.dados.cpfCnpj = this.retirarFormatacao(this.params.dados.cpfCnpj)
            this.params.server = server
            let paramsStore = {
                componente: 'consultarEvento' + this.tipoDoc,
                dados: this.params.dados,
                tipoEnvio: this.params.tipoEnvio
            }

            Object.keys(this.params).forEach(key => this.params[key] === "" ? delete this.params[key] : {})
            this.$store.dispatch("ArmazenarDadosStore", paramsStore)
            this.$store.dispatch("consultaEvento" , this.params )
        },

        exibirWebHook() {
            if(this.params.tipoEnvio === 'SINCRONA'){
                this.params.dados.urlNotificacao = ''
                return true
            }
        },

        lerDadosLocalStorage() {
            const localStorageDados = JSON.parse(localStorage.getItem(`consultarEvento${this.tipoDoc}`))
            if (localStorageDados) {
                this.params = new consultarEventoModel(localStorageDados)
            }
        }
    },
    created(){
        this.lerDadosLocalStorage()
    }
}
</script>

<style lang="scss" scoped>

    .btn-ajuda {
        padding: 30px 19px 0 0;
    }

    .textarea {
        resize: none;
    }

    .has-height-55 {
        height: 55px;
    }


</style>