import axios from 'axios'
import eventBus from '@/eventBus'

export const empresaServico = {
    cadastroEmpresa,
    cadastroLogotipo
}

//#region Cadatro de empresa
function cadastroEmpresa(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.parametros),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando envio do cadastro da empresa.")
            return data;
        }]
    }
    return axios( `${payload.server}/rest/empresas`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.cpfCnpj)
        commit("serialCertificado",response.data.serial)
        return response
    })
    .catch(function (error) {
        _gerarMensagemErro(dispatch, error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region cadastro Logotipo
function cadastroLogotipo(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const formData = new FormData();
    formData.append('cpfCnpj',payload.cpfCnpj)
    formData.append('arquivo',payload.arquivo)

    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando Upload de Imagem do Logotipo.")
            return data;
        }]
    }
    return axios( `${payload.server}/rest/empresas/logotipo_upload`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.cpfCnpj)
        commit("serialCertificado",response.data.serial)
        return response
    })
    .catch(function (error) {
        _gerarMensagemErro(dispatch, error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

function _gerarMensagemErro(dispatch,error){
    if(error.response) {
        eventBus.$emit('retornoApi', error.response.data)
    }
    dispatch('montarMensagemErro', error)
}