<template>
    <div id="formConsultarVersao">
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <i class="fas fa-question-circle fa-lg" 
                    aria-hidden="true" 
                    title="Obter ajuda da documentação"
                    @click="abrirAjuda(tipoDoc)"></i>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-text-centered has-padding-bottom-15">
            <div class="select has-padding-zero has-text-left">
                <span>* UF:</span>
                <select class="has-width-100 input" 
                    v-model="uf">   
                    <option v-for="(Uf,i) in ufs"
                        :key="i">
                        {{Uf.nome}}</option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="has-margin-bottom-10 has-margin-top-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-6 is-pulled-right is-paddingless">
                <SelecaoMenu
                    textoBotao='Obter'
                    @serverSelecionado="consultarVersao($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    props:{
        tipoDoc: String
    },
    data() {
        return {
            uf: ''
        }
    },
    methods: {
        abrirAjuda(parametro) {
            switch (parametro) {
                case '55':
                window.open('https://dfe-dev.alterdata.com.br/docs/#utilidades-retorno-de-versão-de-nf-e')
                    break
                case '65':
                window.open('https://dfe-dev.alterdata.com.br/docs/#utilidades-retorno-de-versão-de-nfc-e')
                    break
                case 'uteis':
                window.open('https://dfe-dev.alterdata.com.br/docs/#utilidades-data-e-hora-por-uf')
                    break
                default :
                    alert('Para acessar ajuda, selecione o tipo de envio')
                    break;
            }
        },
        retornarModelo(tipo){
            let obj = this.modelos.find(item => item.codigo === parseInt(tipo))
            return !!obj ? obj.modelo : 'uteis'
        },
        consultarVersao(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("consultaUteis", {
                server: server,
                uf: this.uf,
                modelo: this.retornarModelo(this.tipoDoc),
                tipoDoc: this.tipoDoc
            })
        }
    }
}
</script>