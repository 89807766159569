import axios from 'axios'
import config from 'config'
import store from '@/store'

function obterXmlMock( commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    return axios(`${config.Dfe}/rest/mock/documentos/${payload.tipoDocumento}/${payload.url}`)
        .then(function(response) {
            commit('gravarXml','testando')
            return response
        })
        .catch(function (error){
            commit('gravarXml','testando')
            store.dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            store.commit("exibirOcultarModalLoading", false)
        })
}

function xmlMock( commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    let xml = {xml:payload.xml}
    let parametroEndPoint = payload.xml === "" ? `/rest/mock/${payload.tipoDocumento}/criar_${payload.url}` : `/rest/mock/${payload.tipoDocumento}/${payload.id}`
    const requestOptions = {
        method:  payload === "" ? 'get' : payload.xml === "" ? 'post' : 'put',
        data: JSON.stringify(xml),
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.Dfe}${parametroEndPoint}`, requestOptions)
        .then(function(response) {
            return response
        })
        .catch(function (error){
            store.dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            store.commit("exibirOcultarModalLoading", false)
        })
        
}
function deletarXmlMock(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.Dfe}/rest/mock/${payload.tipoDocumento}/deletar/${payload.id}`, requestOptions)
        .then(function(response) {
            return response
        })
        .catch(function (error){
            store.dispatch('montarMensagemErro', error)
            return error
        })
        .finally (()=>{
            store.commit("exibirOcultarModalLoading", false)
        })
}

export const mockSefazServico = {
    obterXmlMock,
    xmlMock,
    deletarXmlMock,
    
}
