import { render, staticRenderFns } from "./FormProvedoresNfse.vue?vue&type=template&id=3b65a7f4&scoped=true&"
import script from "./FormProvedoresNfse.vue?vue&type=script&lang=js&"
export * from "./FormProvedoresNfse.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FormProvedoresNfse.vue?vue&type=style&index=0&id=3b65a7f4&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3b65a7f4",
  null
  ,true
)

export default component.exports