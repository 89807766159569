import Vue from "vue";
import Vuex from "vuex";

// Módulos referentes ao projeto MS-DF-e
import { msdfe } from './msdfe/msdfe.modulo'
import { configuracoes } from './msdfe/configuracoes.modulo'
import { certificado } from './msdfe/certificado.modulo'
import { documentoAuxiliar } from './msdfe/documentoAuxiliar.modulo'
import { documentos } from './msdfe/documentos.modulo'
import { email } from './msdfe/email.modulo.js'
import { empresa } from './msdfe/empresa.modulo'
import { enviosDoc } from './msdfe/enviosDoc.modulo'
import { gerarCaCert } from './msdfe/gerarCaCert.modulo'
import { util } from './msdfe/util.modulo'
import { mockSefaz } from './msdfe/mockSefaz.modulo.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dadosmodal:{
      largura: '',
      altura: '',
      titulo: ''
    },
    exibirOcultarModalConfirmacao: false,
    exibirOcultarLog: false,
    exibirOcultarMenu: '',
    exibirMensagemWebService: {
      exibirMensagem: false,
      tipoMensagem: "",
      textoMensagem: "",
    },
    modal_loading: false
  },
  mutations: {
    fnExibirOcultarMenu(state,payload) {
      state.exibirOcultarMenu = payload
    },
    exibirOcultarLog(state,payload) {
      state.exibirOcultarLog = payload
    },
    preencherMensagemWebService(state, payload) {
      state.exibirMensagemWebService = payload
    },
    fecharMensagem(state, payload) {
      state.exibirMensagemWebService.exibirMensagem = payload
    },
    exibirOcultarModalLoading(state,payload) {
      state.modal_loading = payload
    },
  },
  actions: {
    ArmazenarDadosStore({commit},payload){
      localStorage.setItem(payload.componente, JSON.stringify(payload))
    },
    montarMensagemErro({commit}, payload) {
      let message = {
        exibirMensagem: true,
        tipoMensagem: "erro",
        textoMensagem: payload.message ? payload.message : payload
      }
      commit('preencherMensagemWebService', message)
    },
  },
  modules: {
    msdfe,

  // declaração de módulos referentes ao projeto MS-DF-e
    configuracoes,
    certificado,
    documentoAuxiliar,
    documentos,
    email,
    empresa,
    enviosDoc,
    gerarCaCert,
    mockSefaz,
    util
  }
});