<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a v-if="tipoDoc === '55'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-danfe-por-xml-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
                <a v-if="tipoDoc === '65'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-danfe-por-xml-de-nfc-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-5 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-ger-danfe-xml-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-7 select is-pulled-left has-padding-zero">
                <span>* Situação:</span>
                <select 
                    id="frm-ger-danfe-xml-txt-situacao"
                    v-model="params.dados.situacao"
                    class="input">   
                    <option v-for="atributo in atributos" 
                            :key="atributo.codigo"
                            :value="atributo.codigo">
                            {{atributo.descricao}}
                    </option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-3  has-padding-zero" >
                <span>Fonte:</span>
                <input 
                    id="frm-ger-danfe-xml-txt-fonte"
                    v-model="params.tributosNota.fonte"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-2 is-pulled-left  has-padding-zero">
                <span>Federais:</span>
                <input 
                    id="frm-ger-danfe-xml-txt-federal"
                    v-model.number="params.tributosNota.totalFederal"
                    class="input" 
                    type="number">
            </div>
            <div class="column is-2 is-pulled-left has-padding-zero">
                <span>Municipais:</span>
                <input 
                    id="frm-ger-danfe-xml-txt-municipal"
                    v-model.number="params.tributosNota.totalMunicipal"
                    class="input" 
                    type="number">
            </div>
            <div class="column is-2 is-pulled-left has-padding-zero">
                <span>Estaduais:</span>
                <input 
                    id="frm-ger-danfe-xml-txt-estadual"
                    v-model.number="params.tributosNota.totalEstadual"
                    class="input" 
                    type="number">
            </div>
            <div class="column is-3 is-pulled-left has-padding-zero file-reader">
                <FileReader 
                    @load="carregarXml($event)"
                    :Btndescricao='"Carregar XML"'
                    class="button button-secundario is-full-width" />
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-12 has-padding-zero">
                <span v-text="descricaoXml === '' ? '* Informe o XML:' : 'XML: ' + descricaoXml"></span>
                <textarea 
                    id="frm-ger-danfe-xml-txt-xml"
                    v-model="params.dados.xml"
                    class="textarea"
                    rows="6"
                    @change="xmlTextAreaAlterado()"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="geracaoDanfeXml($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import FileReader from "@/components/importarArquivoNovo"
import gerarDanfeXmlModel from '../../model/compartilhado/gerarDanfeXml'
import { geraXML } from '@/helpers/gerarXML'
import SelecaoMenu from '@/components/SelecionarServidor'

export default {
    mixins:[mixin],
    components: {
        FileReader,
        SelecaoMenu
    },
    props: {
        tipoDoc: String,
    },
        data(){
        return {
            params: new gerarDanfeXmlModel(),
            descricaoXml: ''
        }
    },
    methods: {
        xmlTextAreaAlterado() {
            this.descricaoXml = ''
        },

        geracaoDanfeXml(server) {
            this.$root.$emit('closeModal')
            this.params.dados.cpfCnpj = this.retirarFormatacao( this.params.dados.cpfCnpj )
            let payload = {
                dados: { ...this.params.dados}, 
                tipoDoc: this.tipoDoc,
                chaveAcesso: geraXML.carregarDadosXmlDanfe( this.params.dados.xml ).chaveAcesso,
                componente: 'gerarDanfeXml' + this.tipoDoc,
                server: server
            }
            payload.dados.tributosNota = { ...this.params.tributosNota }
            Object.keys( payload.dados.tributosNota ).forEach( key => payload.dados.tributosNota[key] === "" ? delete payload.dados.tributosNota[key] : {} )
            this.$store.dispatch("ArmazenarDadosStore", payload)
            this.$store.dispatch( "geracaoDanfeXml" , payload )
        },

        carregarXml(param) {
            this.descricaoXml = param.nome
            this.params.dados.xml = geraXML.carregarDadosXmlDanfe( param.xml ).xml
        },
        
        preencherDadoComLocalStorage() {
            const localStorageDados = JSON.parse(localStorage.getItem(`gerarDanfeXml${this.tipoDoc}`))
            if (localStorageDados) {
                this.params = new gerarDanfeXmlModel(localStorageDados)
                this.params.tributosNota = localStorageDados.dados.tributosNota ? localStorageDados.dados.tributosNota : {}
            }
        }
    },
    created() {
        this.preencherDadoComLocalStorage()
    }
}
</script>


<style scoped>

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>