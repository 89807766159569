export const xmlCartaCorrecao = {
    xml:`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
    <evento xmlns="http://www.portalfiscal.inf.br/nfe" versao="1.00">
        <infEvento Id="ID1101103320053646277800016055001000144133184117607001">
            <cOrgao>33</cOrgao>
            <tpAmb>2</tpAmb>
            <CNPJ>36462778000160</CNPJ>
            <chNFe>33200536462778000160550010001441331841176070</chNFe>
            <dhEvento>2020-09-04T15:51:47-03:00</dhEvento>
            <tpEvento>110110</tpEvento>
            <nSeqEvento>1</nSeqEvento>
            <verEvento>1.00</verEvento>
            <detEvento versao="1.00">
            <descEvento>Carta de Correção</descEvento>
            <xCorrecao>Teste de Carta de Correção em homologação.</xCorrecao>
            <xCondUso>A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularizacao de erro ocorrido na emissao de documento fiscal, desde que o erro nao esteja relacionado com: I - as variaveis que determinam o valor do imposto tais como: base de calculo, aliquota, diferenca de preco, quantidade, valor da operacao ou da prestacao; II - a correcao de dados cadastrais que implique mudanca do remetente ou do destinatario; III - a data de emissao ou de saida.</xCondUso>
            </detEvento>
        </infEvento>
    </evento>`,
    xmlCpf: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
    <evento xmlns="http://www.portalfiscal.inf.br/nfe" versao="1.00">
        <infEvento Id="ID1101103320053646277800016055001000144133184117607001">
            <cOrgao>33</cOrgao>
            <tpAmb>2</tpAmb>
            <CNPJ>36462778000160</CNPJ>
            <chNFe>33200536462778000160550010001441331841176070</chNFe>
            <dhEvento>2020-09-04T15:51:47-03:00</dhEvento>
            <tpEvento>110110</tpEvento>
            <nSeqEvento>1</nSeqEvento>
            <verEvento>1.00</verEvento>
            <detEvento versao="1.00">
            <descEvento>Carta de Correção</descEvento>
            <xCorrecao>Teste de Carta de Correção em homologação.</xCorrecao>
            <xCondUso>A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularizacao de erro ocorrido na emissao de documento fiscal, desde que o erro nao esteja relacionado com: I - as variaveis que determinam o valor do imposto tais como: base de calculo, aliquota, diferenca de preco, quantidade, valor da operacao ou da prestacao; II - a correcao de dados cadastrais que implique mudanca do remetente ou do destinatario; III - a data de emissao ou de saida.</xCondUso>
            </detEvento>
        </infEvento>
    </evento>`
}