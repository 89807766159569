<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-gravação-do-município-provedor-de-nfs-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="column is-6 is-pulled-left has-padding-zero">
                    <span>* Código Município:</span>
                    <input v-model="params.dados.codigoMunicipio"
                        class="input" 
                        type="text">
                </div>
                <div class="column is-6 select is-pulled-left has-padding-zero">
                    <span>* Ambiente:</span>
                    <select v-model="params.dados.ambiente"
                        class="input">   
                        <option v-for="(ambiente, i) in ambientes" 
                                :key="i"
                                :value="ambiente.ambiente"
                                :selected="params.dados.ambiente" >
                                {{ambiente.descricao}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>* Id Provedor:</span>
                <input v-model="params.dados.idProvedor"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>* Id Tipo Envio:</span>
                <input v-model="params.dados.idTipoEnvio"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>* Uri de consulta do documento:</span>
                <input v-model="params.dados.uri"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-6 is-pulled-right has-padding-zero">
                <SelecaoMenu 
                    @serverSelecionado="gravarMunicipioProvedor($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins.js'
import GravarMunicipioProvedor from '@/model/nfse/gravarMunicipioProvedor'

export default {
    mixins:[mixin],
    data(){
        return {
            params: new GravarMunicipioProvedor
        }
    },
    methods: {
        gravarMunicipioProvedor(server) {
            this.$root.$emit('closeModal')
            let paramsRequisicao = {
                ...this.params,
                server: server
            }
            this.$store.dispatch("ArmazenarDadosStore", this.params)
            this.$store.dispatch("gravarMunicipioProvedor" , paramsRequisicao)
        }
    },
    created() {
        const localStorageDados = JSON.parse(localStorage.getItem(`GravarMunicipioProvedor`))
        if (localStorageDados) {
            this.params = new GravarMunicipioProvedor(localStorageDados)
        }
    }
}
</script>