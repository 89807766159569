<template>
    <div>
        <div class="has-margin-top-25">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-documento-auxiliar-da-carta-de-correção-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-12 is-paddingless is-pulled-left has-padding-zero">
                <div class="column is-6 is-paddingless is-pulled-left has-padding-zero">
                    <div class="column is-12 is-pulled-left">
                        <span class="is-pulled-left has-padding-top-10">* Informe o XML de <b>NOTA</b> ou carregue um arquivo:</span>
                        <div class="column is-4 is-paddingless is-pulled-right">
                            <FileReader class="button button-secundario is-pulled-right is-full-width" 
                                @load="dados.xmlNota = $event.xml" 
                                tituloBotao="Carregar XML de nota"/>
                        </div>
                    </div>
                    <div class="column is-12 has-padding-zero">
                        <textarea
                            class="textarea"
                            rows="8"
                            v-model="dados.xmlNota"/>
                    </div>
                </div>
                <div class="column is-6 is-paddingless is-pulled-left has-padding-zero">
                    <div class="column is-12 is-pulled-left">
                        <span class="is-pulled-left has-padding-top-10">* Informe o XML do <b>EVENTO</b> ou carregue um arquivo:</span>
                        <div class="column is-4 is-paddingless is-pulled-right">
                            <FileReader class="button button-secundario is-pulled-right is-full-width"
                                @load="dados.xmlEvento = $event.xml" 
                                tituloBotao="Carregar XML de evento"/>
                        </div>
                    </div>
                    <div class="column is-12 has-padding-zero">
                        <textarea
                            class="textarea"
                            rows="8"
                            v-model="dados.xmlEvento"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-3 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="GerarImpressaoCce($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import mixin from '@/mixins'
    import FileReader from "@/components/importarArquivoNovo"

    export default {
        mixins:[mixin],
        components: {
            FileReader
        },
         data(){
            return {
                dados: {
                    cpfCnpj: '',
                    xmlNota: '',
                    xmlEvento: ''
                }
            }
        },
        methods: {
            GerarImpressaoCce(server) {
                this.$root.$emit('closeModal')
                store.dispatch("gerarImpressaoCce",{
                    dados: {
                        cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                        xmlDistribuicao :  this.limparXML(this.dados.xmlNota),
                        xmlDistribuicaoEvento : this.limparXML(this.dados.xmlEvento)
                    },
                    server: server
                })
            }
        }
    }
</script>

<style scoped>

    .textarea {
        resize: none;
    }

</style>