<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
            <span class="icon is-pulled-right">
                <i class="btnAbrirAjuda fas fa-question-circle fa-lg" 
                    aria-hidden="true" 
                    title="Obter ajuda da documentação"
                    @click="abrirAjuda(moduloSelecionado)"></i>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left has-padding-zero">
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-rem-con-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu
                    textoBotao='Remover'
                    @serverSelecionado="removerConfiguracoes($event)" 
                    class="is-pulled-right has-margin-top-5"/>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '@/mixins'

    export default {
        props: {
            modelo: String,
            TipoRequisao: String,
            moduloSelecionado: String
        },
        mixins:[mixin],
        methods: {
            selecionadarEndpointDestino(componente) {
                var configMap = new Map();
                configMap.set("RemoverConfiguracoesNfeEmissao", {tipo: 'emissao', modelo:'nfe'});
                configMap.set("RemoverConfiguracoesNfeDanfe", {tipo: 'danfe', modelo:'nfe'});
                configMap.set("RemoverConfiguracoesNfceDanfe", {tipo: 'danfe', modelo:'nfce'});
                configMap.set("RemoverConfiguracoesNfseEmissao", {tipo: 'emissao', modelo:'nfse'});

                return configMap.get(componente);
            },
            removerConfiguracoes(server) {
                this.$root.$emit('closeModal')
                this.$store.dispatch("removerConfiguracoes", {
                    cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                    server: server,
                    modelo: this.selecionadarEndpointDestino(this.moduloSelecionado).modelo,
                    TipoRequisao: this.selecionadarEndpointDestino(this.moduloSelecionado).tipo
                })
            },
            abrirAjuda(parametro) {
                switch (parametro) {
                    case 'RemoverConfiguracoesNfeDanfe':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#configurações-remover-configurações-de-danfe-de-nf-e')
                        break
                    case 'RemoverConfiguracoesNfceDanfe':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#configurações-remover-configurações-de-danfe-de-nfc-e')
                        break
                    case 'RemoverConfiguracoesNfeEmissao':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#configurações-remover-configurações-de-emissão-de-nf-e')
                        break
                    case 'RemoverConfiguracoesNfseEmissao':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#configurações-remover-configurações-de-emissão-de-nfs-e')
                        break
                    default :
                        alert('Para acessar ajuda, selecione o tipo de envio')
                        break;
                }
            }
        }
    }   
</script>

<style>

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
 
</style>