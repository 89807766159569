<template>
    <div>
        <div class="has-margin-top-25">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-documentos-assinatura"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input
                    id="frm-ass-doc-txt-cpfCnpj" 
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-ass-doc-txt-serial serialCertificado"
                    v-model="ultimoSerial"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-2 is-pulled-left has-padding-top-24 has-padding-bottom-5">
                <FileReader @load="dados.xml = $event.xml" class="button button-secundario is-full-width" />
            </div>
            <div class="column is-12 is-paddingless is-pulled-left has-padding-zero">
                <div class="column is-12 has-padding-zero">
                    <span>* Informe o XML:</span>
                    <textarea 
                        id="frm-ass-doc-txt-xml"
                        class="textarea"
                        rows="8"
                        v-model="dados.xml"/>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="assinarDocumento($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '@/mixins'
    import FileReader from "@/components/importarArquivoNovo"

    export default {
         mixins:[mixin],
        components: {
            FileReader
        },
         data(){
            return {
                dados: {
                    cpfCnpj: '',
                    serial: '',
                    xml: ''
                }
            }
        },
        methods: {
            assinarDocumento(server) {
                this.$root.$emit('closeModal')
                this.$store.dispatch("assinarDocumento",{
                    dados: {
                        cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                        serial: this.ultimoSerial,
                        xml: this.dados.xml
                    },
                    server: server
                })
            }
        }
    }
</script>

<style scoped>
    .textarea {
        resize: none;
    }

</style>