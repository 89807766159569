import {
    documentoAuxiliarServico 
} from '../../services'

export const documentoAuxiliar = {
    actions: {
        geracaoDanfeChave({ commit }, payload) {
            documentoAuxiliarServico.geracaoDanfeChave(commit,payload)
        },
        geracaoDanfeXml({ commit }, payload) {
            documentoAuxiliarServico.geracaoDanfeXml(commit,payload)
        },
        geracaoDanfseXml({ commit }, payload) {
            documentoAuxiliarServico.geracaoDanfseXml(commit,payload)
        },
        gerarImpressaoCce({ commit }, payload) {
            documentoAuxiliarServico.geracaoImpressaoCce(commit,payload)
        }
    }
}