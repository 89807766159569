<template>
    <div>
        <modal v-show="exibeOcultaModalConfiguraçoes"
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente"
                :abrirFecharModalTest="abrirFecharModalTest"
                :abrirFecharModalConfirmacao="abrirFecharModalConfirmacao"/>
        </modal>
        <TesteEmail v-if="exibeOcultaTestarEmail"
            :fecharModalTest="abrirFecharModalTest"
            :parametrosTesteEmail="parametrosTesteEmail"/>
        <ModalConfirmacao v-if="exibirOcultarModalConfirmacao"
            :ModalConfirmacaoAbrirTeste="ModalConfirmacaoAbrirTeste"
            :abrirFecharModalConfirmacao="abrirFecharModalConfirmacao"/>
    </div>
</template>

<script>
import Email from'@/views/configuracoes/FormEmail.vue'
import TesteEmail from '@/views/e-mail/FormTesteEmail.vue'
import EnviarEmailAnexo from '@/views/e-mail/FormEnvioEmailAnexo.vue'
import ModalConfirmacao from '@/components/ModalConfirmacao.vue'
import Modal from '@/components/Modal.vue'

export default {
    props: {
        modulo: String
    },
    components: {
        Email,
        TesteEmail,
        EnviarEmailAnexo,
        ModalConfirmacao,
        Modal
    },
    data() {
        return {
            componente: "",
            showModal: '',
            altura: '',
            largura: '',
            titulo:'',
            parametrosTesteEmail: {},
            exibeOcultaTestarEmail: false,
            exibirOcultarModalConfirmacao: false,
            exibeOcultaModalConfiguraçoes: true
        }
    },
    methods: {
        abrirModal(opt) {
            switch (opt) {
                case 'Email':
                    this.componente = 'Email'
                    this.altura = '410px'
                    this.largura = '700px'
                    this.titulo = 'Configurar E-mail'
                    break
                case 'EnviarEmailAnexo':
                    this.componente = 'EnviarEmailAnexo'
                    this.altura = '365px'
                    this.largura = '500px'
                    this.titulo = 'Enviar Email com Anexo'
                    break
            }
        },
        abrirFecharModalTest(exibeOculta, parametrosTesteEmail){
            this.exibeOcultaTestarEmail = exibeOculta
            this.exibeOcultaModalConfiguraçoes = !exibeOculta
            this.parametrosTesteEmail = parametrosTesteEmail
        },
        ModalConfirmacaoAbrirTeste(exibeOculta, parametrosTesteEmail) {
            this.exibeOcultaTestarEmail = exibeOculta
            this.exibirOcultarModalConfirmacao = !exibeOculta
            this.parametrosTesteEmail = parametrosTesteEmail
        },
        abrirFecharModalConfirmacao(exibeOculta){
            this.exibirOcultarModalConfirmacao = exibeOculta
            this.exibeOcultaModalConfiguraçoes = !exibeOculta
        },
        fecharModal() {
            this.showModal = false
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>

<style>

button {
    word-wrap: break-word
}
    

</style>