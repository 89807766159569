<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-margin-top-25">
            <div class="column is-3 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-cons-nota-txt-cpfCnpj"
                    class="input" 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-7 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-cons-nota-txt-serial"
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-cons-nota-txt-uf"
                    class="input" 
                    v-model="params.dadosUf">   
                    <option v-for="(Ufs,i) in listaUf"
                        :key="i" 
                        :value="Ufs"
                        :selected="params.dados.uf">
                        {{Ufs.xUF}}</option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-cons-nota-txt-versao"
                    class="input"
                    v-model="params.dados.versao" >   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>Ambiente:</span>
                <select 
                    id="frm-cons-nota-txt-ambiente"
                    v-model="params.dados.ambiente"
                    class="input">   
                    <option v-for="ambiente in ambientes" 
                            :key="ambiente.descricao"
                            :value="ambiente.ambiente"
                            :selected="params.dados.ambiente" >
                            {{ambiente.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Tipo consulta:</span>
                <select 
                    id="frm-cons-nota-txt-tipoConsulta"
                    class="input"
                    v-model="params.tipoConsulta">   
                    <option v-for="tipo in tiposConsulta"
                        :key="tipo">
                        {{tipo}}</option>
                </select>
            </div>

            <div class="column is-4 is-pulled-left is-paddingless">
                <div class="column is-10 select is-pulled-left has-padding-zero">
                    <span>* Tipo Envio:</span>
                    <select 
                        id="frm-cons-nota-txt-tipoEnvio"
                        class="input"
                        v-model="params.tipoEnvio">
                        <option v-for="tipo in tiposEnvios"
                            :key="tipo">
                            {{tipo}}</option>
                    </select>
                </div>
                <div class="column is-2 is-pulled-left btn-ajuda">
                    <i class="btnAbrirAjuda fas fa-question-circle fa-lg" 
                        aria-hidden="true" 
                        title="Ajuda para o envio com base nos tipos de consulta e envio."
                        @click="abrirAjuda"></i>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-5">
            <div class="column is-pulled-left has-padding-zero"
                :class="[params.tipoConsulta === 'Chave' ? 'is-12': 'is-8']">
                <span>* Url de Notificação(webhook):</span>
                <input 
                    id="frm-cons-nota-txt-urlNotificacao"
                    v-model="params.dados.urlNotificacao"
                    :disabled="params.tipoEnvio === 'SINCRONA'"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-4 is-pulled-right div-file-reader"
                :class="{ 'is-hidden': this.params.tipoConsulta === 'Chave' }">
                <FileReader class="button is-full-width button-secundario"
                    @load="carregarXml($event)" />
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left has-margin-bottom-15 has-height-190">
            <div class="column is-8 is-pulled-left has-padding-zero"
                :class="{ 'is-hidden' : this.params.tipoConsulta === 'XML'}">
                <span>* Chave de acesso:</span>
                <input 
                    id="frm-cons-nota-txt-chaveAcesso"
                    class="input"
                    type="text"
                    v-model="params.dados.chaveAcesso">
            </div>
            <div class="column is-12 is-pulled-left has-padding-zero"
                :class="{ 'is-hidden' : this.params.tipoConsulta === 'Chave'}">
                <span v-text="descricaoXml === '' ? '* Informe o XML:' : 'XML: ' + descricaoXml"></span>
                <textarea
                    id="frm-cons-nota-txt-xml"
                    class="textarea"
                    rows="8"
                    v-model="params.dados.xml"
                    @change="xmlTextAreaAlterado()"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="consultarNota($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import consultarNotaModel from '@/model/compartilhado/consultarNota'
import FileReader from "@/components/importarArquivoNovo"
import { geraXML } from '@/helpers/gerarXML'
import { dadosUfMunicipio } from '@/helpers/listaDadosUf'
import SelecaoMenu from '@/components/SelecionarServidor'

export default {
    mixins:[mixin],
    components: {
        FileReader,
        SelecaoMenu
    },
    props: {
        tipoDoc: String
    },
    data(){
        return {
            params: new consultarNotaModel(),
            listaUf: dadosUfMunicipio,
            tiposConsulta: [
                'XML',
                'Chave'
            ],
            tiposEnvios: [
                'ASSINCRONA',
                'SINCRONA'
            ],
            descricaoXml: ''
        }
    },
    methods: {
        xmlTextAreaAlterado() {
            this.descricaoXml = ''
        },
        consultarNota(server) {
            this.$root.$emit('closeModal')
            let payload ={
                ...this.contruirObjetoRequisicao(this.params),
                server: server,
                tipoDoc: this.tipoDoc
            }
            if(this.params.tipoConsulta === 'Chave') {
                this.$store.dispatch("consultaChaveAcesso" , payload)
            }
            if(this.params.tipoConsulta === 'XML') {
                this.$store.dispatch("consultaXml" , payload)
            }
        },
        abrirAjuda() {
            let parametro = this.params.tipoConsulta + this.params.tipoEnvio

            switch (parametro) {
                case 'ChaveSINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-nota-síncrona-por-chave-de-acesso', '_blank')
                    break;
                case 'XMLSINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-nota-síncrona-por-xml', '_blank')
                    break;
                case 'XMLASSINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-nota-assíncrona-por-xml', '_blank')
                    break;
                case 'ChaveASSINCRONA':
                    window.open('https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-nota-assíncrona-por-chave-de-acesso', '_blank')
                    break;
                default :
                    alert('Para acessar ajuda, selecione os tipos de consulta e envio')
                    break;
            }

        },
        contruirObjetoRequisicao(obj){
            obj.dados.cpfCnpj = this.retirarFormatacao(this.params.dados.cpfCnpj)
            obj.dados.uf = this.params.dadosUf.xUF
            obj.tipoConsulta = this.params.tipoConsulta
            obj.componente = 'consultarNota' + this.tipoDoc
            obj.dados.xml = this.tipoConsulta === 'Chave' ? '' : this.params.dados.xml
            obj.dados.chaveAcesso = this.tipoConsulta === 'XML' ? '' : this.params.dados.chaveAcesso 
            Object.keys(obj.dados).forEach(key => obj.dados[key] === "" ? delete obj.dados[key] : {})
            this.$store.dispatch("ArmazenarDadosStore", obj)
            return obj
        },
        carregarXml(param) {
            this.descricaoXml = param.nome
            this.params.dados.xml = geraXML.carregarDadosXml(param.xml)
        },
        lerDadosLocalStorage() {
            const localStorageDados = JSON.parse(localStorage.getItem(`consultarNota${this.tipoDoc}`))
            if (localStorageDados) {
                this.params = new consultarNotaModel(localStorageDados)
            }
        }
    },
    created(){
        this.lerDadosLocalStorage()
    }
}
</script>

<style lang="scss" scoped>

    .div-file-reader {
        padding: 24px 12px 0 12px;
    }

    .btn-ajuda {
        padding: 30px 19px 0 0;
    }

    .textarea {
        resize: none;
    }

    .has-height-190 {
        height: 190px;
    }

    .has-height-55 {
        height: 55px;
    }

@import "@/comum/styles/main.scss";
</style>