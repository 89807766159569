<template>
    <div>
        <modal  
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente"/>
        </modal>
    </div>
</template>

<script>
import SubirCertificado from '@/views/certificado/FormSubirCertificado.vue'
import CertificadoInfo from '@/views/certificado/FormCertificadoInfo.vue'
import CertificadoInfoArmazenado from '@/views/certificado/FormCertificadoInfoArmazenado.vue'
import ObterCertificadosVigentes from '@/views/certificado/FormObterCertificadosVigentes.vue'
import CertificadosCadastrados from '@/views/certificado/FormCertificadosCadastrados.vue'
import TestarCertificado from '@/views/certificado/FormTestarCertificado.vue'
import RemoverCertificado from '@/views/certificado/FormRemoverCertificado.vue'
import Modal from '@/components/Modal.vue'
import CabecalhoModal from '@/components/CabecalhoModal.vue'


export default {
    props: {
        modulo: String
    },
    components: {
        SubirCertificado,
        CertificadoInfo,
        CertificadosCadastrados,
        CertificadoInfoArmazenado,
        ObterCertificadosVigentes,
        TestarCertificado,
        RemoverCertificado,
        Modal,
        CabecalhoModal
    },
    data() {
        return {
            componente: "",
            showModal: '',
            altura: '',
            largura: '',
            titulo:''
        }
    },
    methods: {
        abrirModal(opt) {
            switch (opt) {
                case 'SubirCertificado':
                    this.componente = 'SubirCertificado'
                    this.altura = '256px'
                    this.largura = '500px'
                    this.titulo = 'Subir Certificado'
                    break
                case 'CertificadoInfo':
                    this.componente = 'CertificadoInfo'
                    this.altura = '209px'
                    this.largura = '500px'
                    this.titulo = 'Receber Info. Certificado local'
                    break
                case 'CertificadoInfoArmazenado':
                    this.componente = 'CertificadoInfoArmazenado'
                    this.altura = '260px'
                    this.largura = '500px'
                    this.titulo = 'Informações Certificado Armazenado'
                    break
                case 'TestarCertificado':
                    this.componente = 'TestarCertificado'
                    this.altura = '252px'
                    this.largura = '500px'
                    this.titulo = 'Testar Certificado Armazenado'
                    break
                case 'RemoverCertificado':
                    this.componente = 'RemoverCertificado'
                    this.altura = '252px'
                    this.largura = '500px'
                    this.titulo = 'Remover certificado'
                    break
                case 'ObterCertificadosVigentes':
                    this.componente = 'ObterCertificadosVigentes'
                    this.altura = '196px'
                    this.largura = '300px'
                    this.titulo = 'Certificados vigentes'
                    break
                case 'CertificadosCadastrados':
                    this.componente = 'CertificadosCadastrados'
                    this.altura = '196px'
                    this.largura = '320px'
                    this.titulo = 'Certificados Cadastrados'
                    break
            }
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>