import {mockSefazServico} from '@/services'

export const mockSefaz = {
    state: {
        xml:"teste"
    },
    mutations: {
        gravarXml(state,payload) {
            state.xml = payload
          },
    },
    actions: {
        obterXmlMock({commit}, payload) {
            return new Promise((resolve, reject) => {
                mockSefazServico.obterXmlMock(commit, payload).then(response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        xmlMock({commit}, payload) {
            return new Promise((resolve, reject) => {
                mockSefazServico.xmlMock(commit, payload).then(response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        deletarXmlMock({commit}, payload) {
            return new Promise((resolve, reject) => {
                mockSefazServico.deletarXmlMock(commit, payload).then(response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
    }
}