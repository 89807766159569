<template>
  <div>
    <div class="column is-12">
      <div>
        <p class="title is-pulled-left">
          MS-DFe
        </p>
      </div>
      <div class="is-pulled-left">
        <img :src="dfeImg">
      </div>
    </div>
    <div class="column  is-12 is-pulled-left">
      <p>Módulo integrador criado para testes nos serviços incluídos na API DF-e, desde,  envio de documentos eletrônicos, certificados e outros.</p>
    </div>
    <div class="column is-12 has-margin-top-50 is-pulled-left">
      <p>
        Documentação da API disponível aqui:
      </p>
      <span class="dfe-doc"><a href="https://dfe-dev.alterdata.com.br/docs/">https://dfe-dev.alterdata.com.br/docs/</a></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dfeImg: String
  }

}
</script>

<style lang="scss" scoped>
  .dfe-doc {
    float: left;
    border: 1px solid black;
    border-radius: 5px;
    width: 500px;
    background-color: rgb(46, 66, 131);
    padding: 20px;
    a{
      font-size: 18px;
      color: white;
      font-weight: 500;
    }
  }

  img {
    height: 50px;
    width: auto;
    margin-left: 20px;
    max-width: 80% !important;
  }


</style>