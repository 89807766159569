<template>
    <div>
        <modal 
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente"
                tipoDoc="uteis"/>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BuscarListaDocumentos from '@/views/documentos/FormBuscarListaDocumentos.vue'
import BuscarDocumentoId from '@/views/documentos/FormBuscarDocumentoId.vue'
import DataHoraUf from '@/views/utilidades/FormConsultarVersao.vue'
import EnviarVariasNotas from '@/views/utilidades/FormEnviarVariasNotas.vue'

export default {
    props: {
        modulo: String
    },
    components: {
        Modal,
        BuscarListaDocumentos,
        BuscarDocumentoId,
        DataHoraUf,
        EnviarVariasNotas
    },
    data() {
        return {
            showModal: false,
            altura: '',
            largura: '',
            titulo:'',
            componente: ''
        }
    },
    methods: {
        abrirModal(opt) {
            this.showModal = true
            switch (opt) {
                case 'BuscarListaDocumentos':
                    this.componente = 'BuscarListaDocumentos'
                    this.altura = '357px'
                    this.largura = '500px'
                    this.titulo = 'Buscar Lista Documentos'
                    break
                case 'BuscarDocumentoId':
                    this.componente = 'BuscarDocumentoId'
                    this.altura = '215px'
                    this.largura = '360px'
                    this.titulo = 'Buscar Documento por ID'
                    break
                case 'DataHoraUf':
                    this.componente = 'DataHoraUf'
                    this.altura = '200px'
                    this.largura = '300px'
                    this.titulo = 'Consultar Data Hora UF'
                    break
                case 'EnviarVariasNotas':
                    this.componente = 'EnviarVariasNotas'
                    this.altura = '300px'
                    this.largura = '450px'
                    this.titulo = 'Enviar Varias Notas'
                    break
            }
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>