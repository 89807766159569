import { validacoes }from './helpers/validacoes'

export default {
  data() {
    return {
      servidor: 'apiDfeUrl',
      servidoresSefaz: [
        'Normal',
        'SVC'
      ],
      tributosNota: {
        fonte: "",
        totalMunicipal : "",
        totalEstadual : "",
        totalFederal : "",
      },
      servidores: [
        { codigo: 'apiDfeUrl', nome: 'Desenvolvimento'},
        { codigo: 'Debug1', nome: 'Debug 1'},
        { codigo: 'Debug2', nome: 'Debug 2'},
      ],
      tpEmis: [
        {descricao: 'Normal', codigo: 1},
        {descricao: 'Contingência FSIA', codigo: 2},
        {descricao: 'Contingência FSDA', codigo: 5},
        {descricao: 'Contingência SVC-AN', codigo: 6},
        {descricao: 'Contingência SVC-RS', codigo: 7},
        {descricao: 'Contingência Off-line', codigo: 9}
      ],
      tpEmissNfce: [
        {descricao: 'Normal', codigo: 1},
        {descricao: 'Contingência Off-line', codigo: 9}
      ],
      ambientes: [
        {descricao: 'Homologação', ambiente: '2'},
        {descricao: 'Produção', ambiente: '1'}
      ],
      modelos: [
        {tipoDocumento: 'NF-e', modelo: 'NFE', codigo: 55},
        {tipoDocumento: 'NFC-e', modelo: 'NFCE', codigo: 65},
        {tipoDocumento: 'NFS-e', modelo: 'NFSE', codigo: 1}
      ],
      layouts: [
        {descricao:'A4' ,codigo :'A4'},
        {descricao:'40 Col' ,codigo :'QUARENTA_COLUNAS'}
      ],
      tiposNome: [
        {descricao:'Nome Fantasia' ,codigo :'NOME_FANTASIA'},
        {descricao:'Razão Social' ,codigo :'RAZAO_SOCIAL'}
      ],
      atributos: [
        {descricao: 'Pendente', codigo:'PENDENTE'},
        {descricao: 'Autorizada', codigo:'AUTORIZADA'},
        {descricao: 'Autorizada em contingência', codigo:'AUTORIZADA_EM_CONTINGENCIA'},
        {descricao: 'Cancelada', codigo:'CANCELADA'},
        {descricao: 'Denegada', codigo:'DENEGADA'},
        {descricao: 'Válida', codigo:'VALIDA'}
      ],
      ufs: [
        {nome: 'AC'},
        {nome: 'AL'},
        {nome: 'AM'},
        {nome: 'AP'},
        {nome: 'BA'},
        {nome: 'CE'},
        {nome: 'DF'},
        {nome: 'ES'},
        {nome: 'GO'},
        {nome: 'MA'},
        {nome: 'MG'},
        {nome: 'MS'},
        {nome: 'MT'},
        {nome: 'PA'},
        {nome: 'PB'},
        {nome: 'PE'},
        {nome: 'PI'},
        {nome: 'PR'},
        {nome: 'RJ'},
        {nome: 'RN'},
        {nome: 'RO'},
        {nome: 'RR'},
        {nome: 'RS'},
        {nome: 'SC'},
        {nome: 'SE'},
        {nome: 'SP'},
        {nome: 'TO'}
      ],
      versoes: [
        '4.00'
      ],
      componente: '',
      showModal: '',
      altura: '',
      largura: '',
      titulo:'',
      tiposImportacaoXML: [
        {descricao: "Gerar", codigo: 1},
        {descricao: "Carregar", codigo: 2},
        {descricao: "Informar", codigo: 3}
      ]
    }
  },
  props: {
    fecharModal: Function
  },
  computed: {
    ultimoSerial: {
      get () {
        return this.$store.getters.dadosEmpresa.serial
      },
      set (valor) {
        this.$store.commit('serialCertificado', valor)
      }
    },
    ultimoCpfCnpj: {
      get () {
        return this.$store.getters.dadosEmpresa.cpfCnpj
      },
      set (valor) {
        this.$store.commit('cpfCnpj', valor)
      }
    },
    storeUltimoCpfCnpj(){
      return this.$store.getters.dadosEmpresa.cpfCnpj
    }
  },
  methods: {
    limparXML(xml) {
        return xml.replace("\n","").replace("\r","").replace(/\\"/g, '"')
    },
    retirarFormatacao(cpfCnpj) {
      if(cpfCnpj != "" || !!cpfCnpj)
      {
        return validacoes.retirarFormatacao(cpfCnpj)
      }
    },
    formatarCampo(cpfCnpj){
      if(cpfCnpj != "" || !!cpfCnpj) 
      {
        return validacoes.formatarCampo(cpfCnpj)
      }
    },
    innerHtmlUrl(modulo) {
      if(process.env.NODE_ENV === 'production') {
        return document.querySelector('mf-atena-msdfe').shadowRoot.getElementById(modulo)
      }
      return document.getElementById(modulo)
    },
    formatarCpfCnpjSeExistir(CpfCnpj){
      if(CpfCnpj) {
        this.ultimoCpfCnpj = validacoes.formatarCampo(CpfCnpj)
      }
    }
  },
  mounted() {
    this.formatarCpfCnpjSeExistir(this.storeUltimoCpfCnpj)
  }
}