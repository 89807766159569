import {
    gerarCaCertServico
} from '../../services'


export const gerarCaCert = {
    actions: {
        enviarCaCert({ commit, dispatch},payload) {
            gerarCaCertServico.enviarCaCert(commit, dispatch)
        }
    }
}
