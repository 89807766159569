import {
    documentosServico 
} from '../../services'

export const documentos = {
    
    actions: {
        assinarDocumento({commit}, payload) {
            documentosServico.assinarDocumento(commit,payload)
        },
        buscarListaDocumentos({commit}, payload) {
            documentosServico.buscarListaDocumentos(commit,payload)
        },
        buscarDocumentoId({commit}, payload) {
            documentosServico.buscarDocumentoId(commit,payload)
        },
        obterUrlStatus({commit},payload){
            documentosServico.obterUrlStatus(commit, payload)
        },
        statusServico({commit}, payload) {
            documentosServico.statusServico(commit,payload)
        },
        validarXml({commit}, payload) {
            documentosServico.validarXml(commit, payload)
        },
        Inutilizacao({ commit }, payload) {
            documentosServico.inutilizacao(payload)
        }
    }


}