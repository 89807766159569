import axios from 'axios'
import eventBus from '@/eventBus'

//#region Obter dados do certificado
function certificadoInfo(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const formData = new FormData();
    formData.append('senha',payload.senha)
    formData.append('arquivo',payload.file)
    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Verificando os dados do certificado enviado.")
            return data;
        }]
    }
    return axios( `${payload.server}/rest/certificados/informacoes`,requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.cpfCnpj)
        commit("serialCertificado",response.data.serial)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region certificados
function certificados(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        params: { cpfCnpj: payload.cpfCnpj},
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Verificando os dados do certificado enviado.")
            return data;
        }]
    }
    return axios( `${payload.server}/rest/certificados`,requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.cpfCnpj)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter dados do certificado armazenado no serviço
function certificadoInfoArmazenado(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        params: {
            cpfCnpj: payload.cpfCnpj,
            serial: payload.serial
          },
          transformRequest: [function (data, headers) {
                eventBus.$emit('envioApi', "Verificando certificados armazenados na Amazon.")
                return data;
          }]
    }

    return axios(`${payload.server}/rest/certificados/informacoes`, requestOptions)
    .then(function(response) {
      eventBus.$emit('retornoApi', response ? response.data : response)
      commit("serialCertificado",response.data.serial)
      return response
    })
    .catch(function (error) {
      eventBus.$emit('retornoApi', error.response ? error.response.data : error)
      dispatch('montarMensagemErro', error)
      return error
      }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter certificados vigentes cadastrados no serviço e que estão na validade
function obterCertificadosVigentes(commit, dispatch, payload){
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        params: {
            cpfCnpj: payload.cpfCnpj
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Obtendo certificados vigentes (na validade) que estão na Amazon.")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/certificados/vigentes`,requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.certificados[0].cpfCnpj)
        commit("serialCertificado", response.data.certificados[0].serial)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Realizar upload do certificado para o serviço
function subirCertificado(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const formData = new FormData();
    formData.append('senha', payload.senha)
    formData.append('cpfCnpj', payload.cpfCnpj)
    formData.append('email', payload.email)
    formData.append('arquivo',payload.file)
    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando envio do certificado e da senha para a Amazon")
            return data;
        }]
    }
    return axios( `${payload.server}/rest/certificados/upload`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        commit("cpfCnpj", response.data.cpfCnpj)
        commit("serialCertificado",response.data.serial)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region realizar teste do certificado armazenada no serviço

function testarCertificado(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando teste do certificado, aguarde.")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/certificados/testar`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Remover certificado armazenada no serviço

function removerCertificado(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const formData = new FormData();
    formData.append('serial', payload.dados.serial)
    formData.append('cpfCnpj', payload.dados.cpfCnpj)

    const requestOptions = {
        method: 'delete',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Remover certificado digital")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/certificados`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        dispatch('montarMensagemErro', error)
        return error
        }
    )
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

export const certificadoServico = {
    certificadoInfo,
    certificadoInfoArmazenado,
    obterCertificadosVigentes,
    subirCertificado,
    testarCertificado,
    removerCertificado,
    certificados
}