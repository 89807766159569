<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Confirmação</p>
                        <button class="delete" aria-label="close" @click="abrirFecharModalConfirmacao(false)"></button>
                    </header>
                    <section class="modal-card-body">
                        Existem campos necessários não informados, continuar?
                    </section>
                    <footer class="modal-card-foot">
                        <div class="is-full-width">
                            <button class="button is-pulled-right" @click="abrirFecharModalConfirmacao(false)">Cancel</button>
                            <button class="button is-pulled-right" @click="ModalConfirmacaoAbrirTeste(true)">Sim</button>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        ModalConfirmacaoAbrirTeste: Function,
        abrirFecharModalConfirmacao: Function
    },
    data() {
        return {
            styleObject: {
                width: this.largura,
                height: this.altura
            }
        }
    },
    methods: {
        OcultarMenu(event) {
            this.$root.$emit(event)
        },
        createdEventListener() {
            this.$store.commit('exibirOcultarLog', true)
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.$root.$emit('closeModal')
                }
            })
        }
    },
    mounted(){
        this.OcultarMenu('evtOcultarMenu')
    },
    created() {
        this.createdEventListener()
    }
}
</script>

<style lang="scss" scoped>
    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }

    .modal-mask {
        position: fixed;
        z-index: 40;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        height: 200px;
        width: 500px;
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        padding: 10px;
        margin-top: -30px;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>