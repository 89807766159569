<template>
  <div>
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#certificados-retornar-informações-de-certificado-digital"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
         </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-12 is-pulled-left has-padding-zero">
        <span>Clique para selecinar o certificado:</span>
        <label id="certificado" class="button column is-12 is-pulled-left">
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept=".crt,.pfx"
            @change="handleFileUpload"/><strong>Certificado: </strong> {{certificadoNome}}
        </label>
      </div>
      <div class="column is-12 is-pulled-left is-paddingless">
        <div class="column campoSenha is-pulled-left  has-padding-zero">
          <span>Senha:</span>
          <input
            v-model="dados.senha"
            class="input senha"
            :type="tipo">
          <p class="verSenha" type="password" @mousedown="tipo = 'text'" @mouseup="tipo = 'password'"><img src="@/assets/eye-2-48.png"></p>
        </div>
        <div class="column is-6 is-pulled-left has-padding-top-24">
          <SelecaoMenu
            textoBotao='Obter'
            @serverSelecionado="enviaCertificado($event)" 
            class="is-pulled-right"/>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
  import store from "@/store";
  import mixin from '@/mixins';
  import SelecaoMenu from '@/components/SelecionarServidor';

  export default {
    components: {
      SelecaoMenu
    },
    mixins:[mixin],
    name: 'Parent',
    data(){
      return {
        tipo: 'password',
        certificadoNome:'',
        dados: {
          file: '',
          senha: ''
        }
      }
    },
    methods: {
      handleFileUpload(event){
        this.$refs.fileInput.click()
        this.dados.file = event.target.files[0]
        const certificado = event.target.files
        if(certificado[0] !=undefined){
          if((certificado[0].name).length > 39){
            this.certificadoNome = certificado[0].name.substr(0,27) + ' ... ' + certificado[0].name.substr(-6)
          }else{
            this.certificadoNome = certificado[0].name
          }
        }
      },
      enviaCertificado(server) {
        this.$root.$emit('closeModal')
        store.dispatch("certificadoInfo",{
          ...this.dados,
          server: server
        })

      }
    }
  }
</script>

<style scoped>
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  #certificado {
    text-align: left !important;
    padding: 7px;
  }

  .campoSenha {
    width: 240px;
  }

  .senha {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    float: left;
    width: 186px;
  }

  .verSenha {
    float: right;
    border: 1px solid grey;
    border-color: #dbdbdb;
    width: 30px;
    height: 32px;
    padding: 7px 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .verSenha:hover {
    background-color: rgb(236, 234, 234);
    border: 1px solid rgba(160, 160, 160, 0.897);
  }

  .verSenha:active {
    background-color: rgba(146, 142, 142, 0.774);
    border: 1px solid grey;
  }

  img {
    height: auto;
    max-width: 80% !important;
  }

</style>