<template>
    <div class="is-pulled-left has-margin-top-15">
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-7 is-pulled-left has-padding-zero" >
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-con-danfce-txt-cpfCnpj" 
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-5 is-pulled-right has-margin-top-12">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Obter configurações"
                        :secundario="true"
                        @serverSelecionado="obterConfiguracoes($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-retornar-configurações-de-danfe-de-nfc-e"
                        target="_blank"
                        class="is-pulled-right">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-4 select is-pulled-left has-padding-zero">
                <span>Impressão Desconto item:</span>
                <select 
                    id="frm-con-danfce-txt-tipoImpressaoDescontoItem" 
                    v-model="configuracoesEmpresa.tipoImpressaoDescontoItem"
                    class="input">
                    <option v-for="tipoImpressao in tiposImpressao"
                        :key="tipoImpressao.codigo"
                        :value="tipoImpressao.codigo">
                        {{tipoImpressao.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-4 select is-pulled-left has-padding-zero">
                <span>Formato:</span>
                <select 
                    id="frm-con-danfce-txt-layoutImpressao" 
                    v-model="configuracoesEmpresa.layoutImpressao" 
                    class="input">   
                    <option v-for="layout in layouts"
                        :key="layout.codigo"
                        :value="layout.codigo">
                        {{layout.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-4 select is-pulled-left has-padding-zero">
                <span>Tipo nome:</span>
                <select 
                    id="frm-con-danfce-txt-tipoNomeEmitente" 
                    v-model="configuracoesEmpresa.tipoNomeEmitente"
                    class="input">
                    <option v-for="nome in tiposNome"
                        :key="nome.codigo"
                        :value="nome.codigo">
                        {{nome.descricao}}
                    </option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left pt-5 pb-0">
            <div class="column is-4 is-pulled-left px-0">
                <input 
                    id="frm-con-danfce-txt-codigoItemPreferenciaEan" 
                    class="column is-1 is-pulled-left checkbox" 
                    type="checkbox" 
                    v-model="configuracoesEmpresa.codigoItemPreferenciaEan">
                <span class="column is-11 is-pulled-left checkboxText">Usar Preferencialmente EAN.</span>
            </div>
            <div class="column is-5 is-pulled-left px-2">
                <input 
                    id="frm-con-danfce-txt-limitarItemPorLinha" 
                    class="column is-1 is-pulled-left checkbox" 
                    type="checkbox" 
                    v-model="configuracoesEmpresa.itemPorLinha">
                <span class="column is-11 is-pulled-left checkboxText">Item em uma linha.</span>
            </div>
        </div>
        
        <div class="column is-12 is-paddingless is-pulled-left">
            <hr class="is-marginless">
            <div class="column is-5 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Salvar configurações"
                        @serverSelecionado="salvarConfiguracoes($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-gravar-configurações-de-danfe-de-nfc-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre salvar as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    computed: {
        configuracoesEmpresa() {
            return  this.$store.state.configuracoes.configuracoesDanfeNfce
        }
    },
    data() {
        return {
            tiposImpressao: [
                {descricao: 'Não Imprimir', codigo: 'NAO_IMPRIMIR'},
                {descricao: 'Imprimir abaixo', codigo: 'IMPRIMIR_ABAIXO'}
            ],
        }
    },
    methods: {
        obterConfiguracoes(server) {
            this.$store.dispatch("danfeNfce", {
                cpfCnpj : this.retirarFormatacao(this.ultimoCpfCnpj),
                server: server
            })
        },
        salvarConfiguracoes(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("danfeNfce", {
                cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                configuracoes: this.configuracoesEmpresa,
                server: server
            })
        }
    }

}
</script>

<style scoped>
    .checkbox {
        margin-top: 5px;
    }

    .checkboxText{
        padding: 3px;
        font-size: 12px;
    }

</style>