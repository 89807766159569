<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#status-status-do-serviço-da-sefaz"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-sta-servico-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-8 is-pulled-left has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-sta-servico-txt-serial"
                    v-model="ultimoSerial"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select 
                    id="frm-sta-servico-txt-ambiente"
                    v-model="dados.ambiente"
                    class="input">   
                    <option v-for="ambiente in ambientes" 
                            :key="ambiente.descricao"
                            :value="ambiente.ambiente">
                            {{ambiente.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-sta-servico-txt-uf"
                    v-model="dados.uf" 
                    class="input">   
                    <option v-for="uf in ufs"
                    :key="uf.nome">
                    {{uf.nome}}</option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-sta-servico-txt-versao"
                    v-model="dados.versao" 
                    class="input">   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="has-margin-top-20 has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="statusServico($event)" 
                    class="is-pulled-right has-margin-top-5"/>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    props: {
        tipoDoc: String
    },
    data(){
        return {
            dados: {
                cpfCnpj: '',
                serial: '',
                ambiente: '',
                modelo:'',
                uf: '',
                versao: '',
            }
        }
    },
    methods: {
        filterItems(tipoDoc) {
            this.dados.modelo = this.modelos.filter(el => el.codigo ===  parseInt(tipoDoc))[0].modelo
        },
        
        statusServico(server) { 
            this.$root.$emit('closeModal')
            this.dados.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
            this.dados.serial = this.ultimoSerial
        
            Object.keys(this.dados).forEach(key => this.dados[key] === "" ? delete this.dados[key] : {})
            this.$store.dispatch("statusServico", {
                dados: this.dados,
                server: server
            })
        },
    
    },
    mounted() {
        this.filterItems(this.tipoDoc)
    }
}
</script>

<style scoped>

</style>