<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a v-if="tipoDoc === '55'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nf-e-envio-de-documento-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
                <a v-if="tipoDoc === '65'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfc-e-envio-de-documento-de-nfc-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-3 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-env-nota-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-6 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    id="frm-env-nota-txt-serial"
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select 
                    id="frm-env-nota-txt-versao"
                    v-model="params.dados.versao" 
                    class="input">   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="is-pulled-left column is-1 has-padding-zero" >
                <span>* Origem:</span>
                <input 
                    id="frm-env-nota-txt-origem"
                    v-model.number="params.dados.origem"
                    class="input"
                    type="number"
                    title="Codigo do sistema que origina o XML.">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-1 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select 
                    id="frm-env-nota-txt-uf"
                    v-model="params.dadosUf" 
                    class="input">   
                <option v-for="(Ufs,i) in listaUf"
                    :key="i" 
                    :value="Ufs"
                    :selected="params.dadosUf.xUF">
                    {{Ufs.xUF}}</option>
                </select>
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>Fonte:</span>
                <input 
                    id="frm-env-nota-txt-fonte"
                    v-model="tributosNota.fonte"
                    class="input" 
                    type="text"
                    title="Fonte de consulta do valor base dos tributos">
            </div>
            <div class="is-pulled-left column is-1 has-padding-zero" >
                <span>Federais:</span>
                <input 
                    id="frm-env-nota-txt-federal"
                    v-model.number="tributosNota.totalFederal"
                    class="input"
                    type="number"
                    title="Total dos tributos Federais">
            </div>
            <div class="is-pulled-left column is-1 has-padding-zero" >
                <span>Estaduais:</span>
                <input 
                    id="frm-env-nota-txt-estadual"
                    v-model.number="tributosNota.totalEstadual"
                    class="input"
                    type="number"
                    title="Total dos tributos Estaduais">
            </div>
            <div class="is-pulled-left column is-1 has-padding-zero" >
                <span>Munic.:</span>
                <input 
                    id="frm-env-nota-txt-municipal"
                    v-model.number="tributosNota.totalMunicipal"
                    class="input"
                    type="number"
                    title="Total dos tributos Municipais">
            </div>
            <div class="column is-6 is-pulled-left is-paddingless">
                <span>Url de Notificação(webhook):</span>
                <input 
                    id="frm-env-nota-txt-urlNotificacao"
                    v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-marginless is-pulled-left is-full-width has-margin-top-10">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select 
                    id="frm-env-nota-txt-importacaoXML"
                    class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"'
                        @load="carregarXml($event)" />
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                     <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-2 is-pulled-left has-padding-zero">
                    <span>Série:</span>
                    <input 
                        id="frm-env-nota-txt-serie"
                        v-model="params.serie"
                        class="input" 
                        type="text">
                </div>
                <div class="column is-2 is-pulled-left has-padding-zero">
                    <span>Número:</span>
                    <input 
                        id="frm-env-nota-txt-numero"
                        v-model="params.numero"
                        class="input" 
                        type="text">
                </div>
                <div class="column is-4 select is-pulled-left has-padding-zero">
                    <span>Tipo Emissão:</span>
                    <select 
                        id="frm-env-nota-txt-tpEmis"
                        v-model="params.tpEmis"
                        class="input">   
                        <option v-for="emiss in tipoDoc === '65' ? tpEmissNfce : tpEmis" 
                                :key="emiss.codigo"
                                :value="emiss.codigo">
                                {{emiss.descricao}}
                        </option>
                    </select>
                </div>
                <div class="column is-2 is-pulled-right file-reader">
                    <button class="btnGerarDoc button button-secundario is-full-width" 
                        @click="gerarDoc">Gerar XML</button>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'informe-xml-txt-area' :'']">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea
                    id="frm-env-nota-txt-xml"
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"
                    class="textarea"
                    rows= 9
                    v-model="params.dados.xml"/>
            </div>
        </div>
         <div class="column is-12 is-pulled-left">
                <hr class="has-margin-bottom-10 has-margin-top-5">
                <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
                <div class="column is-3 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="EnviarNota($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
  </div>
</template>

<script>
import mixin from '@/mixins.js'
import FileReader from "@/components/importarArquivoNovo.vue"
import enviarNotaModel from '@/model/compartilhado/enviarNota.js'
import { geraXML } from '@/helpers/gerarXML.js'
import { dadosUfMunicipio } from '@/helpers/listaDadosUf.js'
import SelecaoMenu from '@/components/SelecionarServidor'

export default {
    mixins:[mixin],
    components: {
        FileReader,
        SelecaoMenu
    },
    props: {
        tipoDoc: String
    },
    data(){
        return {
            params: new enviarNotaModel(),
            listaUf: dadosUfMunicipio,
            xml: '',
            ImportacaoXML: 1,
            descricaoXml: ''
        }
    },
    methods: {
        importacaoXMLAlterado() {
            this.descricaoXml = ''
            this.params.dados.xml = ''
        },

        carregarXml(param) {
            this.descricaoXml = param.nome
            this.params.dados.xml = geraXML.carregarDadosXml(param.xml)
        },

        EnviarNota(server) {
            this.$root.$emit('closeModal')
            this.params.dados.uf = this.params.dadosUf.xUF
            this.params.componente = 'EnviarNota' + this.tipoDoc
            this.params.dados.tributosNota = this.tributosNota
            Object.keys(this.params.dados.tributosNota).forEach(key => this.params.dados.tributosNota[key] === "" ? delete this.params.dados.tributosNota[key] : {})
            
            
            let paramsRequisicao = {
                dados: this.params.dados,
                modelo: this.tipoDoc,
                server: server
            }
            this.$store.dispatch("EnviarNota" , paramsRequisicao)
            this.params.dados.xml = ''
            this.$store.dispatch("ArmazenarDadosStore", this.params)
        },

        gerarDoc() {
            this.innerHtmlUrl('frm-env-nota-txt-xml').innerHTML  = ""
            this.params.dados.xml = geraXML.gerarNota(this.tipoDoc,this.params,this.retirarFormatacao(this.params.dados.cpfCnpj))
        },

        lerDadosLocalStorage() {
            const localStorageDados = JSON.parse(localStorage.getItem(`EnviarNota${this.tipoDoc}`))
            if (localStorageDados) {
                this.params = new enviarNotaModel(localStorageDados)
                this.tributosNota =  this.params.dados.tributosNota
                this.params.componente = "EnviarNota" + this.tipoDoc
                this.params.numero = parseInt(this.params.numero) + 1
            }
            this.params.tpEmis = 1
        }
    },

    watch: {
        params: {
            handler(){
                this.params.dados.uf = this.params.dadosUf.xUF
                this.params.dados.tributosNota = this.tributosNota
            },
            deep: true
        }
    },
    created() {
        this.lerDadosLocalStorage()
    }
}
</script>

<style scoped>

    .informe-xml-txt-area {
        margin-top: 22px;
    }

    .textarea {
        resize: none;
    }

</style>