import eventBus from '@/eventBus'

function envioApi (mensagem) {
    eventBus.$emit('envioApi', mensagem)
}

function retornoApi (response) {
    response.data === "" ?  eventBus.$emit('retornoApi', 'Status: ' + response.status) :
                            eventBus.$emit('retornoApi', response ? response.data : response)
}

function errorApi (error, dispatch) {
    eventBus.$emit('retornoApi', error.response ? error.response.data : error)
    dispatch('montarMensagemErro', error)
}

function errorApiSemError (error, dispatch) {
    eventBus.$emit('retornoApi', error.response ? error.response.data : error)
}

export const mensagemLog = {
    envioApi,
    retornoApi,
    errorApi,
    errorApiSemError
}