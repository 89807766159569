<template>
    <div>
        <div class="column is-12 is-paddingless has-padding-top-35 is-pulled-left">
            <div class="select column is-3 is-pulled-left has-padding-zero">
                <span>Tipo de Nota</span>
                <select 
                    id="frm-mock-sefaz-txt-tiponota" 
                    class="input" 
                    v-model="selecionado">  
                    <option disabled value="">Tipo</option> 
                    <option value="nfe">NF-e</option>
                    <option value="nfce">NFC-e</option>
                </select>
            </div>
            <div class="select column is-6 is-pulled-left has-padding-zero">
                <span>Serviços</span>
                <select id="frm-mock-sefaz-txt-servico" class="input nfe" v-model="selecionadoNota">   
                    <option v-if="!selecionadoNota" disabled value="">Selecione um tipo de nota</option>
                    <option v-for="(nota,i) in listaNotas" :key="i"
                    :value="nota.url">{{nota.nome}}</option>
                </select>
            </div>
            <div class="column is-3 is-pulled-left has-padding-zero">
                <button class="button button-secundario is-full-width"
                        id="btnBuscar"
                        @click="obterXmlMock"><strong>Buscar</strong></button>
            </div>
            <div class="column is-12 has-margin-top-60">
                <span>Informe o XML:</span>
                <textarea
                    v-model="dadosXml"
                    id='frm-mock-sefaz-txt-xml'
                    class="textarea has-margin-top-10"
                    rows="6">
                </textarea>
            </div>
            <div class="column is-12">
            <button class="button is-pulled-right column is-4 has-padding-zero " 
                    @click="xmlMock"
                    v-if="metodo === 'post'"
                ><strong>Salvar</strong></button>
            <button class="button is-pulled-right column is-4 has-padding-zero " 
                    @click="xmlMock"
                    v-if="metodo === 'put'"
                ><strong>Atualizar</strong></button>
            <button class="button column is-pulled-right is-4 has-padding-zero  has-margin-right-10" 
                    @click="deletarXmlMock"
                    v-if="dadosXml !== ''" 
                ><strong>Excluir</strong></button>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () =>({
        metodo: "post",
        dadosXml: "",
        selecionado: "",
        selecionadoNota:"",
        opcoes: [
            {nome:'Envio Cancelamento',url:'retorno_envio_cancelamento',tipo:['nfe','nfce']},
            {nome:'Envio Carta Correcao',url:'retorno_envio_carta_correcao',tipo:['nfe']},
            {nome:'Envio de Status da SEFAZ',url:'retorno_status_sefaz',tipo:['nfe','nfce']},
            {nome:'Envio de Status de SVC',url:'retorno_status_svc',tipo:['nfe']},
            {nome:'Envio Lote',url:'retorno_envio_lote',tipo:['nfe','nfce']},
            {nome:'Envio da consulta de Recibo',url:'retorno_consulta_recibo',tipo:['nfe']},
            {nome:'Envio da consulta de nota',url:'retorno_consulta_nota',tipo:['nfe','nfce']},
            {nome:'Envio Inutilização',url:'retorno_envio_inutilizacao',tipo:['nfe','nfce']}
        ]
    }),
    computed: {
        listaNotas() {
            return this.filtroNotas()
        }
    },
    methods: {
        verificarTipo(opcao){
            return opcao.tipo.includes(this.selecionado)
        },
        filtroNotas(){
            return this.opcoes.filter(this.verificarTipo)
        },
        limpar() {
            this.dadosXml = ""
            this.selecionado = ""
            this.selecionadoNfe = ""
            this.selecionadoNfce = ""
            this.nfe = ""
            this.nfce = ""
            this.metodo = this.$store.state.mockSefaz.xml !== "" ? 'put' : 'post'
        },
        areaTexto(){
            document.getElementById("frm-mock-sefaz-txt-xml").disabled = false;
        },
        obterXmlMock() {
            let dados = {
                tipoDocumento: this.selecionado,
                url: this.selecionadoNota,
                xml: this.dadosXml
            }
            
            this.$store.dispatch('obterXmlMock', dados).then(() => {
                this.dadosXml = this.$store.state.mockSefaz.xml
            })

            this.metodo = this.$store.state.mockSefaz.xml !== "" ? 'put' : 'post'
            this.areaTexto()
        },
        xmlMock() {
            let dados = {
                tipoDocumento: this.selecionado,
                url: this.selecionadoNota,
                id: 'a1ad23a4-bd67-4b0d-be08-0a3774333be6',
                xml: this.dadosXml
            }
            this.$store.dispatch('xmlMock', dados)
        },
        deletarXmlMock() {
            let dados = {
                tipoDocumento: this.selecionado,
                id: 'a1ad23a4-bd67-4b0d-be08-0a3774333be6',
                xml: this.dadosXml
            }
            this.$store.dispatch('deletarXmlMock', dados)
            this.limpar()
        }
    }
}
</script>

<style lang="scss" scoped>
    
    .campoSenha {
        width: 150px;
    }
    .senha {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        float: left;
        width: 96px;
    }

    .verSenha {
        float: right;
        border: 1px solid grey;
        border-color: #dbdbdb;
        width: 30px;
        height: 32px;
        padding: 7px 3px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .verSenha:hover {
        background-color: rgb(236, 234, 234);
        border: 1px solid rgba(160, 160, 160, 0.897);
    }

    .verSenha:active {
        background-color: rgba(146, 142, 142, 0.774);
        border: 1px solid grey;
    }

    .checkboxText{
        font-size: 12px;
        padding: 3px 5px;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 15rem !important; 
    }

    .input[disabled] {
        cursor: initial !important;
    }
    img {
        height: auto;
        max-width: 80% !important;
    }

    .fa-exclamation-circle {
        float: right;
        color: red;
    }

  [data-tooltip]:not(.is-disabled).has-tooltip-multiline::before, [data-tooltip]:not(.is-loading).has-tooltip-multiline::before, [data-tooltip]:not([disabled]).has-tooltip-multiline::before {
    height: auto;
    width: 30rem;
    max-width: 30rem;
    text-overflow: clip;
    white-space: normal;
    word-break: keep-all;
    }
    #btnBuscar {
        margin-top: 24px
    }
</style>