<template>
    <div>
        <div class="column is-12 is-pulled-right has-padding-top-40">
            <div class="column is-11 is-pulled-left has-padding-zero">
                <SelecaoMenu 
                    textoBotao="Obter Tipos de Envio"
                    @serverSelecionado="retornarTiposEnvio($event)" 
                    class="is-pulled-right"/>
            </div>
            <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-retornar-tipos-de-envios-de-nfs-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como salvar as configurações."></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
export default {
    mixins:[mixin],
     methods: {
        retornarTiposEnvio(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("retornarTiposEnvio", server)
        }
    }

}
</script>