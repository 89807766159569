<template>
    <div class="column is-12 is-paddingless is-pulled-left has-padding-top-15">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left has-padding-bottom-5" >
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-con-nfe-txt-cpfCnpj" 
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-11 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Obter configuração"
                        :secundario="true"
                        @serverSelecionado="obterConfiguracoes($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-retornar-configurações-de-emissão-de-nf-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="column is-12 select is-pulled-left has-padding-zero">
            <span>Tipo de Contingência</span>
            <select 
                id="frm-con-nfe-txt-tipoContingencia"
                v-model="configuracoes.tipoContingencia"
                class="input">
                <option v-for="tipo in tiposContingencia"
                    :key="tipo.codigo"
                    :value="tipo.codigo">
                    {{tipo.descricao}}
                </option>
            </select>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <hr class="is-marginless has-margin-top-30">
            <div class="column is-11 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Salvar configuração"
                        @serverSelecionado="salvarConfiguracoes($event)" 
                        class="is-pulled-right has-margin-top-5"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-10">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-gravar-configurações-de-emissão-de-nf-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como salvar as configurações."></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
export default {
    mixins:[mixin],
    data() {
        return {
            tiposContingencia: [
                {descricao:'Contingência FSDA' ,codigo :'CONTINGENCIA_FSDA'},
                {descricao:'Contingência FSIA' ,codigo :'CONTINGENCIA_FSIA'},
                {descricao:'Não informado' ,codigo :'NAO_INFORMADO'}
            ],
        }
    },
    computed: {
        configuracoes() {
            return this.$store.state.configuracoes.parametros
        }
    },
    methods: {
        obterConfiguracoes(server) {
            let dados = {
                cpfCnpj : this.retirarFormatacao(this.ultimoCpfCnpj),
                modelo: 'nfe',
                server: server
            }
            this.$store.dispatch("Configuracoes", dados)
        },
        salvarConfiguracoes(server) {
            this.$root.$emit('closeModal')
            let dados = {
                cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                configuracoes: this.configuracoes,
                modelo: 'nfe',
                server: server
            }
            this.$store.dispatch("Configuracoes", dados)
        }
    }

}
</script>