<template>
    <div>
        <div class="column is-12 is-paddingless has-margin-top-15 is-pulled-left">
            <div class="column is-8 is-pulled-left has-padding-zero" >
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-email-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input"
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-4 is-pulled-left is-paddingless has-margin-top-12">
                <div class="column is-12 is-pulled-right">
                    <div class="column is-11 is-pulled-left is-paddingless">
                        <SelecaoMenu 
                            textoBotao="Obter configuração"
                            :secundario="true"
                            @serverSelecionado="obterConfiguracoesEmail($event, retirarFormatacao(ultimoCpfCnpj))" 
                            class="is-pulled-right"/>
                    </div>
                    <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5 has-padding-left-5">
                        <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-retornar-configurações-de-e-mail"
                            target="_blank">
                            <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-8 is-paddingless is-pulled-left" >
                <div class="column is-8 has-padding-top-5 has-padding-bottom-5 is-pulled-left">
                    <span>SMTP:</span>
                    <input 
                        id="frm-email-txt-smtp"
                        type="url" class="input" v-model="dadosEmail.smtp"
                        :class="{'campo-nao-informado':validacao.smtp}"
                        @keydown="limparValidacao('smtp')">
                    <small class="textoValidacao" v-if="validacao.smtp">SMTP não informado.</small>
                </div>
                <div class=" column is-4 has-padding-top-5 has-padding-bottom-5 is-pulled-left">
                    <span>Porta:</span>
                    <input 
                        id="frm-email-txt-porta"
                        type="text" class="input" v-model="dadosEmail.porta"
                        :class="{'campo-nao-informado':validacao.porta}"
                        @keydown="limparValidacao('porta')">
                    <small class="textoValidacao" v-if="validacao.porta">Porta não informada.</small>
                </div>
                <div class="column is-8 is-pulled-left has-padding-top-5 has-padding-bottom-5">
                    <span>E-mail:</span>
                    <input 
                        id="frm-email-txt-email"
                        type="url" class="input" v-model="dadosEmail.email"
                        :class="{'campo-nao-informado':validacao.email}"
                        @keydown="limparValidacao('email')">
                    <small class="textoValidacao" v-if="validacao.email">E-mail não informado.</small>
                </div>
                <div class="column campoSenha is-pulled-left has-padding-top-5 has-padding-bottom-5">
                    <span>Senha:</span>
                    <input
                        id="frm-email-txt-senha"
                        v-model="dadosEmail.senha"
                        class="input senha"
                        :type="tipo"
                        :class="{'campo-nao-informado':validacao.senha}"
                        @keydown="limparValidacao('senha')">
                    <p class="verSenha" 
                        type="password" 
                        @mousedown="tipo = 'text'" 
                        @mouseup="tipo = 'password'"
                        :class="{'campo-nao-informado':validacao.senha}">
                        <img src="@/assets/eye-2-48.png"></p>
                    <small class="textoValidacao" v-if="validacao.senha">Senha não informada.</small>
                </div>
                <div class="column is-12 is-pulled-left has-padding-top-5 has-paddin-bottom-5">
                    <span>Informação adicional:</span>
                    <textarea 
                        id="frm-email-txt-infoAddNFeAutorizada"
                        class="textarea" rows="2" v-model="dadosEmail.informacoesAdicionaisNFeAutorizada"></textarea>
                </div>
            </div>
            <div class=" column is-4 is-pulled-left">
                <div>
                    <input 
                        id="frm-email-txt-tls"
                        class="column is-1 is-pulled-left has-margin-top-15" 
                        type="checkbox" v-model="dadosEmail.tls">
                    <span class="column is-11 is-pulled-left has-margin-top-10 checkboxText">Utiliza conexão segura TLS</span>
                </div>
                <div>
                    <input 
                        id="frm-email-txt-autenticacao"
                        class="column is-1 is-pulled-left  has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.autenticacao">
                    <span class="column is-11 is-pulled-left checkboxText">Requer autenticação.</span>
                </div>
                <div>
                    <input 
                        id="frm-email-txt-ssl"
                        class="column is-1 is-pulled-left has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.ssl">
                    <span class="column is-11 is-pulled-left checkboxText">Utiliza conexão segura SSL</span>
                </div>
                <div>
                    <input 
                        id="frm-email-txt-copiaRemetente"
                        class="column is-1 is-pulled-left has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.copiaRemetente">
                    <span class="column is-11 is-pulled-left checkboxText">Copia remetente</span>
                </div>
                <div>
                    <input
                        id="frm-email-txt-enviaRetornoSefaz" 
                        class="column is-1 is-pulled-left has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.enviaRetornoSefaz">
                    <span class="column is-11 is-pulled-left checkboxText">Envia retorno Sefaz</span>
                </div>
                <div>
                    <input 
                        id="frm-email-txt-enviaArquivoDistribuicao"
                        class="column is-1 is-pulled-left has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.enviaArquivoDistribuicao">
                    <span class="column is-11 is-pulled-left checkboxText">Envia arquivo assinado</span>
                </div>
                <div>
                    <input 
                        id="frm-email-txt-enviaDanfe"
                        class="column is-1 is-pulled-left has-margin-top-5" 
                        type="checkbox" v-model="dadosEmail.enviaDanfe">
                    <span class="column is-11 is-pulled-left checkboxText">Envia Danfe</span>
                </div>
            </div>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="column is-4 is-pulled-right is-paddingless">
                    <div class="column is-12 is-pulled-right">
                        <div class="column is-11 is-pulled-left is-paddingless">
                            <SelecaoMenu 
                                textoBotao="Salvar configurações"
                                @serverSelecionado="salvarConfiguracoesEmail($event, retirarFormatacao(ultimoCpfCnpj))" 
                                class="is-pulled-right"/>
                        </div>
                        <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5 has-padding-left-5">
                            <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-gravar-configurações-de-e-mail"
                                target="_blank">
                                <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column is-3 is-pulled-right">
                    <button class="button button-secundario is-full-width" @click="validarCampos(dadosEmail)">Testar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    props:{
        abrirFecharModalTest: Function,
        abrirFecharModalConfirmacao: Function
    },
    computed: {
        dadosEmpresa() {
            return this.$store.state.email.dadosEmpresa
        },
        dadosEmail() {
            return this.$store.state.email.dadosEmpresa.dadosEmail
        }
    },
    data(){
        return{
            tipo: 'password',
            validacao: ''
        }
    },
    methods:{
        validarCampos(dadosEmail) {
            let abriModalTeste = true
            for(var key in dadosEmail) {
                if(dadosEmail[key] === "") {
                    this.validacao = {
                        ...this.validacao,
                        [key] : true};
                    abriModalTeste = false
                    
                }
            }
            abriModalTeste ? this.abrirFecharModalTest(abriModalTeste, dadosEmail) :
                            this.abrirFecharModalConfirmacao(!abriModalTeste, dadosEmail)
        },
        limparValidacao(campo) {
            delete this.validacao[campo]
        },
        obterConfiguracoesEmail(server, CpfCnpj) {
            this.validacao = ''
            this.$store.dispatch("ConfiguracoesEmail", { 
                cpfCnpj : CpfCnpj,
                server: server
            })
        },
        salvarConfiguracoesEmail(server, CpfCnpj) {
            this.$root.$emit('closeModal')
            this.$store.state.email.dadosEmpresa.cpfCnpj = CpfCnpj
            this.$store.dispatch("ConfiguracoesEmail", {
                server: server,
                ...this.dadosEmpresa
            })
        }
    }

}
</script>

<style lang="scss" scoped>
    
    .campoSenha {
        width: 150px;
    }
    .senha {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        float: left;
        width: 96px;
    }

    .verSenha {
        float: right;
        border: 1px solid grey;
        border-color: #dbdbdb;
        width: 30px;
        height: 32px;
        padding: 7px 3px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .verSenha:hover {
        background-color: rgb(236, 234, 234);
        border: 1px solid rgba(160, 160, 160, 0.897);
    }

    .verSenha:active {
        background-color: rgba(146, 142, 142, 0.774);
        border: 1px solid grey;
    }

    .checkboxText{
        font-size: 12px;
        padding: 3px 5px;
    }

    textarea {
        resize: none;
        width: 100%;
    }

    .input[disabled] {
        cursor: initial !important;
    }
    img {
        height: auto;
        max-width: 80% !important;
    }

  [data-tooltip]:not(.is-disabled).has-tooltip-multiline::before, [data-tooltip]:not(.is-loading).has-tooltip-multiline::before, [data-tooltip]:not([disabled]).has-tooltip-multiline::before {
    height: auto;
    width: 30rem;
    max-width: 30rem;
    text-overflow: clip;
    white-space: normal;
    word-break: keep-all;
    }
</style>