export const xmlCancelamento = {
    xml: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <evento xmlns="http://www.portalfiscal.inf.br/nfe" versao="1.00">
                        <infEvento Id="ID1101113320020328245800017965001000123433984117607801">
                            <cOrgao>33</cOrgao>
                            <tpAmb>2</tpAmb>
                            <CNPJ>36462778000160</CNPJ>
                            <chNFe>33200236462778000160650010001234339841176078</chNFe>
                            <dhEvento>2020-04-24T13:01:34-03:00</dhEvento>
                            <tpEvento>110111</tpEvento>
                            <nSeqEvento>1</nSeqEvento>
                            <verEvento>1.00</verEvento>
                            <detEvento versao="1.00">
                            <descEvento>Cancelamento</descEvento>
                            <nProt>
                            </nProt>
                            <xJust>Teste de cancelamento em homologação</xJust>
                            </detEvento>
                        </infEvento>
                    </evento>`,
    xmlCpf: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <evento xmlns="http://www.portalfiscal.inf.br/nfe" versao="1.00">
                        <infEvento Id="ID1101113320020328245800017965001000123433984117607801">
                            <cOrgao>33</cOrgao>
                            <tpAmb>2</tpAmb>
                            <CPF>74212238004</CPF>
                            <chNFe>33200200074212238004650010001234339841176078</chNFe>
                            <dhEvento>2020-04-24T13:01:34-03:00</dhEvento>
                            <tpEvento>110111</tpEvento>
                            <nSeqEvento>1</nSeqEvento>
                            <verEvento>1.00</verEvento>
                            <detEvento versao="1.00">
                            <descEvento>Cancelamento</descEvento>
                            <nProt>
                            </nProt>
                            <xJust>Teste de cancelamento em homologação</xJust>
                            </detEvento>
                        </infEvento>
                    </evento>`,
    nfse: `<?xml version='1.0' encoding='UTF-8' standalone='no'?>
        <CancelarNfseEnvio>
            <Pedido>
                <InfPedidoCancelamento Id='Nfse364627780001601124205000209'>
                    <IdentificacaoNfse>
                        <Numero>202000000000674</Numero>
                        <CodigoVerificacao>EC5H-E3CZ</CodigoVerificacao>
                        <Cnpj>36462778000160</Cnpj>
                        <InscricaoMunicipal>1124205</InscricaoMunicipal>
                        <CodigoMunicipio>3305802</CodigoMunicipio>
                    </IdentificacaoNfse>
                    <CodigoCancelamento>1</CodigoCancelamento>
                    <MotivoCancelamento>por que quero horas!! hehe</MotivoCancelamento>
                </InfPedidoCancelamento>
            </Pedido>
        </CancelarNfseEnvio>
    `
}
