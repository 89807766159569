import axios from 'axios'
import eventBus from '@/eventBus'
import store from '@/store'

//#region Geração de DANFCE por chave de acesso
function geracaoDanfeChave(commit , payload) {
    store.commit("exibirOcultarModalLoading", true)

    let tpDoc = ''
    let tpPdf = ''
    payload.tipoDoc === '55' ? (tpDoc = 'nfe', tpPdf = "gerarPdfNfe") : (tpDoc = 'nfce', tpPdf = "gerarPdfNfce");
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Será gerado o DANFE mod ${payload.tipoDoc}, caso o XML não se encontre na base de dados, a geração será apartir do NFstock.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documento_auxiliar/${tpDoc}/chave_acesso`, requestOptions)
    .then(function(response) {
        let danfe = {
            pdf : response.data,
            chaveAcesso: payload.chaveAcesso
        }
        commit(tpPdf, danfe)
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Geração de DANFCE por XML
function geracaoDanfeXml(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    let tpDoc = ''
    let tpPdf = ''
    payload.tipoDoc === '55' ? (tpDoc = 'nfe', tpPdf = "gerarPdfNfe") : (tpDoc = 'nfce', tpPdf = "gerarPdfNfce");
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Gerar DANFE modelo ${payload.tipoDoc} a partir do XML.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documento_auxiliar/${tpDoc}/xml`, requestOptions)
    .then(function(response) {
        let danfe = {
            pdf : response.data,
            chaveAcesso: payload.chaveAcesso
        }
        commit(tpPdf, danfe)
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Geração de DANFSE por XML
function geracaoDanfseXml(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Gerar DANFSE a partir do XML.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documento_auxiliar/nfse/xml`, requestOptions)
    .then(function(response) {
        let danfe = {
            pdf : response.data,
            chaveAcesso: payload.chaveAcesso
        }
        commit('gerarPdfNfse', danfe)
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Geracao de Impressao de CC-e
function geracaoImpressaoCce(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Gerar impressão de Carta de correção a partir do XML.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documento_auxiliar/nfe/carta_correcao/xml`, requestOptions)
    .then(function(response) {
        setTimeout(() => {
            let danfe = {
                pdf : response.data,
                chaveAcesso: 'Carta de correção'
            }
            commit("gerarCCe", danfe)
        }, 1000);
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion



export const documentoAuxiliarServico = {
    geracaoDanfeChave,
    geracaoDanfeXml,
    geracaoImpressaoCce,
    geracaoDanfseXml
}
