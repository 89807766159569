import { render, staticRenderFns } from "./FormConsultaNfse.vue?vue&type=template&id=71bb7b40&scoped=true&"
import script from "./FormConsultaNfse.vue?vue&type=script&lang=js&"
export * from "./FormConsultaNfse.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FormConsultaNfse.vue?vue&type=style&index=0&id=71bb7b40&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "71bb7b40",
  null
  ,true
)

export default component.exports