import store from "@/store";

function retirarFormatacao(cpfCnpj) {
  if(!!cpfCnpj) {
    return cpfCnpj = cpfCnpj.replace(/(\.|\/|-)/g,"");
  }
}

function formatarCampo(cpfCnpj) {
  let cpfCnpjSemFormatacao = this.retirarFormatacao(cpfCnpj);

  if(!cpfCnpj) {
  	return;
  }

  if (cpfCnpjSemFormatacao.length <= 11) {
    if(TestaCPF(cpfCnpjSemFormatacao)) {
      store.dispatch("atualizarCpfCnpj", cpfCnpjSemFormatacao)
      return cpfCnpj = mascaraCpf(cpfCnpjSemFormatacao);
    }
    
    alert("CPF digitado é inválido!")
    return cpfCnpj;
  }

  if(TestaCNPJ(cpfCnpjSemFormatacao)) {
    store.dispatch("atualizarCpfCnpj", cpfCnpjSemFormatacao)
    return cpfCnpj = mascaraCnpj(cpfCnpjSemFormatacao);
  }
  
  alert("CNPJ digitado é inválido!")
  return cpfCnpj
}

function mascaraCpf(cpfCnpj) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4");
}

function mascaraCnpj(cpfCnpj) {
    return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
}

function TestaCPF(cpfCnpj) {
  var Soma;
  var Resto;
  var i;
  Soma = 0;

  if (cpfCnpj === "00000000000") return false;
      
  for (i=1; i<=9; i++) Soma = Soma + parseInt(cpfCnpj.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  
  if ((Resto === 10) || (Resto === 11))  Resto = 0;
  if (Resto != parseInt(cpfCnpj.substring(9, 10)) ) return false;
  
  Soma = 0;
  for (i = 1; i <= 10; i++) Soma = Soma + parseInt(cpfCnpj.substring(i-1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11))  Resto = 0;
  if (Resto != parseInt(cpfCnpj.substring(10, 11) ) ) 
  return false;
  return true;
}

function TestaCNPJ(cpfCnpj) {
    var tamanho;
    var numeros;
    var digitos;
    var resultado;
    var soma;
    var pos;
    var i;
  
    if (cpfCnpj.length != 14) return false;
    if(cpfCnpj === "00000000000000" || 
      cpfCnpj === "11111111111111" || 
      cpfCnpj === "22222222222222" || 
      cpfCnpj === "33333333333333" || 
      cpfCnpj === "44444444444444" || 
      cpfCnpj === "55555555555555" || 
      cpfCnpj === "66666666666666" || 
      cpfCnpj === "77777777777777" || 
      cpfCnpj === "88888888888888" || 
      cpfCnpj === "99999999999999")
    return false;
    tamanho = cpfCnpj.length - 2
    numeros = cpfCnpj.substring(0,tamanho);
    digitos = cpfCnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
      pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
    return false;    
    tamanho = tamanho + 1;
    numeros = cpfCnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
      pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
    return false;    
    return true;
}

export const validacoes = {
  retirarFormatacao,
  formatarCampo
}
