import { dadosUfMunicipio } from '@/helpers/listaDadosUf'

export default class cartaCorrecaoModel {


    constructor(objeto) {

            this.dados = {
                cpfCnpj: '',
                serial: '',
                xml: '',
                uf:'',
                versao:'',
                urlNotificacao: '',
                origem: ''
            },
            this.chNFe ='',
            this.listaUf = dadosUfMunicipio,
            this.tipoXml ='',
            this.sequencial = ''

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}