<template>
    <div>
        <modal 
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente"/>
        </modal>
    </div>
</template>

<script>
import CadastroEmpresa from '@/views/empresas/FormCadastroEmpresa.vue'
import AssinarDocumento from '@/views/documentos/FormAssinarDocumento.vue'
import ValidarXml from '@/views/documentos/FormValidarXml.vue'
import GerarCaCert from '@/views/documentos/FormGerarCaCert.vue'
import Email from'@/views/configuracoes/FormEmail.vue'
import MockSefaz from "@/views/documentos/FormMockSefaz.vue"
import Modal from '@/components/Modal.vue'

export default {
    props: {
        modulo: String
    },
    components: {
        CadastroEmpresa,
        AssinarDocumento,
        ValidarXml,
        GerarCaCert,
        Email,
        MockSefaz,
        Modal
    },
    data() {
        return {
            componente: "",
            showModal: '',
            altura: '',
            largura: '',
            titulo:''
        }
    },
    methods: {
        abrirModal(opt) {
            switch (opt) {
                case 'CadastroEmpresa':
                    this.componente = 'CadastroEmpresa'
                    this.altura = '390px'
                    this.largura = '700px'
                    this.titulo = 'Cadastrar empresa'
                    break
                case 'AssinarDocumento':
                    this.componente = 'AssinarDocumento'
                    this.altura = '405px'
                    this.largura = '750px'
                    this.titulo = 'Assinar Documento'
                    break
                case 'ValidarXml':
                    this.componente = 'ValidarXml'
                    this.altura = '410px'
                    this.largura = '650px'
                    this.titulo = 'Validar XML'
                    break
                case 'GerarCaCert':
                    this.componente = 'GerarCaCert'
                    this.altura = '200px'
                    this.largura = '420px'
                    this.titulo = 'Gerar Cacert'
                    break
                case 'Email':
                    this.componente = 'Email'
                    this.altura = '505px'
                    this.largura = '700px'
                    this.titulo = 'Testes relacionados a E-mail'
                    break
                case 'MockSefaz':
                    this.componente = 'MockSefaz'
                    this.altura = '505px'
                    this.largura = '700px'
                    this.titulo = 'Mock Sefaz'
                    break
            }
        },
        fecharModal() {
            this.showModal = false
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>

<style>

button {
    word-wrap: break-word
}
    

</style>