export const xmlInutilizacao = {
    xml:`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                    <inutNFe xmlns="http://www.portalfiscal.inf.br/nfe" versao="4.00">
                        <infInut Id="ID33200328245800017965001000123446000123446">
                            <tpAmb>2</tpAmb>
                            <xServ>INUTILIZAR</xServ>
                            <cUF>33</cUF>
                            <ano>20</ano>
                            <CNPJ>36462778000160</CNPJ>
                            <mod>65</mod>
                            <serie>1</serie>
                            <nNFIni>123446</nNFIni>
                            <nNFFin>123446</nNFFin>
                            <xJust>Teste inutilização em homologação.</xJust>
                        </infInut>
                    </inutNFe>`
}
