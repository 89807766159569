import axios from 'axios'
import eventBus from '@/eventBus'
import store from '@/store'

//#region  Assinatura do documento XML
function assinarDocumento(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Assinando documento enviado.")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/documentos/assinar`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region buscar Lista Documentos
function buscarListaDocumentos(commit, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Buscando lista de documento.")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/documentos/consulta_documento_lote`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region buscar Documento por id
function buscarDocumentoId(commit, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
         headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Buscando lista de documento.")
            return data;
        }]
    }
    return axios(`${payload.server}/rest/documentos/consulta_documento/${payload.dados}`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter a URL para realizar o teste de consulta de status
function obterUrlStatus({commit},payload){
    store.commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        params: _parametrosStatus(payload).parametros,
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Obtendo endereço de status do servidor ${_parametrosStatus(payload).mensagem} da Sefaz selecionada.`)
            return data;
        }]
    }

    return axios(`${payload.server}/rest/status/${_parametrosStatus(payload).URL}`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Obter o status de serviço  
function statusServico({commit}, payload) {
    store.commit("exibirOcultarModalLoading", true)

    let url

    if(payload.servidor === 'SVC') {
        url = 'svc'
    }else{
        url = 'sefaz'
    }

    const requestOptions = {
        params: _parametrosStatus(payload).parametros,
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Verificando o status do servidor ${_parametrosStatus(payload).mensagem} da UF selecionada.`)
            return data;
        }]
    }

    return axios(`${payload.server}/rest/status/${url}`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Validar schema do xml
function validarXml(commit, payload) {
    store.commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', "Iniciando validação do certificado, aguarde.")
            return data;
        }]
    }

    return axios(`${payload.server}/rest/documentos/validar`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Envio de Inutilização
function inutilizacao(payload) {
    store.commit("exibirOcultarModalLoading", true)

    let urlEnvio = payload.modelo === '65' ? 'nfce/inutilizacao' : 'nfe/inutilizacao'
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            eventBus.$emit('envioApi', `Envio de inutilização modelo ${payload.modelo}.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${urlEnvio}`, requestOptions)
    .then(function(response) {
        eventBus.$emit('retornoApi', response ? response.data : response)
        return response
    })
    .catch(function (error) {
        eventBus.$emit('retornoApi', error.response ? error.response.data : error)
        store.dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        store.commit("exibirOcultarModalLoading", false)
    })
}
//#endregion



function _parametrosStatus(payload) {

    let parametros = {
        cpfCnpj: payload.dados.cpfCnpj,
        serial: payload.dados.serial,
        ambiente: payload.dados.ambiente,
        uf: payload.dados.uf,
        versao: payload.dados.versao
    }

    if(payload.servidor === 'SVC') {
        return {
            URL: 'svc/url',
            mensagem: 'SVC',
            parametros: {
                ...parametros   
            }
        }
    }else{
        return {
            URL: 'sefaz/url',
            mensagem: 'Normal',
            parametros: {
                ...parametros,
                modelo: payload.dados.modelo,
            }
        }
    }
}

export const documentosServico = {
    assinarDocumento,
    obterUrlStatus,
    statusServico,
    validarXml,
    inutilizacao,
    buscarListaDocumentos,
    buscarDocumentoId
}