<template>
  <div>
    <span class="is-size-6">Log das requisições</span>
    <div id="response" class="has-margin-top-5 is-pulled-leff">

    </div>
    <div class="column is-12 is-paddingless">
      <button @click="limparLog" class="button is-pulled-right column is-3 is-paddingless limparLog">
        Limpar Log
        <span class="icon is-small has-padding-left-10">
          <i class="fas fa-eraser" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>

</template>

<script>
import mixin from '@/mixins'
import eventBus from '@/eventBus'

export default {
  mixins: [
    mixin
  ],
  props:{
    selecionarFuncao: Function
  },
  data(){
    return {
      dataAtual: ''
    }
  },
  methods: {
    limparLog() {
      this.innerHtmlUrl('response').innerHTML  = ""
    },
    horaAtual() {
        this.dataAtual = new Date();
        const localdate = (this.dataAtual.getDate()<10?'0':'') + this.dataAtual.getDate() + '/' + 
                          (this.dataAtual.getMonth()+1) + '/' + 
                          this.dataAtual.getFullYear() + ' ' + 
                          this.dataAtual.getHours() + ':' + 
                          (this.dataAtual.getMinutes()<10?'0':'') + this.dataAtual.getMinutes() + ':' + 
                          this.dataAtual.getSeconds();
      return localdate;
    },
    imprimirJson(json) {
    return "<pre>" + JSON.stringify(json, null, 4).replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</pre>";
    },
    appendMessage(text) {
      this.innerHtmlUrl('response').innerHTML += "<p>" + text  + "</p>";
      this.innerHtmlUrl('response').scrollTop = this.innerHtmlUrl('response').scrollHeight
    },
    escutarEnvioApi() {
      eventBus.$off('envioApi')
      setTimeout(() => {
        eventBus.$on('envioApi', retorno => {
        this.appendMessage("Envio de solicitação: " + this.horaAtual() + this.imprimirJson(retorno))
      }, 500);
    })
    },
    escutarRetornoApi() {
      eventBus.$off('retornoApi')
      setTimeout(() => {
        eventBus.$on('retornoApi', retorno => {
        this.appendMessage("retorno recebido: " + this.horaAtual() + this.imprimirJson(retorno))
      }, 500);
    })
    }
  },
  created() {
    this.escutarEnvioApi()
    this.escutarRetornoApi()
  },
}
</script>

<style>
 #response {
   background-color: snow;
   border: 1px solid transparent;
   box-shadow: 0 0 3px gray;
   border-radius: 5px;
   padding: 10px;
   overflow: auto;
   height: calc(100vh - 280px);
 }

 .limparLog {
   margin-top: 12px;
 }

  input {
    font-size: 0.8rem !important;
    text-align: center !important;
  }
</style>