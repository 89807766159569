<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nf-e-carta-de-correção-do-documento-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input class="input" 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* UF:</span>
                <select class="input" 
                    v-model="params.dados.uf">   
                    <option v-for="(Ufs,i) in params.listaUf"
                        :key="i" 
                        :value="Ufs.cUF"
                        :selected="params.dados.uf">
                        {{Ufs.xUF}}</option>
                </select>
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Versão:</span>
                <select class="input"
                    v-model="params.dados.versao" >   
                    <option v-for="versao in versoes"
                        :key="versao">
                        {{versao}}</option>
                </select>
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input 
                    v-model.number="params.dados.origem"
                    class="input" 
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-6 is-pulled-left has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-6 is-pulled-left has-padding-zero">
                <span>Url de Notificação(webhook):</span>
                <input v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                    v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"'
                        @load="carregarXml($event)"/>
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                        <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-8 is-pulled-left has-padding-zero">
                    <span>Chave de acesso:</span>
                    <input class="input" 
                        type="text"
                        v-model="params.chNFe">
                </div>
                <div class="column is-2 is-pulled-left has-padding-zero">
                    <span>Sequêncial:</span>
                    <input class="input" 
                        type="text"
                        v-model="params.sequencial">
                </div>
                <div class="column is-2 is-pulled-right file-reader has-padding-zero">
                    <button class="button button-secundario is-full-width"
                        @click="gerarDoc">Gerar XML</button>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'informe-xml-txt-area' :'']">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea  
                    id="cartaDeCorrecaoTextArea"
                    class="textarea"
                    rows="6"
                    v-model="params.dados.xml"
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-10">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="cartaCorrecao($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import mixin from '@/mixins'
    import FileReader from "@/components/importarArquivoNovo"
    import { geraXML } from '@/helpers/gerarXML'
    import cartaCorrecaoModel from '@/model/nfe/cartaCorrecao'
    import SelecaoMenu from '@/components/SelecionarServidor'

    export default {
        mixins:[mixin],
        components: {
            FileReader,
            SelecaoMenu
        },
        data(){
            return {
                params: new cartaCorrecaoModel(),
                ImportacaoXML: 1,
                descricaoXml: ''
            }
        },
        methods: {
            importacaoXMLAlterado() {
                this.descricaoXml = ''
                this.params.dados.xml = ''
            },
            gerarDoc() {
                this.params.dados.cpfCnpj = this.retirarFormatacao(this.params.dados.cpfCnpj)
                this.innerHtmlUrl('cartaDeCorrecaoTextArea').innerHTML  = ""
                this.params.dados.xml = geraXML.gerarCartaCorrecao(this.params.dados, this.params.chNFe, this.params.sequencial)
            },
           carregarXml(param) {
                this.descricaoXml = param.nome
                this.params.dados.xml = geraXML.carregarXmlEvento(param.xml)
            },
            cartaCorrecao(server) {
                this.$root.$emit('closeModal')

                let paramsRequisicao = {
                    ...this.params,
                    server: server
                }
                store.dispatch('cartaCorrecao' , paramsRequisicao)

                let paramsStore = {
                    componente: 'cartaCorrecao',
                    dados: {...this.params.dados},
                    chNFe: this.params.chNFe,
                    sequencial: this.params.sequencial
                } 
                paramsStore.dados.xml = ''
                store.dispatch("ArmazenarDadosStore", paramsStore)
            },
        },
        created(){
            const localStorageDados = JSON.parse(localStorage.getItem(`cartaCorrecao`))
            if (localStorageDados) {
                this.params = new cartaCorrecaoModel(localStorageDados)
            }
        }
    }
</script>

<style scoped>

    .informe-xml-txt-area {
        margin-top: 22px;
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>