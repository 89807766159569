import axios from 'axios'
import {mensagemLog} from '@/utils/gerarMensagemLog'

export const enviosDocServico = {
    EnviarNota,
    EnviarVariasNotas,
    cancelarNota,
    EnviarNfse,
    cartaCorrecao,
    consultaEvento,
    consultaXml,
    consultaChaveAcesso,
    CancelarNfse,
    ConsultarNfse
}

function _request(url, requestOptions, commit, dispatch) {

    return axios(url, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })

}

//#region Enviar Nota

function EnviarNota(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    let urlEnvio = payload.modelo === '65' ? 'nfce' : 'nfe'

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Enviando nota modelo ${payload.modelo}.`)
            return data;
        }]
    }

    _request(`${payload.server}/rest/documentos/${urlEnvio}/envio`, requestOptions, commit, dispatch)    
    
}
//#endregion

//#region Enviar varias Notas

function EnviarVariasNotas(commit, dispatch, payload) {
    
    let urlEnvio = payload.modelo === '65' ? 'nfce' : 'nfe'

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            if(payload.progresso === 0) mensagemLog.envioApi(`Enviando nota modelo ${payload.modelo}.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${urlEnvio}/envio`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApiSemError(error, dispatch)
        return error
    })
}
//#endregion

//#region Enviar Cancelamento de Nota Fiscal de Serviço

function CancelarNfse(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Realizando envio de Cancelamento de NFS-e.`)
            return data;
        }]
    }

    _request(`${payload.server}/rest/documentos/nfse/cancelamento`, requestOptions, commit, dispatch) 
}

//#endregion

//#region Enviar Nota Fiscal de Serviço

function EnviarNfse(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Realizando envio de NFS-e.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/nfse/envio`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Enviar Consultar Serviço

function ConsultarNfse(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Realizando envio de NFS-e.`)
            return data;
        }]
    }

    if(payload.tipoXml === 1) _request(`${payload.server}/rest/documentos/nfse/consulta_rps/assincrona`, requestOptions, commit, dispatch) 
    if(payload.tipoXml === 2) _request(`${payload.server}/rest/documentos/nfse/consulta_rps_por_pendente/assincrona`, requestOptions, commit, dispatch) 
}
//#endregion

//#region Envio de Cancelamento de Nota

function cancelarNota(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    let urlEnvio = payload.modelo === '65' ? 'nfce' : 'nfe'
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Enviando cancelamento de ${urlEnvio.toUpperCase()}.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${urlEnvio}/cancelamento`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

//#endregion

//#region Envio de Carta Correcao

function cartaCorrecao(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi("Enviando carta de Correção.")
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/nfe/carta_correcao`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

//#endregion

//#region Consulta de evento
function consultaEvento(commit, dispatch, payload){
    commit("exibirOcultarModalLoading", true)

    let apiConsulta = payload.tipoEnvio === 'ASSINCRONA' ? 'consulta_evento_assincrona' : 'consulta_evento_sincrona'
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi("Enviado consulta de evento.")
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${apiConsulta}`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Consulta de nota por XML
function consultaXml(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    let urlEnvio = payload.tipoEnvio === 'SINCRONA'? 'consulta_nota_sincrona' : 'consulta_nota_assincrona'
    let tipoDoc = payload.tipoDoc === '55' ? 'NF-e' : 'NFC-e'
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Enviando consulta ${payload.tipoEnvio} de ${tipoDoc}.`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${urlEnvio}/xml`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion

//#region Consulta de nota por Chave de Acesso
function consultaChaveAcesso(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    let urlEnvio = payload.tipoEnvio === 'SINCRONA'? 'consulta_nota_sincrona' : 'consulta_nota_assincrona'
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.dados),
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Enviando consulta ${payload.tipoEnvio} de documento por chave de acesso`)
            return data;
        }]
    }
    
    return axios(`${payload.server}/rest/documentos/${urlEnvio}/chave_acesso`, requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}
//#endregion