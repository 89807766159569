import { dadosUfMunicipio } from '@/helpers/listaDadosUf'

export default class consultarEventoModel {
    constructor(objeto) {

        this.dados = {
            versao:'',
            chaveAcesso: '',
            serial: '',
            cpfCnpj: '',
            ambiente: '',
            uf:'',
            urlNotificacao: '',
        }
        this.listaUf = dadosUfMunicipio
        this.tipoEnvio = 'ASSINCRONO'

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}