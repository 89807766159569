export const parseDados = {
    carregarXml,
    carregarDadosDanfse
}

function carregarXml(conteudo) {
    
    return {

        chaveAcesso: conteudo.chaveAcesso(),
        xml: conteudo.xml()
        
    }
  }

function carregarDadosDanfse(conteudo) {

    return {
        xml : conteudo.xml(),
        chaveAcesso : conteudo.chaveAcesso()
    }
}

// function carregarXml(obj, conteudo) {
//     obj.dados.cpfCnpj = conteudo.emitente().cpfCnpj
//     obj.dadosUf.xUF = conteudo.emitente().xUF
//     obj.dados.versao = conteudo.versao()
//     obj.dados.modelo = conteudo.dadosIde().modelo
//     obj.dadosUf.cUF = conteudo.dadosIde().cUF
//     obj.dados.ambiente = conteudo.dadosIde().ambiente
//     obj.dados.xml = conteudo.xml()

//     return obj
// }