import {
    empresaServico
} from '../../services'

export const empresa = {

    actions: {
        cadastroEmpresa({commit, dispatch}, payload) {
            empresaServico.cadastroEmpresa(commit, dispatch, payload)
        },
        cadastroLogotipo({commit, dispatch}, payload) {
            empresaServico.cadastroLogotipo(commit, dispatch, payload)
        }
    }


}