<template>
  <div >
    <div class="column is-12 is-pulled-left has-margin-top-15 is-paddingless">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#empresas-logotipo-upload"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
      </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-12 is-pulled-left has-padding-bottom-5 has-padding-top-5">
        <span>* CPF / CNPJ:</span>
        <input id="frm-cad-logotipo-txt-cpfcnpj"
          ref="cpfCnpj"
          v-model="ultimoCpfCnpj"
          class="input " 
          @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
          @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
          maxlength="14"
          type="text">
      </div>
    </div>
    <div  class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-8 is-pulled-left has-padding-top-5"> 
        <span>* Selecione uma imagem para o logotipo</span>
        <input
          class="input is-small input-cursor"
          disabled
          type="text"
          v-model="nomeArquivo"
          title="Nome do arquivo selecionado.">
      </div>
      <div class="column is-4 is-pulled-left">
        <FileReader class="button button-secundario"
          id="frm-cad-logotipo-btn-file-reader"
          :Btndescricao='"Carregar Imagem"'
          @load="carregarArquivo($event)" />
      </div>
    </div>
    <div  class="column is-12 is-pulled-left">
      <div class="column is-12 containerLogotipo">
        <span v-if="nomeArquivo === '' && imagemExemplo === ''" class="is-pulled-left is-size-5 has-padding-30">Não foi selecionada uma imagem para o logotipo</span>
        <div v-else >
          <img class="logotipo" :src="`${imagemExemplo}`" alt="imagem da logo"/>
        </div>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="is-marginless has-margin-bottom-10">
      <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
      <div  class="column is-6 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="cadastroEmpresa($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'
  import FileReader from "@/components/importarImagem.vue"

  export default {
    components: {
      FileReader,
      SelecaoMenu
    },
    mixins:[mixin],
    data(){
      return {
        nomeArquivo: '',
        imagemExemplo:'',
        parametros: {
          cpfCnpj: '',
          arquivo: ''
        }
      }
    },
    methods: {
      validarBase64(str) {
        if((str.substring(0, 15) === 'data:image/jpeg') || (str.substring(0, 14) === 'data:image/png') || (str.substring(0, 14) === 'data:image/jpg')) {
          this.nomeArquivo = "Carregado arquivo base64."
          return false
        }
          return true
      },
      carregarArquivo(param) {
        this.parametros.arquivo = param.file;
        this.imagemExemplo = param.imagemExemplo
        this.nomeArquivo = param.name;
      },
      cadastroEmpresa(server) {
        this.$root.$emit('closeModal')
        this.parametros.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
        store.dispatch("cadastroLogotipo", {
          ...this.parametros,
           server: server,
        })
      }
    }
  }
</script>

<style scoped>

  .input-cursor {
    cursor: context-menu !important;
  }

  #frm-cad-logotipo-btn-file-reader {
    width: 100%;
    margin-top: 17px;
  }

  .logotipo {
    max-width: 380px;
    max-height: 125px;
  }
  
  .containerLogotipo {
    margin-top: -12px;
    background-color: #fff;
    border-radius: .25rem;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
    float: left;
    align-content: center;
    text-align: center;
    height: 150px;
  }

</style>