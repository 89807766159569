import axios from 'axios'
import {mensagemLog} from '@/utils/gerarMensagemLog'

export const utilServico = {
    consultaUteis
}

function consultaUteis(commit, dispatch, payload){
    payload.modelo === 'uteis' ?
        _consultarDataHoraUf(commit, dispatch, payload) :
        _consultarVersao(commit, dispatch, payload)
}

function _consultarVersao(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        params: {
            uf: payload.uf
        },
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Consultar versão de ${payload.modelo} da UF ${payload.uf}.`)
            return data;
        }]
    }

    return axios(`${payload.server}/rest/util/versao/${payload.modelo.toLowerCase()}`,requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function _consultarDataHoraUf(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi(`Consultar hora da UF ${payload.uf}.`)
            return data;
        }]
    }

    return axios(`${payload.server}/rest/util/uf/${payload.uf}`,requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}