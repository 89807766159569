<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-danfse-por-xml-de-nfs-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-5 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-7 select is-pulled-left has-padding-zero">
                <span>* Situação:</span>
                <select v-model="params.dados.situacao"
                    class="input">   
                    <option v-for="(situacao, i) in situacoes" 
                            :key="i"
                            :value="situacao.codigo">
                            {{situacao.descricao}}
                    </option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-4  has-padding-zero" >
                <span>Código Município:</span>
                <input v-model="params.dados.codigoMunicipio"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-4 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select class="input"
                    v-model="params.dados.ambiente">
                    <option v-for="(ambiente, i) in ambientes"
                        :key="i"
                        :value="ambiente.ambiente">
                        {{ambiente.descricao}}</option>
                </select>
            </div>
            <div class="column is-4 is-pulled-left has-padding-zero file-reader">
                <FileReader 
                    @load="carregarXml($event)"
                    :Btndescricao='"Carregar XML"'
                    class="button button-secundario is-full-width" />
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <div class="column is-12 has-padding-zero">
                <span >* Informe o XML ou carregue um XML clicando no botão <b>Carregar XML</b>:</span>
                <textarea v-model="params.dados.xml"
                    class="textarea"
                    rows="6"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="geracaoDanfseXml($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '@/mixins'
    import FileReader from "@/components/importarArquivoNovo.vue"
    import gerarDanfseXmlModel from '@/model/nfse/gerarDanfseXml'
    import { geraXML } from '@/helpers/gerarXML'
    import SelecaoMenu from '@/components/SelecionarServidor.vue'

    export default {
        mixins:[mixin],
        components: {
            FileReader,
            SelecaoMenu
        },
        props: {
            tipoDoc: String,
        },
         data(){
            return {
                params: new gerarDanfseXmlModel(),
                situacoes: [
                    {descricao: "Autorizada", codigo: "AUTORIZADA"},
                    {descricao: "Pendente", codigo: "PENDENTE"},
                    {descricao: "Cancelada", codigo: "CANCELADA"}
                ]
            }
        },
        methods: {
            geracaoDanfseXml(server) {
                this.$root.$emit('closeModal')
                this.params.dados.cpfCnpj = this.retirarFormatacao( this.params.dados.cpfCnpj )
                let payload = {
                    dados: { ...this.params.dados}, 
                    chaveAcesso: this.params.chaveAcesso,
                    componente: 'geracaoDanfseXml',
                    server: server
                }
                this.$store.dispatch( "geracaoDanfseXml" , payload )
                payload.dados.xml = ''
                this.$store.dispatch("ArmazenarDadosStore", payload)
            },
            carregarXml( param ) {
                let dadosXML = geraXML.carregarDadosDanfse( param.xml )
                this.params.dados.xml = dadosXML.xml
                this.params.chaveAcesso = dadosXML.chaveAcesso
            },
            preencherDadoComLocalStorage() {
                const localStorageDados = JSON.parse(localStorage.getItem(`geracaoDanfseXml`))
                if (localStorageDados) {
                    this.params = new gerarDanfseXmlModel(localStorageDados)
                }
            }
        },
        created() {
            this.preencherDadoComLocalStorage()
        }
    }
</script>


<style scoped>

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>