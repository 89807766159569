<template>
  <div >
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#certificados-certificado-upload"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
         </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-8 is-pulled-left has-padding-zero">
        <span>* CPF / CNPJ:</span>
        <input
          ref="cpfCnpj"
          v-model="ultimoCpfCnpj"
          class="input " 
          @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
          @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
          maxlength="14"
          type="text">
      </div>
      <div class="column campoSenha is-pulled-left has-padding-zero">
        <span>* Senha:</span>
        <input
          v-model="parametros.senha"
          class="input senha"
          :type="tipo">
        <p class="verSenha" type="password" @mousedown="tipo = 'text'" @mouseup="tipo = 'password'"><img src="@/assets/eye-2-48.png"></p>
      </div>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-12 is-pulled-left has-padding-zero">
        <span>* Clique para selecinar o certificado:</span>
        <label id="certificado" class="button column is-12 is-pulled-left">
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            accept=".crt,.pfx"
            @change="handleFileUpload"/><strong>Certificado: </strong> {{certificadoNome}}
        </label>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="has-margin-bottom-10 has-margin-top-10">
      <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
      <div class="column is-5 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="subirCertificado($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'

  export default {
    components: {
      SelecaoMenu
    },
    mixins:[mixin],
    data(){
      return {
        tipo: 'password',
        certificadoNome:'',
        retornoServico: '',
        parametros: {
          file: '',
          senha: '',
          cpfCnpj: ''
        }
      }
    },
    methods: {
      
      handleFileUpload(event){

        this.$refs.fileInput.click()
        this.parametros.file = event.target.files[0]
        const certificado = event.target.files
        if(certificado[0] !=undefined){
          if((certificado[0].name).length > 39){
            this.certificadoNome = certificado[0].name.substr(0,27) + ' ... ' + certificado[0].name.substr(-6)
          }else{
            this.certificadoNome = certificado[0].name
          }
        }
      },
      subirCertificado(server) {
        this.$root.$emit('closeModal')
        this.parametros.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
        store.dispatch("subirCertificado", {
          ...this.parametros,
          server: server
        })
      }
    }
  }
</script>

<style scoped>
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  #certificado {
    text-align: left !important;
    padding: 7px;
  }

  .campoSenha {
    width: 160px;
  }

  .senha {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    float: left;
    width: 106px;

  }

  .verSenha {
    float: right;
    border: 1px solid grey;
    border-color: #dbdbdb;
    width: 30px;
    height: 32px;
    padding: 7px 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .verSenha:hover {
    background-color: rgb(236, 234, 234);
    border: 1px solid rgba(160, 160, 160, 0.897);
  }

  .verSenha:active {
    background-color: rgba(146, 142, 142, 0.774);
    border: 1px solid grey;
  }

  img {
    height: auto;
    max-width: 80% !important;
  }

</style>