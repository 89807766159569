import {
    configuracoesServico
} from '../../services'

export const configuracoes = {
    state: {
        parametros: {
            tipoContingencia:''
        },
        configuracoesNfse: {
            listaProvedoresObtidos: [],
            aglutinar: ''
        },
        configuracoesDanfeNfce:{
            codigoItemPreferenciaEan: "",
            limitarItemPorLinha: "",
            tipoImpressaoDescontoItem: "",
            layoutImpressao: "",
            tipoNomeEmitente: ""
        },
        configuracoesDanfeNfe:{
            posicaoLogotipoEmpresa: "",
            ocultarLogomarcaAlterdata: "",
            imprimirPagamento: "",
            exibirObservacaoContribuinte: "",
            tipoImpressaoDescontoItem: "",
            tipoDescontoItem: "",
            limitarItemPorLinha: "",
            imprimirValorStItem: "",
            numeroCasasDecimaisValorUnitarioProduto: "",
            ocultarCamposTributaveisProduto: "",
            tipoNomeEmitente: ""
        }
    },
    mutations: {
        configuracoesDanfeNfce(state,payload) {
            state.configuracoesDanfeNfce = payload
        },
        configuracoesDanfeNfe(state,payload) {
            state.configuracoesDanfeNfe = payload
        },
        salvarConfiguracoesNfe(state,payload) {
            state.parametros = payload
        },
        salvarConfiguracoesNfse(state,payload) {
            state.configuracoesNfse = payload
        },
        salvarProvedoresObtidos(state,payload) {
            state.configuracoesNfse.listaProvedoresObtidos = payload
        }
    },
    actions: {
        removerConfiguracoes({ commit, dispatch }, payload) {
            configuracoesServico.removerConfiguracoes(commit, dispatch, payload)
        },
        Configuracoes({ commit, dispatch }, payload) {
            configuracoesServico.configuracoes(commit, dispatch, payload)
        },
        ConfiguracoesEmail({ commit, dispatch }, payload) {
            configuracoesServico.ConfiguracoesEmail(commit, dispatch, payload)
        },
        danfeNfce({ commit, dispatch }, payload) {
            configuracoesServico.danfeNfce(commit, dispatch, payload)
        },
        danfeNfe({ commit, dispatch }, payload) {
            configuracoesServico.danfeNfe(commit, dispatch, payload)
        },
        salvarProvedor({ commit, dispatch }, payload) {
            configuracoesServico.salvarProvedor(commit, dispatch, payload)
        },
        gravarMunicipioProvedor({ commit, dispatch }, payload) {
            configuracoesServico.gravarMunicipioProvedor(commit, dispatch, payload)
        },
        retornarTiposEnvio({ commit, dispatch }, payload) {
            configuracoesServico.retornarTiposEnvio(commit, dispatch, payload)
        },
        ObterProvedores({ commit, dispatch }, payload) {
            configuracoesServico.ObterProvedores(commit, dispatch, payload)
        }
    }
}