export default class GravarMunicipioProvedor {
    constructor(objeto) {

        this.dados = {

            idProvedor: "",
            idTipoEnvio: "",
            codigoMunicipio: "",
            ambiente: "",
            uri: ""
            
        }
        this.componente = 'GravarMunicipioProvedor'

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}