<template>
  <div id="formBuscarListaDocumentos">
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-consulta-de-documentos-por-lista-de-id"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
      </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-9 is-pulled-left has-padding-zero">
        <span>* Id Documento:</span>
        <input 
          id="frm-busc-list-doc-txt-idDocumento" 
          v-model="idDocumento"
          class="input" 
          type="numer">
      </div>
      <div class="column is-3 is-pulled-left has-padding-zero">
        <button
          @click="adicionarItens(idDocumento)"
          class="btnAdicionarItens button button-secundario has-margin-top-24 is-full-width">
          Adicionar
        </button>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero has-margin-top-5">
      <div class="tblClasse-lista">
        <table class="table is-striped is-narrow is-hoverable">
          <thead>
            <tr>
              <th>IdDocumento</th>
              <th>excluir</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(Documento, i) in listaIdDocumentos" :key="i" >
              <td>{{ Documento }}</td>
              <td>
                <button class="btnRemoverItem" 
                  @click="removerIdDocumento(i)"><i class="fas fa-trash-alt fa-lg"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="has-margin-top-10 has-margin-bottom-10">
      <span class="pulled-left is-size-8">* Campos obrigatórios.</span>
      <div class="column is-5 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="buscarListaDocumentos($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'

  export default {
    mixins:[mixin],
    components: {
      SelecaoMenu
    },
    data(){
      return {
        listaIdDocumentos: [],
        idDocumento: ""
      }
    },
    methods: {
      adicionarItens(parametro){
        if(this.verificarItenVazioLista(parametro) === undefined){
          this.listaIdDocumentos.push(parametro)
          this.idDocumento = ""
        }
      },
      verificarItenVazioLista(parametro){
          if (parametro === '') {
            return this.listaIdDocumentos.find(item => item === "" );
          }
      },
      removerIdDocumento(index){
        this.listaIdDocumentos.splice(index,1)
      },
      buscarListaDocumentos(server) {
        this.$root.$emit('closeModal')
        this.$store.dispatch("buscarListaDocumentos",{
          dados: this.listaIdDocumentos,
          server: server
        })
      }
    }
  }
</script>