export default class enviarNota {
    constructor(objeto) {

        this.dados = {
            xml : "",
            cpfCnpj : "",
            origem : "",
            serial : "",
            ambiente : "",
            tipoEnvio : "",
            codigoMunicipio : "",
            urlNotificacao : ""
        }
        this.numeroNfse = ""
        this.componente = 'CancelarNfse'

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}