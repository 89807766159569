<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a v-if="tipoDoc === '55'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-danfe-por-chave-de-acesso-de-nf-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
                <a v-if="tipoDoc === '65'"
                    href="https://dfe-dev.alterdata.com.br/docs/#documento-auxiliar-emissão-de-danfe-por-chave-de-acesso-de-nfc-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-5 select is-pulled-left has-padding-zero">
                <span>* Situação:</span>
                <select 
                    id="frm-ger-danfe-chave-txt-situacao"
                    v-model="params.dados.situacao"
                    class="input">   
                    <option v-for="atributo in atributos" 
                            :key="atributo.codigo"
                            :value="atributo.codigo">
                            {{atributo.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select 
                    id="frm-ger-danfe-chave-txt-ambiente"
                    v-model="params.dados.ambiente"
                    class="input">   
                    <option v-for="ambiente in ambientes" 
                            :key="ambiente.descricao"
                            :value="ambiente.ambiente"
                            :selected="ambiente" >
                            {{ambiente.descricao}}
                    </option>
                </select>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-3 has-padding-zero" >
                <span>Fonte:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-fonte"
                    v-model="params.tributosNota.fonte"
                    class="input" 
                    type="text">
            </div>
            <div class="column is-3 is-pulled-left has-padding-zero">
                <span>Federais:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-federal"
                    v-model.number="params.tributosNota.totalFederal"
                    class="input" 
                    type="number">
            </div>
            <div class="column is-3 is-pulled-left has-padding-zero">
                <span>Municipais:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-municipal"
                    v-model.number="params.tributosNota.totalMunicipal"
                    class="input" 
                    type="number">
            </div>
            <div class="column is-3 is-pulled-left has-padding-zero">
                <span>Estaduais:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-estadual"
                    v-model.number="params.tributosNota.totalEstadual"
                    class="input" 
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-12 has-padding-zero" >
                <span>* Chave de acesso:</span>
                <input 
                    id="frm-ger-danfe-chave-txt-chaveAcesso"
                    v-model="params.dados.chaveAcesso"
                    class="input" 
                    type="text">
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="geracaoDanfeChave($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '@/mixins'
    import gerarDanfeChaveModel from '@/model/compartilhado/gerarDanfeChave'
    import SelecaoMenu from '@/components/SelecionarServidor'

    export default {
        mixins:[mixin],
        components: {
            SelecaoMenu
        },
        props: {
            tipoDoc: String
        },
        data(){
            return {
               params: new gerarDanfeChaveModel()
            }
        },
        methods: {
            geracaoDanfeChave(server) {
                this.$root.$emit('closeModal')
                this.params.dados.cpfCnpj = this.retirarFormatacao( this.params.dados.cpfCnpj )
                let payload = {
                    dados: { ...this.params.dados}, 
                    tipoDoc: this.tipoDoc,
                    server: server
                }

                let paramsStore = {
                    componente: 'gerarDanfeChave' + this.tipoDoc,
                     dados: { ...this.params.dados}
                }
                payload.dados.tributosNota = { ...this.params.tributosNota }
                paramsStore.dados.tributosNota = { ...this.params.tributosNota }

                Object.keys( payload.dados.tributosNota ).forEach( key => payload.dados.tributosNota[key] === "" ? delete payload.dados.tributosNota[key] : {} )
                this.$store.dispatch("ArmazenarDadosStore", paramsStore)
                this.$store.dispatch("geracaoDanfeChave" , payload)
            },

            lerDadosLocalStorage() {
                const localStorageDados = JSON.parse(localStorage.getItem(`gerarDanfeChave${this.tipoDoc}`))
                if (localStorageDados) {
                    this.params = new gerarDanfeChaveModel(localStorageDados)
                    this.params.tributosNota = localStorageDados.dados.tributosNota ? localStorageDados.dados.tributosNota : {}
                }
                this.ambiente = 2
            }
        },
        created() {
            this.lerDadosLocalStorage()
        }
    }
</script>