<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless is-marginless"
            v-show="!progressBar">
            <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>* Qt. Envios:</span>
                    <input v-model="quantidadeEnvios"
                        class="input" 
                        type="number">
                </div>
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>* Nº Nota:</span>
                    <input v-model="params.numero"
                        class="input" 
                        type="number">
                </div>
                <div class="column is-3 is-pulled-left has-padding-zero">
                    <span>* Série:</span>
                    <input v-model="params.serie"
                        class="input" 
                        type="number">
                </div>
                <div class="column is-3 select is-pulled-left has-padding-zero">
                    <span>* Tipo:</span>
                    <select v-model="tipoDoc" 
                    class="input">   
                    <option v-for="(tipo,i) in tipos"
                        :key="i"
                        :value="tipo.modelo">
                        {{tipo.descricao}}</option>
                    </select>
                </div>
            </div>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="is-pulled-left column is-12 has-padding-zero" >
                    <span>* Serial:</span>
                    <input 
                        v-model="params.dados.serial"
                        class="input" 
                        type="text">
                </div>
            </div>
            <div class="column is-12 is-pulled-leftis-paddingless">
                <div class="column is-12 is-pulled-left is-paddingless has-padding-bottom-5">
                    <span>Url de Notificação(webhook):</span>
                    <input v-model="params.dados.urlNotificacao"
                        class="input" 
                        type="text">
                </div>
            </div>
            <div class="column is-12 is-pulled-left">
                <hr class="has-margin-bottom-10 has-margin-top-10">
                <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
                <div class="column is-5 is-pulled-right is-paddingless">
                    <SelecaoMenu 
                        @serverSelecionado="EnviarNotas($event, quantidadeEnvios)"
                        class="is-pulled-right"/>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15"
            v-show="progressBar">
            <div class="column is-12 is-pulled-left">
                <span class="is-size-4">Realizando envio dos documentos.</span>
            </div>
            <div class="column is-12 is-pulled-left">
                <span><b>Enviando documento: </b>{{params.numero}}</span>
            </div>
            <div class="column is-12 is-pulled-left">
                <span><b>Progresso do envio:</b> Enviando {{envioAtual}} de {{ quantidadeEnvios }}</span>
                <progress class="progress is-large is-primary" :value="progressBar" max="100" />
            </div>
            <div class="column is-12 is-pulled-left">
                <button class="button is-pulled-right has-width-100" 
                    v-if="this.progressBar === 100"
                    @click="voltar">voltar</button>
                <button class="button is-pulled-right has-width-100"
                    v-else
                    @click="cancelarEnvio">Cancelar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { geraXML } from '@/helpers/gerarXML.js'

export default {
    data() {
        return{
            progressBar: '',
            params: {
                dados: {
                    cpfCnpj: '36462778000160',
                    serial: '',
                    xml: '',
                    uf:'RJ',
                    versao:'4.00',
                    urlNotificacao: '',
                    origem: '8'
                },
                dadosUf: {
                    xUF: 'RJ',
                    cUF: '33'
                },
                municipio: {
                    cMun: '3305802',
                    xMunXml: 'TERESOPOLIS'
                },
                numero: '',
                serie: '',
                tpEmis:'1',
            },
            quantidadeEnvios: '',
            envioAtual: '',
            tipoDoc: '',
            tipos: [
                {descricao: 'NF-e', modelo: '55'},
                {descricao: 'NFC-e', modelo: '65'}
            ]
        }
    },
    methods: {
        gerarDoc(tipoDoc, params, cpfCnpj) {
            return geraXML.gerarNota(tipoDoc, params, cpfCnpj)
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        cancelarEnvio(){
            this.progressBar = ''
            this.eventoCancelado = true
            this.params.numero = parseInt(this.params.numero) + 1
        },
        voltar(){
            this.progressBar = ''
            this.eventoCancelado = false
            this.params.numero = this.params.numero + 1
        },
        armazenarDados() {
            this.$store.dispatch("ArmazenarDadosStore", {
                params: {
                    dados: {
                        serial: this.params.dados.serial,
                        urlNotificacao: this.params.dados.urlNotificacao
                    },
                    numero: this.params.numero,
                    serie: this.params.serie
                },
                quantidadeEnvios: this.quantidadeEnvios,
                tipoDoc: this.tipoDoc,
                componente: 'EnviarVariasNotas'
            })
        },
        async EnviarNotas(server, qtEnvios){

            for (let i = 0; i < qtEnvios; i++) {
                if(this.eventoCancelado){
                    this.eventoCancelado = false
                    break
                }
                this.envioAtual = i + 1
                this.progressBar = (this.envioAtual / qtEnvios) * 100
                i === 0 ? this.params.numero : this.params.numero = parseInt(this.params.numero) + 1

                this.params.dados.xml = this.gerarDoc(this.tipoDoc, this.params, this.params.dados.cpfCnpj)
               
                this.armazenarDados()

                this.$store.dispatch("EnviarVariasNotas" , {
                    dados: this.params.dados,
                    modelo: this.tipoDoc,
                    server: server,
                    progresso: i
                })
                await this.sleep(200)
            }
        }
    },
    created() {
        const localStorageDados = JSON.parse(localStorage.getItem(`EnviarVariasNotas`))
        if (localStorageDados) {
            this.params.dados.serial = localStorageDados.params.dados.serial
            this.params.dados.urlNotificacao = localStorageDados.params.dados.urlNotificacao
            this.params.numero = localStorageDados.params.numero + 1
            this.params.serie = localStorageDados.params.serie
            this.quantidadeEnvios = localStorageDados.quantidadeEnvios
            this.tipoDoc = localStorageDados.tipoDoc
        }
    }
}
</script>

<style>

</style>