import {
    utilServico
} from '../../services'

export const util = {
    actions: {
        consultaUteis({commit, dispatch}, payload) {
            utilServico.consultaUteis(commit, dispatch, payload)
        }
    }
}
