<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :style="styleObject">
                    <div class="modal-header">
                        <CabecalhoModal 
                        :titulo="titulo"
                        :fecharModal="emitirEvento"/>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                         default body
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CabecalhoModal from '@/components/CabecalhoModal.vue'

export default {
    components: {
        CabecalhoModal
    },
    props: {
        largura: String,
        altura: String,
        titulo: String

    },
    data() {
        
        return {
            styleObject: {
                width: this.largura,
                height: this.altura
            }
        }
    },
    methods: {
        emitirEvento(event)   {
            this.$root.$emit(event)
        },
        eventosCreated() {
            this.$store.commit('exibirOcultarLog', true)
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.emitirEvento('closeModal')
                }
            })
        }
    },
    mounted(){
        this.emitirEvento('evtOcultarMenu')
        this.eventosCreated()
    }
}
</script>

<style lang="scss" scoped>
    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }

    .modal-mask {
        position: fixed;
        z-index: 40;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        padding: 10px;
        margin-top: -30px;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>