<template>
    <div>
        <modal  
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" 
                :is="componente" 
                tipoDoc="55"
                :moduloSelecionado="componente"/>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import EnviarNota from '@/views/compartilhado/FormEnviarNota.vue'
import CancelarNota from '@/views/compartilhado/FormcancelarNota.vue'
import Inutilizacao from '@/views/compartilhado/FormInutilizacao.vue'
import ConsultarNota from '@/views/compartilhado/FormConsultarNota.vue'
import GerarDanfeXml from '@/views/compartilhado/FormGerarDanfeXml.vue'
import GerarDanfeChave from '@/views/compartilhado/FormGerarDanfeChave.vue'
import StatusServico from '@/views/compartilhado/FormStatusServico.vue'
import StatusServicoSvc from '@/views/compartilhado/FormStatusServicoSvc.vue'
import ObterUrlStatus from '@/views/compartilhado/FormObterUrlStatus.vue'
import ObterUrlStatusSvc from '@/views/compartilhado/FormObterUrlStatusSvc.vue'
import ConsultarEvento from '@/views/documentos/FormConsultarEvento.vue'
import ConfiguracoesNfe from '@/views/configuracoes/FormConfiguracoesNfe.vue'
import ConfiguracoesDanfe from '@/views/configuracoes/FormConfiguracoesDanfe.vue'
import RemoverConfiguracoesNfeEmissao from '@/views/configuracoes/FormRemoverConfiguracoes.vue'
import RemoverConfiguracoesNfeDanfe from '@/views/configuracoes/FormRemoverConfiguracoes.vue'
import CartaCorrecao from '@/views/nfe/FormCartaCorrecao.vue'
import GerarImpressaoCce from '@/views/nfe/FormGerarImpressaoCce.vue'
import ConsultarVersao from '@/views/utilidades/FormConsultarVersao.vue'

export default {
    props: {
        modulo: String
    },
    components: {
        Modal,
        EnviarNota,
        CancelarNota,
        CartaCorrecao,
        Inutilizacao,
        ConsultarNota,
        ConsultarEvento,
        ConfiguracoesNfe,
        ConfiguracoesDanfe,
        GerarDanfeXml,
        GerarDanfeChave,
        StatusServico,
        RemoverConfiguracoesNfeEmissao,
        RemoverConfiguracoesNfeDanfe,
        StatusServicoSvc,
        ObterUrlStatus,
        ObterUrlStatusSvc,
        GerarImpressaoCce,
        ConsultarVersao
    },
    data() {
        return {
            altura: '',
            largura: '',
            titulo:'',
            showModal: '',
            componente: ''
        }
    },
    methods: {
        abrirModal(opt) {
            switch (opt) {
                case 'EnviarNota':
                    this.componente = 'EnviarNota'
                    this.altura = '549px'
                    this.largura = '920px'
                    this.titulo = 'Enviar NFe'
                    break
                case 'CancelarNota':
                    this.componente = 'CancelarNota'
                    this.altura = '485px'
                    this.largura = '850px'
                    this.titulo = 'Cancelar NF-e'
                    break
                case 'CartaCorrecao':
                    this.componente = 'CartaCorrecao'
                    this.altura = '485px'
                    this.largura = '850px'
                    this.titulo = 'Carta de Correção'
                    break
                case 'ConsultarVersao':
                    this.componente = 'ConsultarVersao'
                    this.altura = '200px'
                    this.largura = '270px'
                    this.titulo = 'Consultar Versão NF-e'
                    break
                case 'Inutilizacao':
                    this.componente = 'Inutilizacao'
                    this.altura = '542px'
                    this.largura = '700px'
                    this.titulo = 'Inutilizar NF-e'
                    break
                case 'ConsultarNota':
                    this.componente = 'ConsultarNota'
                    this.altura = '500px'
                    this.largura = '680px'
                    this.titulo = 'Consultar Nota'
                    break
                case 'ConsultarEvento':
                    this.componente = 'ConsultarEvento'
                    this.altura = '350px'
                    this.largura = '600px'
                    this.titulo = 'Consultar Evento NF-e'
                    break
                case 'GerarDanfeXml':
                    this.componente = 'GerarDanfeXml'
                    this.altura = '412px'
                    this.largura = '600px'
                    this.titulo = 'Gerar DANFE por XML'
                    break
                case 'GerarDanfeChave':
                    this.componente = 'GerarDanfeChave'
                    this.altura = '312px'
                    this.largura = '600px'
                    this.titulo = 'Gerar DANFE por Chave de Acesso'
                    break
                case 'ConfiguracoesNfe':
                    this.componente = 'ConfiguracoesNfe'
                    this.altura = '312px'
                    this.largura = '330px'
                    this.titulo = 'Configurar NFe'
                    break
                case 'ConfiguracoesDanfe':
                    this.componente = 'ConfiguracoesDanfe'
                    this.altura = '345px'
                    this.largura = '700px'
                    this.titulo = 'Configurar DANFE'
                    break
                case 'StatusServico':
                    this.componente = 'StatusServico'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Status Serviço'
                    break
                case 'StatusServicoSvc':
                    this.componente = 'StatusServicoSvc'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Status Serviço SVC'
                    break
                case 'ObterUrlStatus':
                    this.componente = 'ObterUrlStatus'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Obter URL de Status'
                    break
                case 'ObterUrlStatusSvc':
                    this.componente = 'ObterUrlStatusSvc'
                    this.altura = '255px'
                    this.largura = '530px'
                    this.titulo = 'Obter URL de Status SVC'
                    break
                case 'RemoverConfiguracoesNfeEmissao':
                    this.componente = 'RemoverConfiguracoesNfeEmissao'
                    this.altura = '201px'
                    this.largura = '380px'
                    this.titulo = 'Remover Configurações Emissão'
                    break
                case 'RemoverConfiguracoesNfeDanfe':
                    this.componente = 'RemoverConfiguracoesNfeDanfe'
                    this.altura = '196px'
                    this.largura = '380px'
                    this.titulo = 'Remover Configurações Danfe'
                    break
                case 'GerarImpressaoCce':
                    this.componente = 'GerarImpressaoCce'
                    this.altura = '435px'
                    this.largura = '1000px'
                    this.titulo = 'Gerar Impressão de CC-e'
                    break
            }
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>

<style>


</style>