export const dadosUfMunicipio = [
    {
        "cMun": 2500205,
        "xMun": "Aguiar",
        "xMunXml": "AGUIAR",
        "cUF": 25,
        "xUF":"PB"
    },
    {
        "cMun": 2900108,
        "xMun": "Abaíra",
        "xMunXml": "ABAIRA",
        "cUF": 29,
        "xUF":"BA"
    },
    {
        "cMun": 1100015,
        "xMun": "Alta Floresta D'Oeste",
        "xMunXml": "ALTA FLORESTA D OESTE",
        "cUF": 11,
        "xUF":"RO"
    },
    {
        "cMun": 1200807,
        "xMun": "Porto Acre",
        "xMunXml": "PORTO ACRE",
        "cUF": 12,
        "xUF":"AC"
    },
    {
        "cMun": 1400027,
        "xMun": "Amajari",
        "xMunXml": "AMAJARI",
        "cUF": 14,
        "xUF":"RR"
    },
    {
        "cMun": 1500107,
        "xMun": "Abaetetuba",
        "xMunXml": "ABAETETUBA",
        "cUF": 15,
        "xUF":"PA"
    },
    {
        "cMun": 1600055,
        "xMun": "Serra do Navio",
        "xMunXml": "SERRA DO NAVIO",
        "cUF": 16,
        "xUF":"AP"
    },
    {
        "cMun": 1700251,
        "xMun": "Abreulândia",
        "xMunXml": "ABREULANDIA",
        "cUF": 17,
        "xUF":"TO"
    },
    {
        "cMun": 2100055,
        "xMun": "Açailândia",
        "xMunXml": "ACAILANDIA",
        "cUF": 21,
        "xUF":"MA"
    },
    {
        "cMun": 2200053,
        "xMun": "Acauã",
        "xMunXml": "ACAUA",
        "cUF": 22,
        "xUF":"PI"
    },
    {
        "cMun": 2300101,
        "xMun": "Abaiara",
        "xMunXml": "ABAIARA",
        "cUF": 23,
        "xUF":"CE"
    },
    {
        "cMun": 2408102,
        "xMun": "Natal",
        "xMunXml": "NATAL",
        "cUF": 24,
        "xUF":"RN"
    },
    {
        "cMun": 2600054,
        "xMun": "Abreu e Lima",
        "xMunXml": "ABREU E LIMA",
        "cUF": 26,
        "xUF":"PE"
    },
    {
        "cMun": 2700102,
        "xMun": "Água Branca",
        "xMunXml": "AGUA BRANCA",
        "cUF": 27,
        "xUF":"AL"
    },
    {
        "cMun": 2800100,
        "xMun": "Amparo de São Francisco",
        "xMunXml": "AMPARO DE SAO FRANCISCO",
        "cUF": 28,
        "xUF":"SE"
    },
    {
        "cMun": 3100104,
        "xMun": "Abadia dos Dourados",
        "xMunXml": "ABADIA DOS DOURADOS",
        "cUF": 31,
        "xUF":"MG"
    },
    {
        "cMun": 3200102,
        "xMun": "Afonso Cláudio",
        "xMunXml": "AFONSO CLAUDIO",
        "cUF": 32,
        "xUF":"ES"
    },
    {
        "cMun": 3305802,
        "xMun": "Teresópolis",
        "xMunXml": "TERESOPOLIS",
        "cUF": 33,
        "xUF":"RJ"
    },
    {
        "cMun": 3500105,
        "xMun": "Adamantina",
        "xMunXml": "ADAMANTINA",
        "cUF": 35,
        "xUF":"SP"
    },
    {
        "cMun": 4100103,
        "xMun": "Abatiá",
        "xMunXml": "ABATIA",
        "cUF": 41,
        "xUF":"PR"
    },
    {
        "cMun": 4200051,
        "xMun": "Abdon Batista",
        "xMunXml": "ABDON BATISTA",
        "cUF": 42,
        "xUF":"SC"
    },
    {
        "cMun": 4300034,
        "xMun": "Aceguá",
        "xMunXml": "ACEGUA",
        "cUF": 43,
        "xUF":"RS"
    },
    {
        "cMun": 5000203,
        "xMun": "Água Clara",
        "xMunXml": "AGUA CLARA",
        "cUF": 50,
        "xUF":"MS"
    },
    {
        "cMun": 5100102,
        "xMun": "Acorizal",
        "xMunXml": "ACORIZAL",
        "cUF": 51,
        "xUF":"MT"
    },
    {
        "cMun": 5200050,
        "xMun": "Abadia de Goiás",
        "xMunXml": "ABADIA DE GOIAS",
        "cUF": 52,
        "xUF":"GO"
    },
    {
        "cMun": 5300108,
        "xMun": "Brasília",
        "xMunXml": "BRASILIA",
        "cUF": 53,
        "xUF":"DF"
    }
]