<template>
    <div id="frmConsultaRps">
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span  v-if="tipoXml === 2" class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-consulta-de-nfs-e-assíncrona-por-rps-por-xml-pendente"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
            <span v-if="tipoXml === 1" class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-consulta-de-nfs-e-assíncrona-por-rps-post"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input  id="frm-consulta-rps-txt-cpfcnpj"
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-6 has-padding-zero" >
                <span>* Serial:</span>
                <input
                    id="frm-consulta-rps-txt-serial"
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input id="frm-consulta-rps-txt-origem"
                    v-model.number="params.dados.origem"
                    class="input"
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-3 has-padding-zero">
                <span>* Código município:</span>
                <input id="frm-consulta-rps-txt-codigo-municipio"
                    v-model="params.dados.codigoMunicipio"
                    class="input"
                    type="text">
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select id="frm-consulta-rps-txt-ambiente"
                    v-model="params.dados.ambiente" 
                    class="input">   
                    <option v-for="(ambiente,i) in ambientes"
                        :key="i" 
                        :value="ambiente.ambiente">
                        {{ambiente.descricao}}
                    </option>
                </select>
            </div>
            <div class="column is-6 is-pulled-left has-padding-zero">
                <span>Url de Notificação(webhook):</span>
                <input id="frm-consulta-rps-txt-urlNotificacao"
                    v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-marginless has-margin-top-10 is-pulled-left is-full-width">
        
        <div class="column is-12 is-pulled-left is-paddingless">
             <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo XML:</span>
                <select class="input"
                    id="frm-consulta-rps-select-tipo-importacao"
                    v-model="tipoXml"
                    @change="filtrarItems(tipoXml)">
                    <option v-for="(tipo, i) in tiposXml"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
             <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão:</span>
                <select class="input"
                    id="frm-consulta-rps-select-tipo-importacao"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()"
                    title="Selecione o Tipo XML para exibir as opções de tipo de inclusão">
                    <option v-for="(tipo, i) in listaTipos"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-8 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2 || ImportacaoXML === 4">
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"'
                        @load="carregarXml($event, tipoXml)" />
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                        <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-8 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-12 is-pulled-left is-paddingless">
                    <div class="is-pulled-left column is-4 has-padding-zero">
                        <span>* Nº RPS:</span>
                        <input id="frm-consulta-rps-txt-rps"
                            v-model.number="params.numeroRps"
                            class="input"
                            type="number">
                    </div>
                    <div class="is-pulled-left column is-2 has-padding-zero">
                        <span>* Série:</span>
                        <input id="frm-consulta-rps-txt-serie"
                            v-model.number="params.serie"
                            title="Infome a quantidade de notas que será gerada no lote."
                            class="input"
                            type="number">
                    </div>
                    <div class="column is-3 is-pulled-right file-reader">
                        <button id="frm-consulta-rps-btn-gerar-nota"
                            class="button button-secundario is-full-width" 
                            @click="gerarNotas()">Gerar XML</button>
                    </div>
                </div>
            </div>
        </div>
       <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 || ImportacaoXML === 5 ||  !ImportacaoXML ? 'informe-xml-txt-area' :'']">
                <span v-text="ImportacaoXML === 3 || ImportacaoXML === 5 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea
                    :disabled="ImportacaoXML !== 3 || ImportacaoXML === 5"
                    :class="[ImportacaoXML !== 3 || ImportacaoXML === 5 ? 'cursor-context-menu' : 'cursor-text']"
                    id="frm-consulta-rps-txtarea-xml"
                    class="textarea"
                    rows="7"
                    v-model="params.dados.xml"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="EnviarConsulta($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
  </div>
</template>

<script>
    import store from '@/store'
    import mixin from '@/mixins.js'
    import FileReader from "@/components/importarArquivoNovo.vue"
    import consultaNfse from '@/model/nfse/consultarNfse.js'
    import { geraXML } from '@/helpers/gerarXML.js'
    import SelecaoMenu from '@/components/SelecionarServidor'

    export default {
        mixins:[mixin],
        components: {
            FileReader,
            SelecaoMenu
        },
        data(){
            return {
                params: new consultaNfse(),
                descricaoXml: '',
                ImportacaoXML: '',
                listaTipos: [],
                tipoXml: '',
                tiposXml: [
                    {descricao: "Consulta RPS", codigo: 1},
                    {descricao: "Pendente", codigo: 2}
                ],
                TiposInclusao: [
                    {tipoXml:1, descricao: "Gerar", codigo: 1},
                    {tipoXml:1, descricao: "Carregar", codigo: 2},
                    {tipoXml:1, descricao: "Informar", codigo: 3},
                    {tipoXml:2, descricao: "Carregar", codigo: 4},
                    {tipoXml:2, descricao: "Informar", codigo: 5}
                ]
                
            }
        },
        methods: {
            filtrarItems(query) {
                this.listaTipos =  this.TiposInclusao.filter(el => el.tipoXml ===  parseInt(query))
                this.ImportacaoXML = ''
            },
            importacaoXMLAlterado() {
                this.params.dados.xml = ''
                this.descricaoXml = ''
            },
            gerarNotas(){
                this.params.dados.xml = geraXML.gerarXmlConsultaNfseRps(this.params.numeroRps, this.params.serie)
            },
            carregarXml(param, tipoXml) {
                let dadosXML = geraXML.carregarXmlNfse(param.xml, tipoXml)
                this.params.dados.xml = dadosXML
                this.params.dados.xml === '' ? this.descricaoXml = '' : this.descricaoXml = param.nome
            },
            EnviarConsulta(server) {
                this.$root.$emit('closeModal')
                let paramsRequisicao = {
                    dados: this.params.dados,
                    server: server,
                    tipoXml: this.tipoXml
                }
                paramsRequisicao.dados.cpfCnpj = this.retirarFormatacao(this.params.dados.cpfCnpj)
                store.dispatch("consultarNfse" , paramsRequisicao)
                let dadosStore = this.params
                dadosStore.dados.xml = ''
                store.dispatch("ArmazenarDadosStore", dadosStore)
            },
            preencherDadoComLocalStorage() {
                const localStorageDados = JSON.parse(localStorage.getItem(`consultarNfse`))
                if (localStorageDados) {
                    this.params = new consultaNfse(localStorageDados)
                    this.params.componente = "consultarNfse"
                }   
                this.tipoXml = 1
                this.filtrarItems(1)
                this.ImportacaoXML = 1
            }
        },
        created() {
            this.preencherDadoComLocalStorage()
        }
    }
</script>

<style lang="scss" scoped>
    .tesssss{
        margin-top: 20px;
    }

    .informe-xml-txt-area {
        margin-top: 22px;
    }

    .tabela-opacity {
        opacity: 0.4;
    }

    .progress-bar {
        position: absolute;
        z-index: 3;
        margin: 35px;
        background-color: snow;
        box-shadow: 0 0 3px gray;
        padding: 20px;
        width: 685px;
        progress {
            width: 100%;
        }
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>