<template>
    <div>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <section>
                <aside id="MenuBotoes" class="menu">
                    <ul class="menu-list has-padding-left-15">
                        <li>
                        <!--#region Empresa  -->
                            <p class="menu-label">Empresa</p>
                            <ul class="menu-list">
                                <li id="ml-li-btn-cadastros">
                                    <a id="ml-btn-cadastros"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(5).codigo}"
                                    @click="exibirMenu(retornaEnum(5))"
                                    tabindex="0">Cadastros</a></li>
                                <ul class="menu-itens cadastros" :class="{'is-block': exibirOcultarMenu === retornaEnum(5).codigo}">
                                    <li id="ml-cad-btn-empresa" @click="chamarModulo('CadastroEmpresa')">Empresa</li>
                                    <li id="ml-cad-btn-logotipo" @click="chamarModulo('CadastroLogotipo')">Logotipo</li>
                                </ul>
                            </ul>
                        <!--#endregion -->
                        <!--#region Utilitários  -->
                            <p class="menu-label">Utilitários</p>
                            <ul class="menu-list">
                            <!--#region Certificados  -->
                                <li><a id="ml-btn-certificado"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(3).codigo}"
                                    @click="exibirMenu(retornaEnum(3))"
                                    tabindex="0">Certificados</a></li>
                                <ul class="menu-itens certificados" :class="{'is-block': exibirOcultarMenu === retornaEnum(3).codigo}">
                                    <li id="ml-cert-btn-subir-certificado" @click="chamarModulo('SubirCertificado')">Subir Certificado</li>
                                    <li id="ml-cert-btn-info-certificado-local" @click="chamarModulo('CertificadoInfo')">Info. Certificado local</li>
                                    <li id="ml-cert-btn-certificado-cadastrados" @click="chamarModulo('CertificadosCadastrados')">Certificado Cadastrados</li>
                                    <li id="ml-cert-btn-info-certificado-armazenado" @click="chamarModulo('CertificadoInfoArmazenado')">Info. Certificado Armazenado</li>
                                    <li id="ml-cert-btn-testar-certificado-armazenado" @click="chamarModulo('TestarCertificado')">Testar Certificado Armazenado</li>
                                    <li id="ml-cert-btn-remover-certificado" @click="chamarModulo('RemoverCertificado')">Remover Certificado</li>
                                    <li id="ml-cert-btn-certificados-vigentes" @click="chamarModulo('ObterCertificadosVigentes')">Certificados vigentes</li>
                                </ul>
                            <!--#endregion -->
                            <!--#region Outros  -->
                                <li><a id="ml-btn-outros"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(4).codigo}"
                                    @click="exibirMenu(retornaEnum(4))"
                                    tabindex="0">Outros</a></li>
                                <ul class="menu-itens outros" :class="{'is-block': exibirOcultarMenu === retornaEnum(4).codigo}">
                                    <li id="ml-outros-btn-assinar-documento" @click="chamarModulo('AssinarDocumento')">Assinar Documento</li>
                                    <li id="ml-outros-btn-validar-xml" @click="chamarModulo('ValidarXml')">Validar XML</li>
                                    <li id="ml-outros-btn-gerar-cacert" @click="chamarModulo('GerarCaCert')">Gerar Cacert</li>
                                    <li id="ml-outros-btn-mock-sefaz" @click="chamarModulo('MockSefaz')">Mock Sefaz</li>
                                </ul>
                            <!--#endregion -->
                            <!--#region Úteis  -->
                                <li><a id="ml-btn-uteis"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(7).codigo}"
                                    @click="exibirMenu(retornaEnum(7))"
                                    tabindex="0">Úteis</a></li>
                                <ul class="menu-itens uteis" :class="{'is-block': exibirOcultarMenu === retornaEnum(7).codigo}">
                                    <li id="ml-uteis-btn-buscar-lista-documentos" @click="chamarModulo('BuscarListaDocumentos')">Buscar Lista Documentos</li>
                                    <li id="ml-uteis-btn-buscar-documento-por-id" @click="chamarModulo('BuscarDocumentoId')">Buscar Documento por ID</li>
                                    <li id="ml-uteis-btn-consultar-data-hora-uf" @click="chamarModulo('DataHoraUf')">Consultar Data Hora Uf</li>
                                    <li id="ml-uteis-btn-enviar-varias-notas" @click="chamarModulo('EnviarVariasNotas')">Enviar Varias Notas</li>
                                </ul>
                            <!--#endregion -->
                            <!--#region E-mail  -->
                                <li><a id="ml-btn-email"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(8).codigo}"
                                    @click="exibirMenu(retornaEnum(8))"
                                    tabindex="0">E-mail</a></li>
                                <ul class="menu-itens email" :class="{'is-block': exibirOcultarMenu === retornaEnum(8).codigo}">
                                    <li id="ml-email-btn-configurar-email" @click="chamarModulo('Email')">Configurar E-mail</li>
                                    <li id="ml-email-btn-enviar-email-com-anexo" @click="chamarModulo('EnviarEmailAnexo')">Enviar E-mail com Anexo</li>
                                </ul>
                            <!--#endregion -->
                            </ul>
                        <!--#endregion -->
                        <!--#region Envios  -->
                            <p class="menu-label">Envios</p>
                            <ul class="menu-list">
                                <!--#region NF-e  -->
                                <li><a id="ml-btn-nfe"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(1).codigo}"
                                    @click="exibirMenu(retornaEnum(1))"
                                    tabindex="0">NF-e</a></li>
                                <ul class="menu-itens nfe" :class="{'is-block': exibirOcultarMenu === retornaEnum(1).codigo}">
                                    <li id="ml-nfe-btn-enviar" @click="chamarModulo('EnviarNota')">Enviar</li>
                                    <li id="ml-nfe-btn-cancelar" @click="chamarModulo('CancelarNota')">Cancelar</li>
                                    <li id="ml-nfe-btn-nfe-carta-correcao" @click="chamarModulo('CartaCorrecao')">Carta de Correção</li>
                                    <li id="ml-nfe-btn-inutilizacao" @click="chamarModulo('Inutilizacao')">Inutilizar</li>
                                    <li id="ml-nfe-btn-consultar-nota" @click="chamarModulo('ConsultarNota')">Consultar nota</li>
                                    <li id="ml-nfe-btn-consultar-evento" @click="chamarModulo('ConsultarEvento')">Consultar evento</li>
                                    <li id="ml-nfe-btn-status-servico" @click="chamarModulo('StatusServico')">Status serviço</li>
                                    <li id="ml-nfe-btn-status-servico-svc" @click="chamarModulo('StatusServicoSvc')">Status serviço SVC</li>
                                    <li id="ml-nfe-btn-obter-url-status" @click="chamarModulo('ObterUrlStatus')">Obter URL de Status</li>
                                    <li id="ml-nfe-btn-obter-url-status-svc" @click="chamarModulo('ObterUrlStatusSvc')">Obter URL de Status SVC</li>
                                    <li id="ml-nfe-btn-gerar-danfe-xml" @click="chamarModulo('GerarDanfeXml')">Gerar DANFE por XML</li>
                                    <li id="ml-nfe-btn-gerar-danfe-chave-acesso" @click="chamarModulo('GerarDanfeChave')">Gerar DANFE por Chave de Acesso</li>
                                    <li id="ml-nfe-btn-gerar-impressao-cce" @click="chamarModulo('GerarImpressaoCce')">Gerar impressão de CC-e</li>
                                    <li id="ml-nfe-btn-configurar-emissao" @click="chamarModulo('ConfiguracoesNfe')">Configurar Emissão</li>
                                    <li id="ml-nfe-btn-configurar-danfe" @click="chamarModulo('ConfiguracoesDanfe')">Configurar DANFE</li>
                                    <li id="ml-nfe-btn-remover-configuracoes-emissao" @click="chamarModulo('RemoverConfiguracoesNfeEmissao')">Remover configurações Emissão</li>
                                    <li id="ml-nfe-btn-remover-configuracoes-danfe" @click="chamarModulo('RemoverConfiguracoesNfeDanfe')">Remover configurações Danfe</li>
                                    <li id="ml-nfe-btn-consultar-versao" @click="chamarModulo('ConsultarVersao')">Consultar Versão</li>
                                </ul>
                                <!--#endregion -->
                                <!--#region NFC-e  -->
                                <li><a id="ml-btn-nfce"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(2).codigo}"
                                    @click="exibirMenu(retornaEnum(2))"
                                    tabindex="0">NFC-e</a></li>
                                <ul class="menu-itens nfce" :class="{'is-block': exibirOcultarMenu === retornaEnum(2).codigo}">
                                    <li id="ml-nfce-btn-enviar" @click="chamarModulo('EnviarNFCe')">Enviar NFC-e</li>
                                    <li id="ml-nfce-btn-cancelar" @click="chamarModulo('cancelarNota')">Cancelar NFC-e</li>
                                    <li id="ml-nfce-btn-inutilizacao" @click="chamarModulo('Inutilizacao')">Inutilizar NFC-e</li>
                                    <li id="ml-nfce-btn-consultar-nota" @click="chamarModulo('ConsultarNota')">Consultar nota</li>
                                    <li id="ml-nfce-btn-consultar-evento" @click="chamarModulo('ConsultarEvento')">Consultar evento NFC-e</li>
                                    <li id="ml-nfce-btn-status-servico" @click="chamarModulo('StatusServico')">Status serviço</li>
                                    <li id="ml-nfce-btn-obter-url-status" @click="chamarModulo('ObterUrlStatus')">Obter URL de Status</li>
                                    <li id="ml-nfce-btn-gerar-danfce-xml" @click="chamarModulo('GerarDanfceXml')">Gerar DANFCE por XML</li>
                                    <li id="ml-nfce-btn-gerar-danfce-chave" @click="chamarModulo('GerarDanfceChave')">Gerar DANFCE por Chave de Acesso</li>
                                    <li id="ml-nfce-btn-configuracoes-danfce" @click="chamarModulo('ConfiguracoesDanfce')">Configurar DANFCE</li>
                                    <li id="ml-nfce-btn-remover-configuracoes-danfe" @click="chamarModulo('RemoverConfiguracoesNfceDanfe')">Remover configurações Danfe</li>
                                    <li id="ml-nfce-btn-consultar-versao" @click="chamarModulo('ConsultarVersao')">Consultar Versão</li>
                                </ul>
                                <!--#endregion -->
                                <!--#region NFS-e  -->
                                <li><a id="ml-btn-nfse"
                                    class="is-unselectable menu-item"
                                    :class="{'is-active': exibirOcultarMenu === retornaEnum(6).codigo}"
                                    @click="exibirMenu(retornaEnum(6))"
                                    tabindex="0">NFS-e</a></li>
                                <ul class="menu-itens nfse" :class="{'is-block': exibirOcultarMenu === retornaEnum(6).codigo}">
                                    <li id="ml-nfse-btn-enviar" @click="chamarModulo('EnviarNfse')">Enviar NFS-e</li>
                                    <li id="ml-nfse-btn-cancelar" @click="chamarModulo('CancelarNfse')">Cancelar NFS-e</li>
                                    <li id="ml-nfse-btn-consulta" @click="chamarModulo('ConsultaNfse')">Consultar NFS-e</li>
                                    <li id="ml-nfse-btn-gerar-danfse-xml" @click="chamarModulo('GerarDanfseXml')">Gerar DANFSE por Xml</li>
                                    <li id="ml-nfse-btn-configuracoes-nfse" @click="chamarModulo('ConfiguracoesNfse')">Configurações da NFS-e</li>
                                    <li id="ml-nfse-btn-remover-configuracoes-nfse-emissao" @click="chamarModulo('RemoverConfiguracoesNfseEmissao')">Remover Configurações Emissão</li>
                                    <li id="ml-nfse-btn-provedores" @click="chamarModulo('ProvedoresNfse')">Obter e Gravar Provedores</li>
                                    <li id="ml-nfse-btn-gravar-municipio-provedor" @click="chamarModulo('GravarMunicipioProvedor')">Gravar Município Provedor</li>
                                    <li id="ml-nfse-btn-retornar-tipos-envio" @click="chamarModulo('RetornarTiposEnvio')">Retornar Tipos Envio</li>
                                </ul>
                                <!--#endregion -->
                            </ul>
                        <!--#endregion -->
                        </li>
                    </ul>
                </aside>
            </section>
        </div>
        <div>
            <component :is="componente" :modulo="modulo"/>
        </div>
    </div>
</template>

<script>
import Nfe from '@/views/menus/ItensNfe'
import Nfce from '@/views/menus/ItensNfce.vue'
import Nfse from '@/views/menus/ItensNfse.vue'
import Empresa from '@/views/menus/ItensEmpresa.vue'
import Certificado from '@/views/menus/ItensCertificado.vue'
import Uteis from '@/views/menus/ItensUteis.vue'
import Email from '@/views/menus/ItensEmail.vue'
import MenuOutros from '@/views/menus/ItensOutros.vue'
import store from '@/store'
import mixin from '@/mixins'

export default {
    mixins:[mixin],
    components: {
        Nfe,
        Nfce,
        Nfse,
        Uteis,
        Empresa,
        Certificado,
        Email,
        MenuOutros
    },
    computed:{
        exibirOcultarMenu() {
            return store.state.exibirOcultarMenu
        }
    },
    data() {
        return {
            componente: '',
            modulo: '',
            opcaoSelecionada: '',
            componentEnum: [
                { nome: 'Nfe', codigo: 1 },
                { nome: 'Nfce', codigo: 2 },
                { nome: 'Certificado', codigo: 3 },
                { nome: 'MenuOutros', codigo: 4 },
                { nome: 'Empresa', codigo: 5 },
                { nome: 'Nfse', codigo: 6 },
                { nome: 'Uteis', codigo: 7 },
                { nome: 'Email', codigo: 8 }
            ]
        }
    },
    methods: {
        chamarModulo(componente) {
            this.componente = this.retornaEnum(this.exibirOcultarMenu).nome
            store.commit('fnExibirOcultarMenu','')
            this.exibirModulo(componente)
        },
        exibirModulo(componente) {
            this.modulo = componente
        },
        retornaEnum(parametros) {
            return this.componentEnum.find(el => el.codigo ===  parseInt(parametros))
        },
        exibirMenu(parametros){
            this.exibirOcultarMenu && this.exibirOcultarMenu === parametros.codigo?
            store.commit('fnExibirOcultarMenu',''):
            store.commit('fnExibirOcultarMenu',parametros.codigo)
        }
    },
    created() {
        this.$root.$on('closeModal', () => this.componente = '')
    }
}
</script>

<style lang="scss" scoped>
    #MenuBotoes{
        padding: 0 !important;
        .nfe {
            margin-top: -325px;
        }
        .nfce {
            margin-top: -195px;
        }
       .certificados {
            margin-top: -95px;
        }
        .cadastros, .email {
            margin-top: -50px;
        }
        .uteis, .outros {
            margin-top: -80px;
        }
        .nfse {
            margin-top: -110px;
        }
        .menu-itens {
            padding: 0;
            margin-left: 10%;
            position: absolute;
            display: none;
            float: left;            
            li:hover {
                border-radius: 5px;
            }
        }
    }

    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }    

</style>