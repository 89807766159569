<template>
     <div>
        <modal 
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente"/>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import CadastroEmpresa from '@/views/empresas/FormCadastroEmpresa.vue'
import CadastroLogotipo from '@/views/empresas/FormCadastroLogotipo.vue'


export default {
    props: {
        modulo: String
    },
    components: {
        Modal,
        CadastroEmpresa,
        CadastroLogotipo
    },
    data() {
        return {
            componente: "",
            showModal: '',
            altura: '',
            largura: '',
            titulo:''
        }
    },
    methods: {
        abrirModal(opt) {
            switch (opt) {
                case 'CadastroEmpresa':
                    this.componente = 'CadastroEmpresa'
                    this.altura = '477px'
                    this.largura = '750px'
                    this.titulo = 'Cadastrar empresa'
                    break
                case 'CadastroLogotipo':
                    this.componente = 'CadastroLogotipo'
                    this.altura = '442px'
                    this.largura = '450px'
                    this.titulo = 'Cadastrar logotipo'
                    break
            }
        },
        fecharModal() {
            this.showModal = false
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>

<style>

button {
    word-wrap: break-word
}
    

</style>