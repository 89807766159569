import { xmlNfe } from '../assets/test/xml/xml.nfe.modelo'
import { xmlNfce } from '../assets/test/xml/xml.nfce.modelo'
import { xmlCancelamento } from '../assets/test/xml/xml.cancelamento.modelo'
import { xmlCartaCorrecao } from '../assets/test/xml/xml.cartaCorrecao.modelo'
import { xmlInutilizacao } from '../assets/test/xml/xml.inutilizacao.modelo'
import xmlRps from '../assets/test/xml/xml.rps.modelo'
import nfseEnvio from '../assets/test/xml/xml.nfse.modelo'
import { validacoes }from '../helpers/validacoes'
import { parseDados }from '../helpers/parseDados'

//#region  Carregar Conteúdo XML
class ConteudoXML {
  constructor(param) {

    
    let xmlDoc = (new DOMParser()).parseFromString(param, "text/xml")
    
    this.chaveAcesso = () => (xmlDoc.getElementsByTagName("infNFe")[0].getAttribute("Id")).replace(/NFe/, '')
    
    this.xml = () => new XMLSerializer().serializeToString(xmlDoc)

  }
}
//#endregion

//#region Gerar Conteudo Xml Nfse

class ConteudoXmlNfse {
  constructor(param) {
    
    let xmlDoc = (new DOMParser()).parseFromString(param, "text/xml")

    this.chaveAcesso = () =>  !!xmlDoc.getElementsByTagName("LoteRps")[0] ? 
                              (xmlDoc.getElementsByTagName("LoteRps")[0].getAttribute("Id")).replace(/Lote/, '') : 
                              (xmlDoc.getElementsByTagName("InfNfse")[0].getAttribute("Id"))

    this.xml = () => new XMLSerializer().serializeToString(xmlDoc)
  }
}

//#endregion

//#region Gerar nota completa 
  function gerarNota(modelo,param,cpfCnpj){

    let chave = gerarchavedeacesso(param.dadosUf.cUF,cpfCnpj, parseInt(modelo),param.serie,param.numero,param.tpEmis,84117666).chaveacessoparcial
    let dv = calculo_cdv(chave);
    let chavecompleta = chave + dv;
    let xmlDoc = ''
    if(modelo === '55') {
      xmlDoc = (new DOMParser()).parseFromString(xmlNfe.xml,"text/xml")
      xmlDoc.getElementsByTagName("dhSaiEnt")[0].childNodes[0].nodeValue = gerardatahora().dataCompletaNf;
    }
    if(modelo === '65') {
      xmlDoc = (new DOMParser()).parseFromString(xmlNfce.xml,"text/xml") 
    }
    xmlDoc.getElementsByTagName("infNFe")[0].setAttribute("Id","NFe" + chavecompleta);
    xmlDoc.getElementsByTagName("cNF")[0].childNodes[0].nodeValue = 84117666
    xmlDoc.getElementsByTagName("tpEmis")[0].childNodes[0].nodeValue = param.tpEmis
    xmlDoc.getElementsByTagName("cUF")[0].childNodes[0].nodeValue = param.dadosUf.cUF
    xmlDoc.getElementsByTagName("mod")[0].childNodes[0].nodeValue = modelo
    xmlDoc.getElementsByTagName("nNF")[0].childNodes[0].nodeValue = param.numero
    xmlDoc.getElementsByTagName("dhEmi")[0].childNodes[0].nodeValue = gerardatahora().dataCompletaNf;

    xmlDoc.getElementsByTagName("serie")[0].childNodes[0].nodeValue = param.serie
    xmlDoc.getElementsByTagName("cMunFG")[0].childNodes[0].nodeValue = param.dadosUf.cMun
    xmlDoc.getElementsByTagName("cMun")[0].childNodes[0].nodeValue = param.dadosUf.cMun
    xmlDoc.getElementsByTagName("xMun")[0].childNodes[0].nodeValue = param.dadosUf.xMunXml
    xmlDoc.getElementsByTagName("UF")[0].childNodes[0].nodeValue = param.dadosUf.xUF
    xmlDoc.getElementsByTagName("cDV")[0].childNodes[0].nodeValue = dv;
    xmlDoc.getElementsByTagName("tpAmb")[0].childNodes[0].nodeValue = 2;
    xmlDoc.getElementsByTagName("CNPJ")[0].childNodes[0].nodeValue = validacoes.retirarFormatacao(cpfCnpj);
    
    if(param.tpEmis !== 1){
      let nameSpace = xmlDoc.getElementsByTagName('NFe')[0]
      let dhCont = xmlDoc.createElementNS(nameSpace.namespaceURI, 'dhCont')
      dhCont.appendChild(document.createTextNode(gerardatahora().dataCompletaNf))
      let xJust = xmlDoc.createElementNS(nameSpace.namespaceURI, 'xJust')
      xJust.appendChild(document.createTextNode("Nota fiscal eletronica em contingencia"))
      xmlDoc.getElementsByTagName("verProc")[0].after(dhCont,xJust)
    }

    return new XMLSerializer().serializeToString(xmlDoc)
  }
//#endregion

//#region Carregar o XML de Nota
  function carregarDadosXmlDanfe(param) {

    
    try {
      
      const _conteudoXML = new ConteudoXML(param)
      let obj = parseDados.carregarXml(_conteudoXML)

      return obj

    }catch (e) {

      alert("XML informado inválido ou com problemas, verifique o XML.")
      return ""

    }
  }
//#endregion

//#region Carregar o XML de Nota
  function carregarDadosXml(param) {
    
    try {

      let stringFile = new DOMParser()
      let xmlDoc = stringFile.parseFromString(param,"text/xml")

      const errorNode = xmlDoc.querySelector('parsererror');

      if (errorNode) {
        throw "Parser error"
      } else {
        return new XMLSerializer().serializeToString(xmlDoc)
      }
    }catch (e) {
      alert("XML informado inválido ou com problemas, verifique o XML.")
      return ""

    }
  }
//#endregion

//#region Gerar inutilização 
  function gerarInutilizacao(modelo,dados,serie, inicial,final){
    let uf = dados.uf
    let ano = gerardatahora().anoAtual
    let cpfCnpj =  dados.cpfCnpj
    let chave = uf + ano + cpfCnpj + parseInt(modelo) + ("000" + serie).slice(-3) + ("000000000" + inicial).slice(-9) +  ("000000000" + final).slice(-9)
    let xmlDoc = (new DOMParser()).parseFromString(xmlInutilizacao.xml,"text/xml")

    xmlDoc.getElementsByTagName("infInut")[0].setAttribute("Id","ID" + chave)
    xmlDoc.getElementsByTagName("cUF")[0].childNodes[0].nodeValue = dados.uf
    xmlDoc.getElementsByTagName("ano")[0].childNodes[0].nodeValue = ano
    xmlDoc.getElementsByTagName("mod")[0].childNodes[0].nodeValue = modelo
    xmlDoc.getElementsByTagName("serie")[0].childNodes[0].nodeValue = serie
    xmlDoc.getElementsByTagName("nNFIni")[0].childNodes[0].nodeValue = inicial
    xmlDoc.getElementsByTagName("nNFFin")[0].childNodes[0].nodeValue = final
    return new XMLSerializer().serializeToString(xmlDoc)
  }
//#endregion

//#region carregar XML de inutilização
  function carregarXmlInut(param) {

    let xmlDoc = (new DOMParser()).parseFromString(param,"text/xml")
    try {
      xmlDoc.getElementsByTagName("infInut")[0].getAttribute("ID")

      return new XMLSerializer().serializeToString(xmlDoc)

    }catch (e) {
      alert("XML informado inválido ou com problemas, verifique se é um XML de inutilização.")
      return ''
    }

  }
//#endregion

//#region  Gerar XML de Cancelamento
  function gerarCancelamento(dados, chNFe, protocolo){

    let chavecompleta = 110111 + chNFe + "01";

    const cpfCnpj = validacoes.retirarFormatacao(dados.cpfCnpj);
    const xml = (cpfCnpj.length === 14) ? xmlCancelamento.xml : xmlCancelamento.xmlCpf;

    let xmlDoc = (new DOMParser()).parseFromString(xml,"text/xml")

    xmlDoc.getElementsByTagName("infEvento")[0].setAttribute("Id","ID" + chavecompleta)

    if(cpfCnpj.length === 14){
      xmlDoc.getElementsByTagName("CNPJ")[0].childNodes[0].nodeValue = cpfCnpj
    }else{
      xmlDoc.getElementsByTagName("CPF")[0].childNodes[0].nodeValue = cpfCnpj
    }

    xmlDoc.getElementsByTagName("cOrgao")[0].childNodes[0].nodeValue = dados.uf
    xmlDoc.getElementsByTagName("dhEvento")[0].childNodes[0].nodeValue = gerardatahora().dataCompletaNf
    xmlDoc.getElementsByTagName("chNFe")[0].childNodes[0].nodeValue = chNFe
    xmlDoc.getElementsByTagName("nProt")[0].childNodes[0].nodeValue = protocolo
    return new XMLSerializer().serializeToString(xmlDoc)
  }
//#endregion

//#region Carregar o XML de cancelamento
  function carregarXmlEvento(param) {
    let xmlDoc = (new DOMParser()).parseFromString(param,"text/xml")
   
    try {
      
      xmlDoc.getElementsByTagName("infEvento")[0].getAttribute("Id")
      
      return new XMLSerializer().serializeToString(xmlDoc)

    }catch (e) {
      
      alert("XML informado inválido ou com problemas, verifique se é um XML de evento.")
      return ''
    }
  }
//#endregion

//#region  Gerar XML de Carta Correcao
function gerarCartaCorrecao(dados, chNFe,sequencial){

  const nSequencial = sequencial === '' ? '01' : sequencial
  
  let chavecompleta = 110110 + chNFe + ("00" + nSequencial).slice(-2);
  const xml = (dados.cpfCnpj.length === 14) ? xmlCartaCorrecao.xml : xmlCartaCorrecao.xmlCpf;

  let xmlDoc = (new DOMParser()).parseFromString(xml,"text/xml")
  
  xmlDoc.getElementsByTagName("infEvento")[0].setAttribute("Id","ID" + chavecompleta)
  xmlDoc.getElementsByTagName("CNPJ")[0].childNodes[0].nodeValue = validacoes.retirarFormatacao(dados.cpfCnpj)
  xmlDoc.getElementsByTagName("cOrgao")[0].childNodes[0].nodeValue = dados.uf
  xmlDoc.getElementsByTagName("dhEvento")[0].childNodes[0].nodeValue = gerardatahora().dataCompletaNf
  xmlDoc.getElementsByTagName("chNFe")[0].childNodes[0].nodeValue = chNFe
  xmlDoc.getElementsByTagName("nSeqEvento")[0].childNodes[0].nodeValue = nSequencial
  return new XMLSerializer().serializeToString(xmlDoc)
}
//#endregion

//#region gerar chave de acesso
  function gerarchavedeacesso(cuf,cpfCnpj,modelonf,serienf,nrnota,ambientenota,cnfnota){
    var UF = cuf,
        anomes = gerardatahora().anoMes,
        cnpj = cpfCnpj,
        modelo = modelonf,
        serie = ("000" + serienf.toString()).slice(-3),
        nNF = ("000000000" + nrnota).slice(-9),
        ambiente = ambientenota,
        cNF = cnfnota,
        chaveacessoparcial = UF + anomes + cnpj + modelo + serie + nNF + ambiente + cNF;

    return{
      chaveacessoparcial
    }
  }
  //#endregion

//#region gerarRps

function gerarRps(params) {

  let chavecompleta = params.numeroRps + "11";

  let xmlDoc = (new DOMParser()).parseFromString(xmlRps,"text/xml")

  xmlDoc.getElementsByTagName("InfRps")[0].setAttribute("Id","Rps" + ("0000000000" + chavecompleta).slice(-10))
  xmlDoc.getElementsByTagName("Numero")[0].childNodes[0].nodeValue = ("0000000000" + params.numeroRps).slice(-10)
  xmlDoc.getElementsByTagName("DataEmissao")[0].childNodes[0].nodeValue = gerardatahora().dataHoraIss
  xmlDoc.getElementsByTagName("Email")[0].childNodes[0].nodeValue = params.email
  xmlDoc.getElementsByTagName("Serie")[0].childNodes[0].nodeValue = params.serie
  xmlDoc.getElementsByTagName("Competencia")[0].childNodes[0].nodeValue = gerardatahora().dataIss

  return new XMLSerializer().serializeToString(xmlDoc)

}

//#endregion

//#region gerar Xml Nfse

function gerarXmlNfse(QuantidadeRps, NumeroLote, loteRps) {

  let xmlDoc = (new DOMParser()).parseFromString(nfseEnvio.xml,"text/xml")

  xmlDoc.getElementsByTagName("NumeroLote")[0].childNodes[0].nodeValue = ("00000000" + NumeroLote).slice(-8)
  xmlDoc.getElementsByTagName("QuantidadeRps")[0].childNodes[0].nodeValue = QuantidadeRps

  
  
  for (let i = 0; i < loteRps.length; i++) {
    
    let listaLoteRps = (new DOMParser()).parseFromString(loteRps[i].rps,"text/xml")
    var frame = listaLoteRps.getElementsByTagName("Rps")[0]
    var result = xmlDoc.importNode(frame, true);

    i === 0 ? xmlDoc.getElementsByTagName("ListaRps")[0].appendChild(result) :
              xmlDoc.getElementsByTagName("Rps")[i - 1].after(result)
  }

  return new XMLSerializer().serializeToString(xmlDoc)

}

//#endregion

//#region Carregar o XML NFSe

function carregarXmlNfse(param, tipoXml) {

  let stringFile = new DOMParser()
  let xmlDoc = stringFile.parseFromString(param,"text/xml")

  try {
      const errorNode = xmlDoc.querySelector('parsererror');

      if (errorNode) {
        throw "Parser error"
      } else {
        if(tipoXml === 2){

          xmlDoc.getElementsByTagName("EnviarLoteRpsEnvio")[0]
          return new XMLSerializer().serializeToString(xmlDoc)
    
        }
    
        if(tipoXml === 1){

          xmlDoc.getElementsByTagName("ConsultarNfseEnvio")[0]
          return new XMLSerializer().serializeToString(xmlDoc)
    
        }
      }
      
  }catch (e) {

    alert("XML informado inválido ou com problemas, verifique se o XML é uma nota autorizada ou um XML de cancelamento.")
    return ""

  }
}

//#endregion

//#region Carregar o XML de Cancelamento de NFSe

function carregarXmlCancelamentoNfse(param) {
  let xmlDoc = (new DOMParser()).parseFromString(param,"text/xml")
  try {

    xmlDoc.getElementsByTagName("InfPedidoCancelamento")[0].getAttribute("Id")
    return new XMLSerializer().serializeToString(xmlDoc)

  }catch (e) {

    alert("XML informado inválido ou com problemas, verifique se o XML é um cancelamento.")
    return ""

  }
}
//#endregion

//#region Carregar dados para geração do DANFSE
  function carregarDadosDanfse(param) {
    const _conteudoXML = new ConteudoXmlNfse(param)
    let obj = parseDados.carregarDadosDanfse(_conteudoXML)

    return obj
  }
//#endregion

//#region Gerar xml de consulta de NFSe por RPS

function gerarXmlConsultaNfseRps(numeroRps, serie) {

  let xmlDoc = (new DOMParser()).parseFromString(nfseEnvio.xmlConsulta,"text/xml")

  xmlDoc.getElementsByTagName("Numero")[0].childNodes[0].nodeValue = numeroRps
  xmlDoc.getElementsByTagName("Serie")[0].childNodes[0].nodeValue = serie

  return new XMLSerializer().serializeToString(xmlDoc)

}

//#endregion

//#region gerar Xml Cancelamento Nfse

function gerarXmlCancelamentoNfse(params) {

  let xmlDoc = (new DOMParser()).parseFromString(xmlCancelamento.nfse,"text/xml")

  xmlDoc.getElementsByTagName("InfPedidoCancelamento")[0].setAttribute("Id","Nfse" + params)
  xmlDoc.getElementsByTagName("Numero")[0].childNodes[0].nodeValue = params

  return new XMLSerializer().serializeToString(xmlDoc)

}

//#endregion

//#region Calcular digito verificador
  function calculo_cdv(valor){
    var multiplicadores = [2, 3, 4, 5, 6, 7, 8, 9]; 
    var i = 0;
    var resto = valor.split("").reduceRight(function(anterior, atual){
      if(i > multiplicadores.length - 1) i = 0;
      return (multiplicadores[i++] * parseInt(atual, 10)) + anterior; 
    }, 0) % 11;

    return 11 - resto >= 10 ? 0 : 11 - resto
  }
//#endregion

//#region Função de retorno de data e hora
  function gerardatahora() {
    var date = new Date(), 
        data = (date.getDate()<10?'0':'') + date.getDate() + "/"
              + (date.getMonth()<9?'0':'') + (date.getMonth()+1) + "/"
              + date.getFullYear(),
        hora = (date.getHours()<10?'0':'') + date.getHours() + ":"
              + (date.getMinutes()<10?'0':'') + date.getMinutes() + ":" 
              + (date.getSeconds()<10?'0':'') + date.getSeconds(),
        dataCompleta = hora + " em " + data,
        dataCompletaNf = date.getFullYear() + "-" +
                        (date.getMonth()<9?'0':'') + (date.getMonth()+1) + "-" +
                        (date.getDate()<10?'0':'') + date.getDate()
                        + "T" + hora + "-03:00",
        anoMes = date.getFullYear().toString().substr(2,2) + (date.getMonth()<9?'0':'') + (date.getMonth()+1),
        dataIss = date.getFullYear() + "-"
                + (date.getMonth()<9?'0':'') + (date.getMonth()+1) + "-"
                + (date.getDate()<10?'0':'') + date.getDate(),
        dataHoraIss = dataIss + "T" + hora,
        anoAtual = date.getFullYear().toString().substr(2,2)         
    return {
      anoMes,
      data,
      hora,
      dataCompleta,
      dataCompletaNf,
      dataIss,
      anoAtual,
      dataHoraIss
    }
  }
//#endregion

export const geraXML = {
  gerarNota,
  carregarDadosXml,
  carregarDadosDanfse,
  gerarInutilizacao,
  carregarXmlInut,
  gerarCancelamento,
  carregarXmlEvento,
  gerarCartaCorrecao,
  carregarXmlNfse,
  gerarRps,
  gerarXmlNfse,
  gerarXmlCancelamentoNfse,
  carregarXmlCancelamentoNfse,
  carregarDadosXmlDanfe,
  gerarXmlConsultaNfseRps
}