<template>
  <div id="msdfe-app" class="app" key="msdfe-app" @click="fecharMenu($event.target)">
    <script type="module" src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js" />
    <div class="column is-12 is-pulled-left is-marginless is-paddingless has-text-centered">
      <img :src="dfeImg" @click="retornarPaginaInicial('Apresentacao')" class="titulo-img is-pulled-left has-margin-left-15">
      <span class="is-pulled-left has-margin-left-30 is-size-4">
        Integrador para o projeto MS-DFe
      </span>
    </div>
    <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10"/>
    <div class="column is-12 is-full-width has-padding-zero has-heigt-inherit">
      <MenuLateral
        class="column is-3 is-pulled-left is-paddingless"/>
      <Apresentacao :dfeImg='dfeImg' v-if="!exibirOcultarLog" class="column is-9 is-pulled-left cardPersonalizado" />
      <LogRetornoEnvio v-show="exibirOcultarLog" class="column is-9 is-pulled-left" />
    </div>
    <atena-utils-mensagens-retorno v-if="exibirOcultarMensagem"
      :parametros-mensagem='parametrosMensagem'
    ></atena-utils-mensagens-retorno>
    <atena-utils-modal-loading v-if="_modalLoading"/>
  </div>
</template>

<script>
import store from "@/store"
import Vue from 'vue';
import MenuLateral from '@/views/menus/MenuLateral.vue'
import Apresentacao from '@/views/apresentacao.vue'
import LogRetornoEnvio from '@/components/LogRetornoEnvio.vue'
import { dfeImg } from"./assets/base64Img"
import SelecaoMenu from '@/components/SelecionarServidor'


Vue.component('SelecaoMenu', SelecaoMenu)

export default {
  store,
  name: 'msdfe-app',
  components: {
    MenuLateral,
    Apresentacao,
    LogRetornoEnvio
  },
  computed: {
    parametrosMensagem() {
      return JSON.stringify(this.$store.state.exibirMensagemWebService)
    },
    _modalLoading() {
      return this.$store.state.modal_loading
    },
    exibirOcultarLog () {
      return this.$store.state.exibirOcultarLog
    },
    exibirOcultarMensagem() {
      return this.$store.state.exibirMensagemWebService.exibirMensagem
    },
    dfeImg() {
      return dfeImg
    }
  },
  data() {
    return {
      menuItemSelecionado: ''
    }
  },
  methods: {
    fecharMenu( event ) {
      this.menuItemSelecionado = !event.classList.contains('menu-item')
      if(this.menuItemSelecionado){
        this.$store.commit('fnExibirOcultarMenu','')
      }
    },
    fecharMensagem( event ) {
      this.$store.commit('fecharMensagem', event)
    },
    retornarPaginaInicial() {
      this.$store.commit('exibirOcultarLog', false)
    },
    CriarEventoFecharMensagem() {
      window.addEventListener('fecharMensagemRetorno', (e) => this.fecharMensagem(e.detail))
      this.$root.$on('evtOcultarMenu', () => this.$store.commit('fnExibirOcultarMenu',''))
    }
  },
  created() {
    this.CriarEventoFecharMensagem()
  }
};
</script>

<style lang="scss">

@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
@import url("https://unpkg.com/@fortawesome/fontawesome-free@5.15.4/css/all.css");
@import url("https://cdn.jsdelivr.net/npm/bulma-tooltip@3.0.2/dist/css/bulma-tooltip.min.css");
@import "./comum/styles/main.scss";

html {
  min-width: 1024px !important;
}

.cardPersonalizado {
  background-color: snow !important;
  border-radius: 5px;
  height: inherit;
}

.modal-load {
  height: 200px;
  width: 550px !important;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 10;
  z-index: 999;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 180px;
    width: 180px;
  }

  &.is-active {
    opacity: 1;
    z-index: 999;
  }
}
</style>

