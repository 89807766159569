<template>
    <div id="frmEnviarNfse">
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-cancelamento-do-documento-de-nfs-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-6 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input 
                    v-model.number="params.dados.origem"
                    class="input"
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-2 has-padding-zero">
                <span>* Código município:</span>
                <input 
                    v-model="params.dados.codigoMunicipio"
                    class="input"
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select v-model="params.dados.ambiente" 
                class="input">   
                <option v-for="(ambiente,i) in ambientes"
                    :key="i" 
                    :value="ambiente.ambiente">
                    {{ambiente.descricao}}</option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Tipo Envio:</span>
                <select class="input"
                    v-model="params.dados.tipoEnvio">
                    <option v-for="(tipo, i) in tiposEnvios"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-5 is-pulled-left has-padding-zero">
                <span>Url de Notificação(webhook):</span>
                <input v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-marginless has-margin-top-10 is-pulled-left is-full-width">
        
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"'
                        @load="carregarXml($event)" />
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                        <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="is-pulled-left column is-4 has-padding-zero">
                    <span>* Nº NFS-e:</span>
                    <input 
                        v-model.number="params.numeroNfse"
                        class="input"
                        type="number">
                </div>
                <div class="column is-3 is-pulled-left file-reader">
                    <button class="button button-secundario is-full-width" 
                        @click="GerarNotas()">Gerar XML</button>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'has-margin-top-24' :'']">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"
                    id='txt-area-cancelar-nfse'
                    class="textarea"
                    rows="7"
                    v-model="params.dados.xml"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="EnviarNota($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
  </div>
</template>

<script>
    import store from '@/store'
    import mixin from '@/mixins.js'
    import FileReader from "@/components/importarArquivoNovo.vue"
    import cancelarNfse from '@/model/nfse/cancelarNfse.js'
    import { geraXML } from '@/helpers/gerarXML.js'
    import SelecaoMenu from '@/components/SelecionarServidor'

    export default {
        mixins:[mixin],
        components: {
            FileReader,
            SelecaoMenu
        },
        data(){
            return {
                params: new cancelarNfse(),
                tiposEnvios: [
                    { descricao: 'SINCRONA', codigo: 3 },
                    { descricao: 'ASSINCRONA', codigo: 4 }
                ],
                descricaoXml: '',
                ImportacaoXML: ''
            }
        },
        methods: {
            importacaoXMLAlterado() {
                this.params.dados.xml = ''
                this.descricaoXml = ''
            },
            carregarXml(param) {
                this.params.dados.xml = geraXML.carregarXmlCancelamentoNfse(param.xml)
                this.params.dados.xml === '' ? this.descricaoXml = '' : this.descricaoXml = param.nome
            },
            EnviarNota(server) {
                this.$root.$emit('closeModal')
                let paramsRequisicao = {
                    dados: this.params.dados,
                    server: server
                }
                store.dispatch("CancelarNfse" , paramsRequisicao)
                let dadosStore = this.params
                dadosStore.dados.xml = ''
                store.dispatch("ArmazenarDadosStore", dadosStore)
            },
            GerarNotas(){
                this.params.dados.xml = geraXML.gerarXmlCancelamentoNfse(this.params.numeroNfse)
            },
            preencherDadoComLocalStorage() {
                const localStorageDados = JSON.parse(localStorage.getItem(`CancelarNfse`))
                if (localStorageDados) {
                    this.params = new cancelarNfse(localStorageDados)
                    this.params.componente = "CancelarNfse"
                }   
                this. ImportacaoXML = 1
            }
        },
        created() {
            this.preencherDadoComLocalStorage()
        }
    }
</script>

<style lang="scss" scoped>

    .informe-xml-txt-area {
        margin-top: 22px;
    }

    .tabela-opacity {
        opacity: 0.4;
    }

    .progress-bar {
        position: absolute;
        z-index: 3;
        margin: 35px;
        background-color: snow;
        box-shadow: 0 0 3px gray;
        padding: 20px;
        width: 685px;
        progress {
            width: 100%;
        }
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>