<template>
  <div>
     <div class="column is-12 is-pulled-left">
      <div class="column is-12">
          <div class="has-padding-bottom-20">
            <span>Gerar cadeias de certificados e enviar para o S3</span>
          </div>
          <button slot="footer" @click="enviarCaCert"
          class="btnEnviarCaCert button is-full-width"
          >Atualizar cadeias</button>
          <div class="has-padding-top-20">
            <span><strong>Processo pode demorar, aguarde retorno!</strong></span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/mixins'

  export default {
    mixins:[mixin],
    methods: {
      enviarCaCert() {
        this.$root.$emit('closeModal')
        this.$store.dispatch('enviarCaCert')
      }
    }
  }
</script>