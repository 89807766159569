import {
    certificadoServico
} from '../../services'

export const certificado = {

    actions: {
        certificadoInfo({ commit, dispatch },payload) {
            certificadoServico.certificadoInfo(commit, dispatch ,payload)
        },
        certificadoInfoArmazenado({ commit,dispatch },payload) {
            certificadoServico.certificadoInfoArmazenado(commit, dispatch, payload)
        },
        obterCertificadosVigentes({ commit, dispatch }, payload){
            certificadoServico.obterCertificadosVigentes(commit, dispatch, payload)
        },
        certificados({ commit, dispatch }, payload){
            certificadoServico.certificados(commit, dispatch, payload)
        },
        subirCertificado({ commit, dispatch }, payload) {
            certificadoServico.subirCertificado(commit, dispatch, payload)
        },
        testarCertificado({ commit, dispatch }, payload) {
            certificadoServico.testarCertificado(commit, dispatch, payload)
        },
        removerCertificado({ commit, dispatch }, payload) {
            certificadoServico.removerCertificado(commit, dispatch, payload)
        }
    }


}