<template>
    <div class="column is-12 is-paddingless is-pulled-left has-padding-top-15">
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-12 is-pulled-left has-padding-bottom-5 has-padding-top-5" >
                <span>CPF / CNPJ:</span>
                <input
                    id="frm-con-nfse-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
        </div>

        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-11 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Obter configurações"
                        :secundario="true"
                        @serverSelecionado="obterConfiguracoes($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configurações-retornar-configurações-de-emissão-de-nfs-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div>
                <input 
                    id="frm-con-nfse-txt-aglutinarServicos"
                    class="column is-1 is-pulled-left checkbox" 
                    type="checkbox" v-model="configuracoes.aglutinarServicosPorDiscriminacao">
                <span class="column is-11 is-pulled-left checkboxText">Aglutinar serviços por discriminação.</span>
            </div>
        </div>
        <div class="column is-12 is-paddingless is-pulled-left">
            <hr class="is-marginless has-margin-top-10">
            <div class="column is-11 is-pulled-right">
                <div class="column is-11 is-pulled-left has-padding-zero">
                    <SelecaoMenu 
                        textoBotao="Salvar configurações"
                        @serverSelecionado="salvarConfiguracoes($event)" 
                        class="is-pulled-right"/>
                </div>
                <div class="column is-1 is-pulled-left is-paddingless has-text-centered has-padding-top-5">
                    <a href="https://dfe-dev.alterdata.com.br/docs/#configura%C3%A7%C3%B5es-gravar-configura%C3%A7%C3%B5es-de-emiss%C3%A3o-de-nfs-e"
                        target="_blank">
                        <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como salvar as configurações."></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
export default {
    mixins:[mixin],
    computed: {
        configuracoes() {
            return this.$store.state.configuracoes.configuracoesNfse
        }
    },
    methods: {
        obterConfiguracoes(server) {
            this.$store.dispatch("Configuracoes", {
                cpfCnpj : this.retirarFormatacao(this.ultimoCpfCnpj),
                modelo: 'nfse',
                server: server
            })
        },
        salvarConfiguracoes(server) {
            this.$root.$emit('closeModal')
            this.$store.dispatch("Configuracoes", {
                cpfCnpj: this.retirarFormatacao(this.ultimoCpfCnpj),
                configuracoes: this.configuracoes,
                modelo: 'nfse',
                server: server
            })
        }
    }

}
</script>

<style scoped>
    .checkbox {
        margin-top: 5px;
    }

    .checkboxText{
        padding: 3px;
        font-size: 12px;
    }

</style>