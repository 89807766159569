<template>
    <div>
        <modal 
            :altura="altura" 
            :largura="largura" 
            :titulo="titulo">
            <component slot="body" :is="componente" 
                tipoDoc="65"
                :moduloSelecionado="componente"/>
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import EnviarNfse from '@/views/nfse/FormEnviarNfse.vue'
import CancelarNfse from '@/views/nfse/FormCancelarNfse.vue'
import ProvedoresNfse from '@/views/nfse/FormProvedoresNfse.vue'
import RetornarTiposEnvio from '@/views/nfse/FormRetornarTiposEnvio.vue'
import GerarDanfseXml from '@/views/nfse/FormGerarDanfseXml.vue'
import ConsultaNfse from '@/views/nfse/FormConsultaNfse.vue'
import GravarMunicipioProvedor from '@/views/nfse/FormGravacaoMunicipioProvedor.vue'
import ConfiguracoesNfse from '@/views/configuracoes/FormConfiguracoesNfse.vue'
import RemoverConfiguracoesNfseEmissao from '@/views/configuracoes/FormRemoverConfiguracoes.vue'

export default {
    props: {
        modulo: String
    },
    components: {
        Modal,
        EnviarNfse,
        CancelarNfse,
        ConfiguracoesNfse,
        ConsultaNfse,
        RemoverConfiguracoesNfseEmissao,
        ProvedoresNfse,
        GravarMunicipioProvedor,
        RetornarTiposEnvio,
        GerarDanfseXml
    },
    data() {
        return {
            exibirOcultarOpcoes: false,
            showModal: false,
            altura: '',
            largura: '',
            titulo:'',
            componente: ''
        }
    },
    methods: {
        abrirModal(opt) {
            this.showModal = true
            switch (opt) {
                case 'EnviarNfse':
                    this.componente = 'EnviarNfse'
                    this.altura = '560px'
                    this.largura = '900px'
                    this.titulo = 'Enviar NFS-e'
                    break
                case 'ConsultaNfse':
                    this.componente = 'ConsultaNfse'
                    this.altura = '495px'
                    this.largura = '800px'
                    this.titulo = 'Consulta NFS-e'
                    break
                case 'CancelarNfse':
                    this.componente = 'CancelarNfse'
                    this.altura = '500px'
                    this.largura = '900px'
                    this.titulo = 'Cancelar NFS-e'
                    break
                case 'ConfiguracoesNfse':
                    this.componente = 'ConfiguracoesNfse'
                    this.altura = '275px'
                    this.largura = '330px'
                    this.titulo = 'Configurar NFS-e'
                    break
                case 'GravarMunicipioProvedor':
                    this.componente = 'GravarMunicipioProvedor'
                    this.altura = '375px'
                    this.largura = '345px'
                    this.titulo = 'Gravar Município do Provedor'
                    break
                case 'ProvedoresNfse':
                    this.componente = 'ProvedoresNfse'
                    this.altura = '335px'
                    this.largura = '530px'
                    this.titulo = 'Provedores NFS-e'
                    break
                case 'RemoverConfiguracoesNfseEmissao':
                    this.componente = 'RemoverConfiguracoesNfseEmissao'
                    this.altura = '196px'
                    this.largura = '380px'
                    this.titulo = 'Remover Configurações Emissão'
                    break
                case 'RetornarTiposEnvio':
                    this.componente = 'RetornarTiposEnvio'
                    this.altura = '136px'
                    this.largura = '300px'
                    this.titulo = 'Retornar Tipos de Envio'
                    break
                case 'GerarDanfseXml':
                    this.componente = 'GerarDanfseXml'
                    this.altura = '412px'
                    this.largura = '600px'
                    this.titulo = 'Gerar DANFSE por XML'
                    break
            }
        }
    },
    created() {
        this.abrirModal(this.modulo)
    }
}
</script>

<style>


</style>