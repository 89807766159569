import serviceGenerica from './service.generica'
import config from 'config'
import {mensagemLog} from '@/utils/gerarMensagemLog'

export const gerarCaCertServico = {
    enviarCaCert
}

function enviarCaCert(commit, dispatch) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        transformRequest: [function (data, headers) {
            mensagemLog.envioApi("Gerando cadeia de certificados. Pode demorar, favor aguarde resposta.")
            return data;
        }]
    }

    return serviceGenerica.get(`${config.Dfe}/rest/cadeias/gerar`,requestOptions)
    .then(function(response) {
        mensagemLog.retornoApi(response)
        return response
    })
    .catch(function (error) {
        mensagemLog.errorApi(error, dispatch)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}