<template>
  <div>
    <div class="column is-12 is-pulled-left is-paddingless has-margin-top-15">
      <span class="icon is-pulled-right">
        <a href="https://dfe-dev.alterdata.com.br/docs/#certificados-teste-de-certificado-digital"
          target="_blank">
          <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
        </a>
         </span>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-12 is-pulled-left has-padding-zero">
        <span>* CPF / CNPJ:</span>
        <input 
          ref="cpfCnpj"
          v-model="ultimoCpfCnpj"
          class="input " 
          @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
          @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
          maxlength="14"
          type="text">
      </div>
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-12 is-pulled-left has-padding-zero">
        <span>* Serial:</span>
        <input 
          v-model="ultimoSerial"
          class="input" 
          type="text">
      </div>
    </div>
    <div class="column is-12 is-pulled-left has-padding-zero">
      <hr class="has-margin-bottom-10 has-margin-top-10">
      <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
      <div class="column is-5 is-pulled-right is-paddingless">
        <SelecaoMenu 
          @serverSelecionado="testarCertificado($event)" 
          class="is-pulled-right"/>
      </div>
    </div>
  </div>
</template>

<script> 
  import store from '@/store'
  import mixin from '@/mixins'
  import SelecaoMenu from '@/components/SelecionarServidor'

  export default {
    mixins:[mixin],
    components: {
      SelecaoMenu
    },
    name: 'Parent',
    data(){
      return {
        dados: {
          cpfCnpj: '',
          serial: ''
        }
      }
    },
    methods: {
      testarCertificado(server) {
        this.$root.$emit('closeModal')
        this.dados.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
        this.dados.serial = this.ultimoSerial
        store.dispatch("testarCertificado", {
          dados: this.dados,
          server: server
        })
      }
    }
  }
</script>

<style scoped>
  
</style>