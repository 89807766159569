<template>
    <div id="frmEnviarNfse">
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-nfs-e-envio-de-documento-de-nfs-e"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-4 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    ref="cpfCnpj"
                    v-model="params.dados.cpfCnpj"
                    class="input" 
                    @focus="params.dados.cpfCnpj = retirarFormatacao(params.dados.cpfCnpj)" 
                    @blur="params.dados.cpfCnpj = formatarCampo(params.dados.cpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="is-pulled-left column is-6 has-padding-zero" >
                <span>* Serial:</span>
                <input 
                    v-model="params.dados.serial"
                    class="input" 
                    type="text">
            </div>
            <div class="is-pulled-left column is-2 has-padding-zero" >
                <span>* Origem:</span>
                <input 
                    v-model.number="params.dados.origem"
                    class="input"
                    type="number">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="is-pulled-left column is-2 has-padding-zero">
                <span>* Código município:</span>
                <input 
                    v-model="params.dados.codigoMunicipio"
                    class="input"
                    type="text">
            </div>
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Ambiente:</span>
                <select v-model="params.dados.ambiente" 
                class="input">   
                <option v-for="(ambiente,i) in ambientes"
                    :key="i" 
                    :value="ambiente.ambiente">
                    {{ambiente.descricao}}</option>
                </select>
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Tipo Envio:</span>
                <select class="input"
                    v-model="params.dados.tipoEnvio">
                    <option v-for="(tipo, i) in tiposEnvios"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-5 is-pulled-left has-padding-zero">
                <span>Url de Notificação(webhook):</span>
                <input v-model="params.dados.urlNotificacao"
                    class="input" 
                    type="text">
            </div>
        </div>
        <hr class="is-marginless has-margin-top-10 is-pulled-left is-full-width">
        
        <div class="column is-12 is-pulled-left is-paddingless">
            <div class="column is-2 select is-pulled-left has-padding-zero">
                <span>* Tipo Inclusão XML:</span>
                <select class="input"
                    v-model="ImportacaoXML"
                    @change="importacaoXMLAlterado()">
                    <option v-for="(tipo, i) in tiposImportacaoXML"
                        :key="i"
                        :value="tipo.codigo">
                        {{tipo.descricao}}</option>
                </select>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 2">
                <div class="column is-3 is-pulled-left file-reader has-padding-zero">
                    <FileReader class="button button-secundario is-full-width"
                        :Btndescricao='"Carregar XML"'
                        @load="carregarXml($event)" />
                </div>
                <div class="column is-9 is-pulled-left pt-1 pb-0">
                        <div class="txt-descricao-xml">
                        <span class="is-pulled-left">Nome XML Carregado:  </span>
                        <div class="column is-12 is-pulled-left is-paddingless">
                            <span class="is-pulled-left pt-2"><b>{{ descricaoXml}}</b></span>
                        </div>
                    </div>
                    <hr class="is-marginless">
                </div>
            </div>
            <div class="column is-10 is-pulled-left is-paddingless"
                v-if="ImportacaoXML === 1">
                <div class="column is-12 is-pulled-left is-paddingless">
                    <div class="is-pulled-left column is-3 has-padding-zero">
                        <span>* Nº Lote:</span>
                        <input 
                            v-model.number="params.numeroLote"
                            class="input"
                            type="number">
                    </div>
                    <div class="is-pulled-left column is-4 has-padding-zero">
                        <span>* Nº RPS:</span>
                        <input 
                            v-model.number="params.numeroRps"
                            
                            class="input"
                            type="number">
                    </div>
                    <div class="is-pulled-left column is-2 has-padding-zero">
                        <span>* Série:</span>
                        <input
                            v-model.number="params.serie"
                            title="Infome a quantidade de notas que será gerada no lote."
                            class="input"
                            type="number">
                    </div>
                    <div class="is-pulled-left column is-3 has-padding-zero">
                        <span>* Qt RPS's:</span>
                        <input
                            v-model.number="qtRps"
                            title="Infome a quantidade de notas que será gerada no lote."
                            class="input"
                            type="number">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-paddingless">                
                    <div class="is-pulled-left column is-6 has-padding-zero">
                        <span>* E-mail do tomador:</span>
                        <input
                            v-model="params.email"
                            title="Infome o e-mail para do tomador do serviço."
                            class="input"
                            type="text">
                    </div>
                    <div class="column is-3 is-pulled-right file-reader">
                        <button class="button button-secundario is-full-width" 
                            @click="GerarNotas(qtRps)"
                            v-text="qtRps <= 1 ? 'Gerar XML' : 'Gerar Lote de XML'"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <div class="column is-12 is-pulled-left is-paddingless"
                :class="[ImportacaoXML === 3 ? 'has-margin-top-24' :'']"
                v-if="qtRps <= 1">
                <span v-text="ImportacaoXML === 3 ? `* Informe o XML:` :  `* XML`"></span>
                <textarea
                    :disabled="ImportacaoXML !== 3"
                    :class="[ImportacaoXML !== 3 ? 'cursor-context-menu' : 'cursor-text']"
                    id='txt-area-enviar-nfse'
                    class="textarea"
                    :rows="[ImportacaoXML === 1 ? 7 : 10]"
                    v-model="params.dados.xml"/>
            </div>
            <div class="column is-12 is-pulled-left is-paddingless"
                v-else>
                 <span>* Lista de Rps's:</span>
                <div class="progress-bar" v-if="exibirBarraProgresso">
                    <span><b>Progresso do envio:</b> Enviando {{envioAtual}} de {{ qtRps }}</span>
                    <progress class="progress is-large is-primary" :value="progressBar" max="100" />
                </div>
                <div class="tblClasse-lista"
                    :class="{'tabela-opacity': exibirBarraProgresso}">
                    <table class="table is-striped is-narrow is-hoverable">
                    <thead>
                        <tr>
                            <th>Índice</th>
                            <th>Rps</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(Rps, i) in listaRps" :key="i" >
                            <td>{{ i }}</td>
                            <td>{{ Rps.numeroRps }}</td>
                            <td>
                                <i class="fas fa-trash-alt fa-lg is-clickable" @click="removerItens(i)"></i>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <hr class="is-marginless has-margin-bottom-5">
            <span class="pulled-left is-size-8 ">* Campos obrigatórios.</span>
            <div class="column is-4 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="EnviarNota($event)" 
                    class="is-pulled-right"/>
            </div>
        </div>
  </div>
</template>

<script>
    import store from '@/store'
    import mixin from '@/mixins.js'
    import FileReader from "@/components/importarArquivoNovo.vue"
    import enviarNfse from '@/model/nfse/enviarNfse.js'
    import { geraXML } from '@/helpers/gerarXML.js'
    import SelecaoMenu from '@/components/SelecionarServidor'

    export default {
        mixins:[mixin],
        props: {
            arquivoXML: String
        },
        components: {
            FileReader,
            SelecaoMenu
        },
        data(){
            return {
                exibirBarraProgresso: false,
                params: new enviarNfse(),
                xml: [],
                listaRps: [],
                qtRps: '',
                progressBar: '',
                envioAtual: '',
                tiposEnvios: [
                    { descricao: 'SINCRONA', codigo: 1 },
                    { descricao: 'ASSINCRONA', codigo: 2 }
                ],
                descricaoXml: '',
                ImportacaoXML: '',
            }
        },
        methods: {
            importacaoXMLAlterado() {
                this.qtRps = 1,
                this.descricaoXml = ''
                this.params.dados.xml = ''

            },
            carregarXml(param) {
                this.descricaoXml = param.nome
                this.params.dados.xml = geraXML.carregarXmlNfse(param.xml, 2)
                this.qtRps = 1
            },
            EnviarNota(server) {
                this.$root.$emit('closeModal')
                let paramsRequisicao = {
                    dados: this.params.dados,
                    server: server
                }
                store.dispatch("EnviarNfse" , paramsRequisicao)
                let dadosStore = this.params
                dadosStore.dados.xml = ''
                store.dispatch("ArmazenarDadosStore", dadosStore)
            },
            async removerItens(indice) {
                await this.listaRps.splice(indice,1)
                this.params.dados.xml =  geraXML.gerarXmlNfse(this.listaRps.length, this.params.numeroLote, this.listaRps)
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async GerarNotas(qtRps){
                this.exibirBarraProgresso = true
                this.listaRps = []
                for (let i = 0; i < qtRps; i++) {
                    this.envioAtual = i + 1
                    this.progressBar = (this.envioAtual / qtRps) * 100
                    i === 0 ? this.params.numeroLote : this.params.numeroRps = parseInt(this.params.numeroRps) + 1
                    let novoRps = {
                        rps: geraXML.gerarRps(this.params),
                        numeroRps: this.params.numeroRps
                    }
                    this.listaRps.push(novoRps)
            
                    await this.sleep(50)
                }

                this.params.dados.xml = geraXML.gerarXmlNfse(this.listaRps.length, this.params.numeroLote,  this.listaRps)
                this.exibirBarraProgresso = false
            },
            preencherDadoComLocalStorage() {
                const localStorageDados = JSON.parse(localStorage.getItem(`EnviarNfse`))
                if (localStorageDados) {
                    this.params = new enviarNfse(localStorageDados)
                    this.params.componente = "EnviarNfse"
                    this.params.numeroRps = this.params.numeroRps + 1
                }   
                this.qtRps = 1
                this. ImportacaoXML = 1
            }
        },
        created() {
            this.preencherDadoComLocalStorage()
        }
    }
</script>

<style lang="scss" scoped>

    .tabela-opacity {
        opacity: 0.4;
    }

    .progress-bar {
        position: absolute;
        z-index: 3;
        margin: 35px;
        background-color: snow;
        box-shadow: 0 0 3px gray;
        padding: 20px;
        width: 685px;
        progress {
            width: 100%;
        }
    }

    .textarea {
        resize: none;
    }

    .file-reader {
        padding-top: 24px !important;
    }

</style>