<template>
    <div>
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <span>Testar E-mail</span>
                        <span class="fechar" @click="fecharModalTest(false)">X</span>
                    </div>
                    <div class="modal-body">
                        <div class="column is-12 is-paddingless is-pulled-left ">
                            <div class="column is-12 is-pulled-left has-padding-zero">
                                <span>Informe o e-mail e clique em testar:</span>
                                <input 
                                    id="frm-teste-email-txt-destinatarios"
                                    class="input" 
                                    type="url" 
                                    v-model="destinatarios">
                            </div>
                        </div>
                        <div class="column is-12 is-pulled-left has-padding-left-15">
                            <span class="is-pulled-left">Resultado: </span>
                            <span class="is-pulled-left"
                                :class="{ 'has-sucesso': retornoTesteEmail.tipo === 'Sucesso',
                                          'has-error': retornoTesteEmail.tipo === 'Erro'}">
                                {{ retornoTesteEmail.mensagem }}
                            </span>
                        </div>
                        <div class="column is-12 is-paddingless is-pulled-left ">
                            <div class="column is-4 is-pulled-right is-paddingless">
                                <div class="column is-12 is-pulled-right">
                                    <div class="column is-10 is-pulled-left is-paddingless">
                                        <SelecaoMenu 
                                            textoBotao="Testar"
                                            @serverSelecionado="testarEmail($event, destinatarios)" 
                                            class="is-pulled-right"/>
                                    </div>
                                    <div class="column is-2 is-pulled-left is-paddingless has-text-centered has-padding-top-5 has-padding-left-10">
                                        <a href="https://dfe-dev.alterdata.com.br/docs/#envio-de-e-mail-teste-de-envio-de-e-mail"
                                            target="_blank">
                                            <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Ajuda sobre como obter as configurações"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-3 is-pulled-right is-paddingless has-margin-top-12">
                                <button class="button button-secundario is-full-width"
                                    @click="fecharModalTest(false)">
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fecharModalTest: Function
    },
    computed:{
        retornoTesteEmail() {
            return this.$store.state.email.conteudoRetornoTesteEmail
        },
        dadosEmail() {
            return this.$store.state.email.dadosEmpresa.dadosEmail
        }
    },
    data() {
        return {
            destinatarios: '',
        }
    },
    methods:{
        testarEmail(server, destinatarios) {
            this.$store.dispatch("testarEmail", {
                server: server,
                dados: {
                    destinatario: destinatarios,
                    ...this.dadosEmail
                }
            })
        }
    },
    created() {
        this.$store.commit("retornoTesteEmail", "")
    }

}
</script>

<style lang="scss" scoped>

    .has-sucesso {
        padding-left: 5px;
        color: rgb(5, 180, 93) !important;
    }

    .has-error {
        color: rgb(194, 49, 49) !important;
        padding-left: 5px;
    }

    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }

    .modal-mask {
        position: fixed;
        z-index: 40;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        height: 220px;
        width: 600px;
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-header{
        background-color: #2e4283;
        padding: 10px;

        Span {
            font-weight: bold;
            font-size: 20px;
            color: white;

        }

        .fechar {
            margin-top: 3px;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(16, 135, 247, 0.815);
            cursor: pointer;
            border-radius: 50%;
            width: 23px;
            height: 23px;
        }

        .fechar:hover {
            transform: scale(1.2)
        }
    }

    .modal-body {
        padding: 10px;
        margin-top: 0px;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>