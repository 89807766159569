export default class enviarNota {
    constructor(objeto) {

        this.dados = {
            cpfCnpj: '',
            serial: '',
            xml: '',
            uf:'',
            versao:'',
            urlNotificacao: '',
            origem: '',
            tributosNota:''
        }
        this.dadosUf = {
            xUF: '',
            cUF: ''
        }
        this.municipio = '',
        this.numero = '',
        this.serie = '',
        this.tpEmis ='',
        this.municipioPesquisa = ''

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}