<template>
    <div>
        <div class="has-margin-top-25">
            <span class="icon is-pulled-right">
                <a href="https://dfe-dev.alterdata.com.br/docs/#documentos-documentos-validação"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless has-margin-bottom-15">
            <div class="column is-5 is-pulled-left has-padding-zero" >
                <span>* CPF / CNPJ:</span>
                <input 
                    id="frm-validar-xml-txt-cpfCnpj"
                    ref="cpfCnpj"
                    v-model="ultimoCpfCnpj"
                    class="input" 
                    @focus="ultimoCpfCnpj = retirarFormatacao(ultimoCpfCnpj)"  
                    @blur="ultimoCpfCnpj = formatarCampo(ultimoCpfCnpj)"
                    maxlength="14"
                    type="text">
            </div>
            <div class="column is-3 select is-pulled-left has-padding-zero">
                <span>* Modelo:</span>
                <select 
                    id="frm-validar-xml-txt-modelo"
                    v-model="dados.modelo" 
                    class="input">   
                    <option v-for="modelo in modelos"
                        :key="modelo.tipoDocumento"
                        :value="modelo.modelo">
                        {{modelo.tipoDocumento}}</option>
                </select>
            </div>
            <div class="column is-4 file-reader is-pulled-left">
                <FileReader class="button button-secundario is-full-width"
                    @load="dados.xml = $event.xml" />
            </div>
        </div>
        <div class="column is-12 div-textarea">
            <div class="column is-12 has-padding-zero">
                <span>* Informe o XML ou importe clicando no botão importar XML:</span>
                <textarea
                    id="frm-validar-xml-txt-xml"
                    class="textarea"
                    rows="8"
                    v-model="dados.xml"/>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-zero">
            <hr class="is-marginless has-margin-top-10 has-margin-bottom-10">
            <span class="pulled-left is-size-8">* Campos obrigatórios.</span>
            <div class="column is-5 is-pulled-right is-paddingless">
                <SelecaoMenu 
                    @serverSelecionado="validarXml($event)"
                    textoBotao="Validar dados"
                    class="is-pulled-right"/>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '@/mixins'
    import FileReader from "@/components/importarArquivoNovo";

    export default {
         mixins:[mixin],
        components: {
            FileReader
        },
         data(){
            return {
                dados: {
                    cpfCnpj: '',
                    xml: '',
                    modelo:''
                }
            }
        },
        methods: {
            validarXml(server) {
                this.$root.$emit('closeModal')
                this.dados.cpfCnpj = this.retirarFormatacao(this.ultimoCpfCnpj)
                this.$store.dispatch("validarXml", {
                    dados: {
                        ...this.dados
                    },
                    server: server
                })
            }
        }
    }
</script>

<style scoped>

    .textarea {
        resize: none;
    }

    .file-reader {
        float: right;
        padding-top: 24px !important;
    }

    .div-textarea {
        float: left;
        margin-top: -5px;
        padding: 0;
    }

</style>